/**
 * These are all the languages the CTags support.
 * This can be produced by running `ctags --list-languages`.
 * Additional languages that are not supported by CTags are added in the end.
 */
export enum CTagLanguage {
  ABAQUS = 'Abaqus',
  ABC = 'Abc',
  ADA = 'Ada',
  ANSIBLEPLAYBOOK = 'AnsiblePlaybook',
  ANT = 'Ant',
  ASCIIDOC = 'Asciidoc',
  ASM = 'Asm',
  ASP = 'Asp',
  AUTOCONF = 'Autoconf',
  AUTOIT = 'AutoIt',
  AUTOMAKE = 'Automake',
  AWK = 'Awk',
  BASIC = 'Basic',
  BATS = 'Bats',
  BETA = 'BETA',
  BIBTEX = 'BibTeX',
  C = 'C',
  'C#' = 'C#',
  'C++' = 'C++',
  CLOJURE = 'Clojure',
  CMAKE = 'CMake',
  COBOL = 'Cobol',
  COBOLFREE = 'CobolFree',
  COBOLVARIABLE = 'CobolVariable',
  CPREPROCESSOR = 'CPreProcessor',
  CSS = 'CSS',
  CTAGS = 'Ctags',
  CUDA = 'CUDA',
  D = 'D',
  DBUSINTROSPECT = 'DBusIntrospect',
  DIFF = 'Diff',
  DOSBATCH = 'DosBatch',
  DTD = 'DTD',
  DTS = 'DTS',
  EIFFEL = 'Eiffel',
  ELIXIR = 'Elixir',
  ELM = 'Elm',
  EMACSLISP = 'EmacsLisp',
  ERLANG = 'Erlang',
  FALCON = 'Falcon',
  FLEX = 'Flex',
  FORTRAN = 'Fortran',
  FUNCTIONPARAMETERS = 'FunctionParameters',
  FYPP = 'Fypp',
  GDBINIT = 'Gdbinit',
  GDSCRIPT = 'GDScript',
  GEMSPEC = 'GemSpec',
  GLADE = 'Glade',
  GO = 'Go',
  GPERF = 'Gperf',
  HASKELL = 'Haskell',
  HAXE = 'Haxe',
  HTML = 'HTML',
  INICONF = 'Iniconf',
  INKO = 'Inko',
  IPYTHONCELL = 'IPythonCell',
  ITCL = 'ITcl',
  JAVA = 'Java',
  JAVAPROPERTIES = 'JavaProperties',
  JAVASCRIPT = 'JavaScript',
  JSON = 'JSON',
  JULIA = 'Julia',
  KCONFIG = 'Kconfig',
  KOTLIN = 'Kotlin',
  LDSCRIPT = 'LdScript',
  LEX = 'LEX',
  LISP = 'Lisp',
  LITERATEHASKELL = 'LiterateHaskell',
  LUA = 'Lua',
  M4 = 'M4',
  MAKE = 'Make',
  MAN = 'Man',
  MARKDOWN = 'Markdown',
  MATLAB = 'MatLab',
  MAVEN2 = 'Maven2',
  MESON = 'Meson',
  MESONOPTIONS = 'MesonOptions',
  MOOSE = 'Moose',
  MYRDDIN = 'Myrddin',
  NSIS = 'NSIS',
  OBJECTIVEC = 'ObjectiveC',
  OCAML = 'OCaml',
  OPENAPI = 'OpenAPI',
  ORG = 'Org',
  PASCAL = 'Pascal',
  PASSWD = 'Passwd',
  PERL = 'Perl',
  PERL6 = 'Perl6',
  PHP = 'PHP',
  PLISTXML = 'PlistXML',
  POD = 'Pod',
  POWERSHELL = 'PowerShell',
  PROTOBUF = 'Protobuf',
  PUPPETMANIFEST = 'PuppetManifest',
  PYTHON = 'Python',
  PYTHONLOGGINGCONFIG = 'PythonLoggingConfig',
  QEMUHX = 'QemuHX',
  QTMOC = 'QtMoc',
  QUARTO = 'Quarto',
  RMARKDOWN = 'RMarkdown',
  R = 'R',
  R6CLASS = 'R6Class',
  RAKE = 'Rake',
  RAKU = 'Raku',
  RDOC = 'RDoc',
  RELAXNG = 'RelaxNG',
  RESTRUCTUREDTEXT = 'ReStructuredText',
  REXX = 'REXX',
  ROBOT = 'Robot',
  RPMMACROS = 'RpmMacros',
  RPMSPEC = 'RpmSpec',
  RSPEC = 'RSpec',
  RUBY = 'Ruby',
  RUST = 'Rust',
  S4CLASS = 'S4Class',
  SCALA = 'Scala',
  SCHEME = 'Scheme',
  SCSS = 'SCSS',
  SH = 'Sh',
  SLANG = 'SLang',
  SML = 'SML',
  SQL = 'SQL',
  SVG = 'SVG',
  SYSTEMDUNIT = 'SystemdUnit',
  SYSTEMTAP = 'SystemTap',
  SYSTEMVERILOG = 'SystemVerilog',
  TCL = 'Tcl',
  TCLOO = 'TclOO',
  TERRAFORM = 'Terraform',
  TERRAFORMVARIABLES = 'TerraformVariables',
  TEX = 'Tex',
  TEXBEAMER = 'TeXBeamer',
  THRIFT = 'Thrift',
  TTCN = 'TTCN',
  TXT2TAGS = 'Txt2tags',
  TYPESCRIPT = 'TypeScript',
  TYPESCRIPTX = 'TypeScriptX',
  UNKNOWN = 'Unknown',
  VARLINK = 'Varlink',
  VERA = 'Vera',
  VERILOG = 'Verilog',
  VHDL = 'VHDL',
  VIM = 'Vim',
  WINDRES = 'WindRes',
  XML = 'XML',
  XSLT = 'XSLT',
  YACC = 'YACC',
  YAML = 'Yaml',
  YUMREPO = 'YumRepo',
  ZEPHIR = 'Zephir',
  ZSH = 'Zsh',
  // Additional languages that are not supported by CTags
  JSP = 'JSP',
  RPGLE = 'RPGLE',
  ABL = 'ABL',
  IEF = 'IEF',
  'VB.NET' = 'VB.NET',
  PLSQL = 'PLSQL',
  IBM_CL = 'IBM_CL',
  JCL = 'JCL',
}

// These are all the languages extensions the CTags support, for use in quick lookups.
// This can be produced by running `ctags --list-map-extensions`.
// Additional languages that are not supported by CTags are added in the end.
export const EXTENSION_TO_LANGUAGE: Record<string, CTagLanguage> = {
  '.inp': CTagLanguage.ABAQUS,
  '.abc': CTagLanguage.ABC,
  '.adb': CTagLanguage.ADA,
  '.ads': CTagLanguage.ADA,
  '.ada': CTagLanguage.ADA,
  '.build.xml': CTagLanguage.ANT,
  '.ant': CTagLanguage.ANT,
  '.xml': CTagLanguage.XML,
  '.asc': CTagLanguage.ASCIIDOC,
  '.adoc': CTagLanguage.ASCIIDOC,
  '.asciidoc': CTagLanguage.ASCIIDOC,
  '.asm': CTagLanguage.ASM,
  '.s': CTagLanguage.R,
  '.asp': CTagLanguage.ASP,
  '.asa': CTagLanguage.ASP,
  '.ac': CTagLanguage.AUTOCONF,
  '.au3': CTagLanguage.AUTOIT,
  '.am': CTagLanguage.AUTOMAKE,
  '.awk': CTagLanguage.AWK,
  '.gawk': CTagLanguage.AWK,
  '.mawk': CTagLanguage.AWK,
  '.bas': CTagLanguage.BASIC,
  '.bi': CTagLanguage.BASIC,
  '.bm': CTagLanguage.BASIC,
  '.bb': CTagLanguage.BASIC,
  '.pb': CTagLanguage.BASIC,
  '.bats': CTagLanguage.BATS,
  '.bet': CTagLanguage.BETA,
  '.bib': CTagLanguage.BIBTEX,
  '.clj': CTagLanguage.CLOJURE,
  '.cljs': CTagLanguage.CLOJURE,
  '.cljc': CTagLanguage.CLOJURE,
  '.cmake': CTagLanguage.CMAKE,
  '.c': CTagLanguage.C,
  '.c++': CTagLanguage['C++'],
  '.cc': CTagLanguage['C++'],
  '.cp': CTagLanguage['C++'],
  '.cpp': CTagLanguage['C++'],
  '.cxx': CTagLanguage['C++'],
  '.h': CTagLanguage['C++'],
  '.h++': CTagLanguage['C++'],
  '.hh': CTagLanguage['C++'],
  '.hp': CTagLanguage['C++'],
  '.hpp': CTagLanguage['C++'],
  '.hxx': CTagLanguage['C++'],
  '.inl': CTagLanguage['C++'],
  '.css': CTagLanguage.CSS,
  '.cs': CTagLanguage['C#'],
  '.ctags': CTagLanguage.CTAGS,
  '.cbl': CTagLanguage.COBOL,
  '.cob': CTagLanguage.COBOL,
  '.cu': CTagLanguage.CUDA,
  '.cuh': CTagLanguage.CUDA,
  '.d': CTagLanguage.D,
  '.di': CTagLanguage.D,
  '.diff': CTagLanguage.DIFF,
  '.patch': CTagLanguage.DIFF,
  '.dtd': CTagLanguage.DTD,
  '.mod': CTagLanguage.DTD,
  '.dts': CTagLanguage.DTS,
  '.dtsi': CTagLanguage.DTS,
  '.bat': CTagLanguage.DOSBATCH,
  '.cmd': CTagLanguage.REXX,
  '.e': CTagLanguage.EIFFEL,
  '.ex': CTagLanguage.ELIXIR,
  '.exs': CTagLanguage.ELIXIR,
  '.el': CTagLanguage.EMACSLISP,
  '.erl': CTagLanguage.ERLANG,
  '.hrl': CTagLanguage.ERLANG,
  '.fal': CTagLanguage.FALCON,
  '.ftd': CTagLanguage.FALCON,
  '.as': CTagLanguage.FLEX,
  '.mxml': CTagLanguage.FLEX,
  '.f': CTagLanguage.FORTRAN,
  '.for': CTagLanguage.FORTRAN,
  '.ftn': CTagLanguage.FORTRAN,
  '.f77': CTagLanguage.FORTRAN,
  '.f90': CTagLanguage.FORTRAN,
  '.f95': CTagLanguage.FORTRAN,
  '.f03': CTagLanguage.FORTRAN,
  '.f08': CTagLanguage.FORTRAN,
  '.f15': CTagLanguage.FORTRAN,
  '.fy': CTagLanguage.FYPP,
  '.gdb': CTagLanguage.GDBINIT,
  '.gd': CTagLanguage.GDSCRIPT,
  '.gemspec': CTagLanguage.GEMSPEC,
  '.go': CTagLanguage.GO,
  '.perf': CTagLanguage.GPERF,
  '.gperf': CTagLanguage.GPERF,
  '.hs': CTagLanguage.HASKELL,
  '.hx': CTagLanguage.QEMUHX,
  '.htm': CTagLanguage.HTML,
  '.html': CTagLanguage.HTML,
  '.ini': CTagLanguage.INICONF,
  '.conf': CTagLanguage.INICONF,
  '.inko': CTagLanguage.INKO,
  '.itcl': CTagLanguage.ITCL,
  '.java': CTagLanguage.JAVA,
  '.properties': CTagLanguage.JAVAPROPERTIES,
  '.js': CTagLanguage.JAVASCRIPT,
  '.jsx': CTagLanguage.JAVASCRIPT,
  '.mjs': CTagLanguage.JAVASCRIPT,
  '.json': CTagLanguage.JSON,
  '.jl': CTagLanguage.JULIA,
  '.lds': CTagLanguage.LDSCRIPT,
  '.scr': CTagLanguage.LDSCRIPT,
  '.ld': CTagLanguage.LDSCRIPT,
  '.ldi': CTagLanguage.LDSCRIPT,
  '.lex': CTagLanguage.LEX,
  '.l': CTagLanguage.LISP,
  '.clisp': CTagLanguage.LISP,
  '.lisp': CTagLanguage.LISP,
  '.lsp': CTagLanguage.LISP,
  '.lhs': CTagLanguage.LITERATEHASKELL,
  '.lua': CTagLanguage.LUA,
  '.m4': CTagLanguage.M4,
  '.spt': CTagLanguage.M4,
  '.1': CTagLanguage.MAN,
  '.2': CTagLanguage.MAN,
  '.3': CTagLanguage.MAN,
  '.4': CTagLanguage.MAN,
  '.5': CTagLanguage.MAN,
  '.6': CTagLanguage.MAN,
  '.7': CTagLanguage.MAN,
  '.8': CTagLanguage.MAN,
  '.9': CTagLanguage.MAN,
  '.3pm': CTagLanguage.MAN,
  '.3stap': CTagLanguage.MAN,
  '.7stap': CTagLanguage.MAN,
  '.mak': CTagLanguage.MAKE,
  '.mk': CTagLanguage.MAKE,
  '.md': CTagLanguage.MARKDOWN,
  '.markdown': CTagLanguage.MARKDOWN,
  '.m': CTagLanguage.OBJECTIVEC,
  '.myr': CTagLanguage.MYRDDIN,
  '.nsi': CTagLanguage.NSIS,
  '.nsh': CTagLanguage.NSIS,
  '.mm': CTagLanguage.OBJECTIVEC,
  '.ml': CTagLanguage.OCAML,
  '.mli': CTagLanguage.OCAML,
  '.aug': CTagLanguage.OCAML,
  '.org': CTagLanguage.ORG,
  '.p': CTagLanguage.PASCAL,
  '.pas': CTagLanguage.PASCAL,
  '.pl': CTagLanguage.PERL,
  '.pm': CTagLanguage.PERL6,
  '.ph': CTagLanguage.PERL,
  '.plx': CTagLanguage.PERL,
  '.perl': CTagLanguage.PERL,
  '.p6': CTagLanguage.PERL6,
  '.pm6': CTagLanguage.PERL6,
  '.pl6': CTagLanguage.PERL6,
  '.t6': CTagLanguage.PERL6,
  '.php': CTagLanguage.PHP,
  '.php3': CTagLanguage.PHP,
  '.php4': CTagLanguage.PHP,
  '.php5': CTagLanguage.PHP,
  '.php7': CTagLanguage.PHP,
  '.phtml': CTagLanguage.PHP,
  '.pod': CTagLanguage.POD,
  '.ps1': CTagLanguage.POWERSHELL,
  '.psm1': CTagLanguage.POWERSHELL,
  '.proto': CTagLanguage.PROTOBUF,
  '.pp': CTagLanguage.PUPPETMANIFEST,
  '.py': CTagLanguage.PYTHON,
  '.pyx': CTagLanguage.PYTHON,
  '.pxd': CTagLanguage.PYTHON,
  '.pxi': CTagLanguage.PYTHON,
  '.scons': CTagLanguage.PYTHON,
  '.wsgi': CTagLanguage.PYTHON,
  '.qmd': CTagLanguage.QUARTO,
  '.rmd': CTagLanguage.RMARKDOWN,
  '.r': CTagLanguage.R,
  '.q': CTagLanguage.R,
  '.rake': CTagLanguage.RAKE,
  '.raku': CTagLanguage.RAKU,
  '.rakumod': CTagLanguage.RAKU,
  '.rakutest': CTagLanguage.RAKU,
  '.rakudoc': CTagLanguage.RAKU,
  '.rexx': CTagLanguage.REXX,
  '.rx': CTagLanguage.REXX,
  '.robot': CTagLanguage.ROBOT,
  '.spec': CTagLanguage.RPMSPEC,
  '.rest': CTagLanguage.RESTRUCTUREDTEXT,
  '.rst': CTagLanguage.RESTRUCTUREDTEXT,
  '.rb': CTagLanguage.RUBY,
  '.ruby': CTagLanguage.RUBY,
  '.rs': CTagLanguage.RUST,
  '.scm': CTagLanguage.SCHEME,
  '.sm': CTagLanguage.SCHEME,
  '.sch': CTagLanguage.SCHEME,
  '.scheme': CTagLanguage.SCHEME,
  '.rkt': CTagLanguage.SCHEME,
  '.scss': CTagLanguage.SCSS,
  '.sh': CTagLanguage.SH,
  '.bsh': CTagLanguage.SH,
  '.bash': CTagLanguage.SH,
  '.ksh': CTagLanguage.SH,
  '.ash': CTagLanguage.SH,
  '.sl': CTagLanguage.SLANG,
  '.sml': CTagLanguage.SML,
  '.sig': CTagLanguage.SML,
  '.sql': CTagLanguage.SQL,
  '.service': CTagLanguage.SYSTEMDUNIT,
  '.socket': CTagLanguage.SYSTEMDUNIT,
  '.device': CTagLanguage.SYSTEMDUNIT,
  '.mount': CTagLanguage.SYSTEMDUNIT,
  '.automount': CTagLanguage.SYSTEMDUNIT,
  '.swap': CTagLanguage.SYSTEMDUNIT,
  '.target': CTagLanguage.SYSTEMDUNIT,
  '.path': CTagLanguage.SYSTEMDUNIT,
  '.timer': CTagLanguage.SYSTEMDUNIT,
  '.snapshot': CTagLanguage.SYSTEMDUNIT,
  '.slice': CTagLanguage.SYSTEMDUNIT,
  '.stp': CTagLanguage.SYSTEMTAP,
  '.stpm': CTagLanguage.SYSTEMTAP,
  '.tcl': CTagLanguage.TCL,
  '.tk': CTagLanguage.TCL,
  '.wish': CTagLanguage.TCL,
  '.exp': CTagLanguage.TCL,
  '.tf': CTagLanguage.TERRAFORM,
  '.tfvars': CTagLanguage.TERRAFORMVARIABLES,
  '.tex': CTagLanguage.TEX,
  '.ttcn': CTagLanguage.TTCN,
  '.ttcn3': CTagLanguage.TTCN,
  '.t2t': CTagLanguage.TXT2TAGS,
  '.ts': CTagLanguage.TYPESCRIPT,
  '.vr': CTagLanguage.VERA,
  '.vri': CTagLanguage.VERA,
  '.vrh': CTagLanguage.VERA,
  '.v': CTagLanguage.VERILOG,
  '.sv': CTagLanguage.SYSTEMVERILOG,
  '.svh': CTagLanguage.SYSTEMVERILOG,
  '.svi': CTagLanguage.SYSTEMVERILOG,
  '.vhdl': CTagLanguage.VHDL,
  '.vhd': CTagLanguage.VHDL,
  '.vim': CTagLanguage.VIM,
  '.vba': CTagLanguage.VIM,
  '.rc': CTagLanguage.WINDRES,
  '.y': CTagLanguage.YACC,
  '.repo': CTagLanguage.YUMREPO,
  '.zep': CTagLanguage.ZEPHIR,
  '.zsh': CTagLanguage.ZSH,
  '.glade': CTagLanguage.GLADE,
  '.pom': CTagLanguage.MAVEN2,
  '.plist': CTagLanguage.PLISTXML,
  '.rng': CTagLanguage.RELAXNG,
  '.svg': CTagLanguage.SVG,
  '.xsl': CTagLanguage.XSLT,
  '.xslt': CTagLanguage.XSLT,
  '.yml': CTagLanguage.YAML,
  '.yaml': CTagLanguage.YAML,
  '.varlink': CTagLanguage.VARLINK,
  '.kt': CTagLanguage.KOTLIN,
  '.kts': CTagLanguage.KOTLIN,
  '.thrift': CTagLanguage.THRIFT,
  '.elm': CTagLanguage.ELM,
  '.rdoc': CTagLanguage.RDOC,
  // We treat Vue as JavaScript
  '.vue': CTagLanguage.JAVASCRIPT,
  // Additional languages that are not supported by CTags.
  '.jsp': CTagLanguage.JSP,
  '.rpgle': CTagLanguage.RPGLE,
  '.ief': CTagLanguage.IEF,
  '.w': CTagLanguage.ABL,
  '.i': CTagLanguage.ABL,
  '.vb': CTagLanguage['VB.NET'],
  '.pkb': CTagLanguage.PLSQL,
  '.pks': CTagLanguage.PLSQL,
  '.cl': CTagLanguage.IBM_CL, // .cl files are also relevant for LISP, we will add support for multiple languages.
  '.clp': CTagLanguage.IBM_CL,
  '.jcl': CTagLanguage.JCL,
  '.prc': CTagLanguage.JCL, // PROC definition
};

export enum CTagMetaType {
  TAG = 'tag',
  PTAG = 'ptag',
}

/**
 * These are all the possible values for CTAG's kind.
 * This can be produced by running `ctags --list-kinds-full`.
 */
export type CTagKind =
  | 'accelerators'
  | 'accessor'
  | 'activeBindingFunc'
  | 'alias'
  | 'altstep'
  | 'anchor'
  | 'annotation'
  | 'anon'
  | 'anonMember'
  | 'architecture'
  | 'array'
  | 'article'
  | 'assembly'
  | 'assert'
  | 'attribute'
  | 'augroup'
  | 'autovar'
  | 'benchmark'
  | 'bibitem'
  | 'bitmap'
  | 'block'
  | 'blockData'
  | 'book'
  | 'booklet'
  | 'boolean'
  | 'build'
  | 'C#'
  | 'C++'
  | 'callback'
  | 'category'
  | 'ccflag'
  | 'cell'
  | 'chapter'
  | 'checker'
  | 'choice'
  | 'citation'
  | 'class'
  | 'clocking'
  | 'combo'
  | 'command'
  | 'common'
  | 'component'
  | 'cond'
  | 'condition'
  | 'conference'
  | 'config'
  | 'const'
  | 'constant'
  | 'constraint'
  | 'constructor'
  | 'Constructor'
  | 'context'
  | 'counter'
  | 'covergroup'
  | 'cursor'
  | 'custom'
  | 'data'
  | 'database'
  | 'dataframe'
  | 'define'
  | 'definition'
  | 'delegate'
  | 'deletedFile'
  | 'derivedMode'
  | 'describe'
  | 'dialog'
  | 'division'
  | 'document'
  | 'domain'
  | 'edesc'
  | 'element'
  | 'entity'
  | 'entry'
  | 'entryspec'
  | 'enum'
  | 'enumConstant'
  | 'enumerator'
  | 'error'
  | 'event'
  | 'exception'
  | 'Exception'
  | 'externvar'
  | 'face'
  | 'fd'
  | 'feature'
  | 'field'
  | 'filename'
  | 'font'
  | 'formal'
  | 'format'
  | 'fragment'
  | 'framesubtitle'
  | 'frametitle'
  | 'fun'
  | 'func'
  | 'function'
  | 'functionVar'
  | 'functor'
  | 'generator'
  | 'generic'
  | 'getter'
  | 'global'
  | 'globalVar'
  | 'grammar'
  | 'group'
  | 'guard'
  | 'handler' // We added this for our queries
  | 'header'
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heredoc'
  | 'hunk'
  | 'icon'
  | 'id'
  | 'identifier'
  | 'ifclass'
  | 'implementation'
  | 'import'
  | 'inbook'
  | 'incollection'
  | 'index'
  | 'infoitem'
  | 'inline'
  | 'inproceedings'
  | 'inputSection'
  | 'instance'
  | 'integer'
  | 'interface'
  | 'iparam'
  | 'kconfig'
  | 'key'
  | 'keyword'
  | 'kind'
  | 'l4subsection'
  | 'l5subsection'
  | 'label'
  | 'langdef'
  | 'langstr'
  | 'library'
  | 'list'
  | 'literal'
  | 'local'
  | 'localvar'
  | 'localVariable'
  | 'loggerSection'
  | 'ltlibrary'
  | 'macro'
  | 'macrofile'
  | 'macroparam'
  | 'macroParameter'
  | 'mainMenu'
  | 'makefile'
  | 'man'
  | 'manual'
  | 'map'
  | 'mastersthesis'
  | 'member'
  | 'menu'
  | 'message'
  | 'method'
  | 'methodSpec'
  | 'minorMode'
  | 'misc'
  | 'mixin'
  | 'mlconn'
  | 'mlprop'
  | 'mltable'
  | 'modifiedFile'
  | 'modport'
  | 'module'
  | 'modulepar'
  | 'mxtag'
  | 'nameattr'
  | 'namelist'
  | 'namespace'
  | 'net'
  | 'nettype'
  | 'newFile'
  | 'node'
  | 'notation'
  | 'null'
  | 'number'
  | 'object'
  | 'OldC++'
  | 'oneof'
  | 'oparam'
  | 'operator'
  | 'optenable'
  | 'option'
  | 'optwith'
  | 'package'
  | 'packageName'
  | 'packspec'
  | 'paragraph'
  | 'param'
  | 'parameter'
  | 'parameterEntity'
  | 'part'
  | 'patch'
  | 'pattern'
  | 'phandler'
  | 'phdthesis'
  | 'pkg'
  | 'placeholder'
  | 'port'
  | 'probe'
  | 'procedure'
  | 'proceedings'
  | 'process'
  | 'program'
  | 'project'
  | 'property'
  | 'protected'
  | 'protectspec'
  | 'protocol'
  | 'protodef'
  | 'prototype'
  | 'publication'
  | 'qmp'
  | 'qualname'
  | 'receiver'
  | 'record'
  | 'RecordField'
  | 'regex'
  | 'region'
  | 'register'
  | 'reopen'
  | 'repoid'
  | 'repr'
  | 'resource'
  | 'role'
  | 'rpc'
  | 'rule'
  | 'run'
  | 'schema'
  | 'script'
  | 'section'
  | 'sectionGroup'
  | 'selector'
  | 'sequence'
  | 'service'
  | 'set'
  | 'setter'
  | 'signal'
  | 'signature'
  | 'singletonMethod'
  | 'slot'
  | 'source'
  | 'sourcefile'
  | 'step'
  | 'string'
  | 'struct'
  | 'structure'
  | 'stylesheet'
  | 'subdir'
  | 'submethod'
  | 'submodule'
  | 'subparagraph'
  | 'subprogram'
  | 'subprogspec'
  | 'subroutine'
  | 'subroutineDeclaration'
  | 'subsection'
  | 'subspec'
  | 'subst'
  | 'substdef'
  | 'subsubsection'
  | 'subtype'
  | 'symbol'
  | 'synonym'
  | 'table'
  | 'tag'
  | 'talias'
  | 'target'
  | 'task'
  | 'taskspec'
  | 'techreport'
  | 'template'
  | 'test'
  | 'testcase'
  | 'theme'
  | 'thriftFile'
  | 'throwsparam'
  | 'timer'
  | 'title'
  | 'token'
  | 'toplevelVariable'
  | 'trait'
  | 'trigger'
  | 'type'
  | 'typealias'
  | 'typedef'
  | 'typespec'
  | 'union'
  | 'unit'
  | 'unknown'
  | 'unpublished'
  | 'username'
  | 'val'
  | 'value'
  | 'var'
  | 'varalias'
  | 'variable'
  | 'varlambda'
  | 'varspec'
  | 'vector'
  | 'version'
  | 'view'
  | 'virtual'
  | 'vresource'
  | 'wrapper'
  | 'xinput';

/**
 * These are all the possible values for CTAG's roles.
 * This can be produced by running `ctags --list-roles`.
 */
export type CTagRoles =
  | 'subunit'
  | 'placement'
  | 'local'
  | 'system'
  | 'cmdline'
  | 'cmdline'
  | 'branched'
  | 'data'
  | 'library'
  | 'ltlibrary'
  | 'man'
  | 'program'
  | 'script'
  | 'condition'
  | 'undef'
  | 'local'
  | 'system'
  | 'condition'
  | 'undef'
  | 'local'
  | 'system'
  | 'condition'
  | 'undef'
  | 'local'
  | 'system'
  | 'condition'
  | 'undef'
  | 'local'
  | 'system'
  | 'copied'
  | 'copied'
  | 'copied'
  | 'attOwner'
  | 'condition'
  | 'elementName'
  | 'partOfAttDef'
  | 'imported'
  | 'import'
  | 'extended'
  | 'imported'
  | 'receiverType'
  | 'extFile'
  | 'extFile'
  | 'attribute'
  | 'imported'
  | 'imported'
  | 'namespace'
  | 'used'
  | 'imported'
  | 'used'
  | 'source'
  | 'discarded'
  | 'mapped'
  | 'entrypoint'
  | 'referenced'
  | 'included'
  | 'sincluded'
  | 'undef'
  | 'included'
  | 'optional'
  | 'included'
  | 'condition'
  | 'undef'
  | 'local'
  | 'system'
  | 'condition'
  | 'undef'
  | 'local'
  | 'system'
  | 'unused'
  | 'used'
  | 'endmarker'
  | 'imported'
  | 'extension'
  | 'imported'
  | 'indirectlyImported'
  | 'namespace'
  | 'imported'
  | 'indirectlyImported'
  | 'library'
  | 'require'
  | 'source'
  | 'undef'
  | 'decl'
  | 'loaded'
  | 'required'
  | 'requiredRel'
  | 'endmarker'
  | 'loaded'
  | 'After'
  | 'Before'
  | 'RequiredBy'
  | 'Requires'
  | 'WantedBy'
  | 'Wants'
  | 'bibliography'
  | 'included'
  | 'input'
  | 'included'
  | 'desigend'
  | 'condition'
  | 'undef'
  | 'local';
