import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { forAllSwimmNodes, parseSwmd, schema, serializeSwmd } from '@swimm/swmd';
import {
  SwimmDocument,
  type WorkspaceDocumentTemplate,
  config,
  getLoggerNew,
  gitwrapper,
  slugify,
} from '@swimm/shared';
import {
  createDocumentTemplate,
  fetchDocumentTemplate,
  fetchDocumentTemplates,
  fetchLastTemplate,
  fetchLastTemplateVersion,
} from '@/modules/core/services/workspace';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import main from '@/modules/customizations/custom-process-to-doc/ppg/main';
import merge from '@/modules/customizations/custom-process-to-doc/ppg/merge';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { DraftType } from '@/modules/drafts3/db.ts';
import { useDoc } from '@/modules/drafts3/composables/doc.ts';
import {
  COMMIT_SUFFIX_PROCESS_TO_DOC,
  MERGE_BASE_FOLDER_PATH,
} from '@/modules/customizations/custom-process-to-doc/commit-helper.ts';

const logger = getLoggerNew(__modulename);

interface ProcessToDocModalOptions {
  unitId: string;
  unitPath: string;
}

export class MissingMergeBaseError extends Error {}

export const useProcessToDocStore = defineStore('processToDoc', () => {
  const store = useStore();
  const { user } = storeToRefs(useAuthStore());
  const { newDoc, saveDraftImmediately } = useDrafts3Store();
  const { id: workspaceId, currentUser } = storeToRefs(useWorkspaceStore());
  const { loadSwmd } = useDoc(workspaceId, ref(''));
  const db_getRepoMetadata = computed(() => store.getters['database/db_getRepoMetadata']);

  const showProcessToDocModal = ref<boolean>(false);
  const showProcessToDocModalOptions = ref<ProcessToDocModalOptions>(null);
  const documentTemplates = ref<Map<string, WorkspaceDocumentTemplate>>(new Map());

  function openProcessToDocModal(options?: ProcessToDocModalOptions) {
    showProcessToDocModal.value = true;
    if (options) {
      showProcessToDocModalOptions.value = options;
    }
  }

  function closeProcessToDocModal() {
    showProcessToDocModal.value = false;
    showProcessToDocModalOptions.value = null;
  }

  async function getDocumentTemplate(templateId: string): Promise<WorkspaceDocumentTemplate> {
    if (!documentTemplates.value.has(templateId)) {
      return await fetchDocumentTemplate(workspaceId.value, templateId);
    }
    return documentTemplates.value.get(templateId);
  }

  async function refreshDocumentTemplates(): Promise<void> {
    documentTemplates.value = await fetchDocumentTemplates(workspaceId.value);
  }

  async function saveDocumentTemplate(template: string, name: string): Promise<string> {
    const currentVersion = await fetchLastTemplateVersion(workspaceId.value, name);
    const newVersion = currentVersion + 1;
    const templateId = `${slugify(name)}-${newVersion}`;

    await createDocumentTemplate(workspaceId.value, templateId, template, name, newVersion);
    void refreshDocumentTemplates();

    return templateId;
  }

  async function fetchMergeBase(
    repoId: string,
    branch: string,
    docId: string,
    currentDocPath: string
  ): Promise<string> {
    const mergeBasePath = `.swm/${MERGE_BASE_FOLDER_PATH}/${docId}.sw.md`;

    // Get commit history for the current file
    const commits = await gitwrapper.fetchFileCommitHistory({ relativeFilePath: currentDocPath, repoId, branch });

    // Search the latest occurrence of a commit that included a mergeBase (marked by the commit suffix)
    const relevantCommit = commits.find((commit) => commit.commitMessage.endsWith(COMMIT_SUFFIX_PROCESS_TO_DOC));

    return gitwrapper.getFileContentFromRevision({
      repoId,
      revision: `${relevantCommit?.sha}`,
      filePath: mergeBasePath,
    });
  }

  function extractFilePaths(doc: SwimmDocument): string[] {
    const filePaths = new Set<string>();
    const rootNode = ProseMirrorNode.fromJSON(schema, doc.content);
    forAllSwimmNodes(rootNode, (node) => {
      if (
        ['swmSnippet', 'swmPath', 'swmToken'].includes(node.type.name) &&
        (node.attrs.path || (node.attrs.href && !node.attrs.href.endsWith('/')))
      ) {
        filePaths.add(node.attrs.path || node.attrs.href);
      }
      return true;
    });

    return Array.from(filePaths);
  }

  async function getCurrentPaths(paths: string[], repoId: string, branch: string): Promise<string[]> {
    const currentPaths = [];
    for (const path of paths) {
      const currentNameResult = await gitwrapper.getCurrentName({
        oldFilePath: path,
        repoId,
        destCommit: branch,
      });

      if (currentNameResult.code === config.ERROR_RETURN_CODE || !currentNameResult.exists) {
        // Do not include this path
        continue;
      }

      currentPaths.push(currentNameResult.currentName);
    }

    return currentPaths;
  }

  async function generateDocument(
    document: { title: string; templateId: string; process: 'cdi' | 'cai'; filePaths: string[] },
    repoId: string,
    branch: string
  ) {
    const repoName: string = db_getRepoMetadata.value(repoId) ? db_getRepoMetadata.value(repoId).name : '';
    const template = (await getDocumentTemplate(document.templateId))?.text;

    return main(document.title, template, document.process, document.filePaths, {
      repoId,
      repoName,
      branch,
      user: {
        id: currentUser.value,
        name: user.value.displayName,
        email: user.value.email,
      },
    });
  }

  async function regenerate({
    current,
    process,
    filePaths,
    repoId,
    branch,
  }: {
    current: {
      document: SwimmDocument;
      docId: string;
      docPath: string;
    };
    process: 'cdi' | 'cai';
    filePaths: string[];
    repoId: string;
    branch: string;
  }): Promise<{ id: string; status: 'ok' | 'conflict' }> {
    try {
      const mergeBase = await fetchMergeBase(repoId, branch, current.docId, current.docPath);
      if (!mergeBase) {
        throw new MissingMergeBaseError(`Failed locating merge base`);
      }

      const { id: templateId } = await fetchLastTemplate(workspaceId.value, process.toUpperCase());

      const regenerated = await generateDocument(
        { title: current.document.title, templateId, process, filePaths },
        repoId,
        branch
      );

      // Perform 3-way merge
      const mergeResult = merge(
        serializeSwmd(current.document, { baseUrl: config.BASE_URL }),
        serializeSwmd(regenerated, { baseUrl: config.BASE_URL }),
        mergeBase
      );

      const swimmDocument = parseSwmd(mergeResult.document);

      // if success - update current doc as draft
      if (mergeResult.status === 'ok') {
        await saveDraftImmediately(
          current.docId,
          { type: DraftType.DOC, content: swimmDocument },
          { originalTitle: current.document.title, mergeBase: swimmDocument }
        );

        return { id: current.docId, status: 'ok' };
      }

      // if fail - create new doc draft with new id and an indicative name
      const newDraft = {
        ...swimmDocument,
        title: `${current.document.title} (regenerated ${new Date().toLocaleDateString()})`,
      };
      const newDraftId = await newDoc(newDraft, { mergeBase: newDraft });

      return { id: newDraftId, status: 'conflict' };
    } catch (err) {
      logger.error({ err }, `Failed regenerating document ${current.docId}`);
      if (err.status === 404 || err.message === 'No commit found for the ref ^') {
        throw new MissingMergeBaseError(`Failed locating merge base`);
      }
      throw err;
    }
  }

  async function generate(
    document: { title: string; templateId: string; process: 'cdi' | 'cai'; filePaths: string[] },
    repoId: string,
    branch: string
  ): Promise<string> {
    const doc = await generateDocument(document, repoId, branch);
    return await newDoc(doc, { mergeBase: doc });
  }

  return {
    showProcessToDocModal,
    showProcessToDocModalOptions,
    openProcessToDocModal,
    closeProcessToDocModal,
    documentTemplates,
    refreshDocumentTemplates,
    getDocumentTemplate,
    saveDocumentTemplate,
    loadSwmd,
    getCurrentPaths,
    extractFilePaths,
    generate,
    regenerate,
  };
});
