<script setup lang="ts">
import type { Editor } from '@tiptap/vue-3';
import path from 'path-browserify';
import { computed, ref, watch, watchEffect } from 'vue';

import {
  type SelectableFolderTree,
  SwmSelectionContentTokenAdvanced,
  SwmSelectionContentTokenPreview,
} from '@swimm/reefui';
import { type FolderTree, type TokenSuggestion, type WorkspaceRepo, productEvents } from '@swimm/shared';
import { SwModal } from '@swimm/ui';

import { getFocsuedSnippetInfo } from '@/composables/focusedSnippet';
import { useTokenSelectionPreview } from '@/composables/tokenSelection/preview';
import { useTokenSuggestionsState } from '@/composables/tokenSelection/suggestionsState';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';
import flatten from 'lodash-es/flatten';

const props = defineProps<{
  editor: Editor;
}>();

const swimmEditorServices = getSwimmEditorServices(props.editor);

const show = swimmEditorServices.showSwmTokenSelectionAdvancedModal;
const initialQuery = swimmEditorServices.swmTokenSelectionAdvancedModalInitialQuery;
const result = swimmEditorServices.swmTokenSelectionAdvancedModalResult;

watch(
  () => show.value,
  (show) => {
    if (show) {
      swimmEditorServices.external.trackEvent(productEvents.TOKEN_SELECTION_OPENED_ADVANCED_MODE, {});
      // in case the folder tree was not loaded before
      if (folderTree.value == null && repoId.value && swimmEditorServices) {
        void loadFolderTree(repoId.value);
      }
    }
  }
);

const repoId = ref(swimmEditorServices.repoId.value);
const isRepoListing = ref(false);

const workspaceRepos = computed(() => swimmEditorServices.repos.value.repos);

const selectedRepo = computed(() => workspaceRepos.value.find((repo) => repo.id === repoId.value));

const reposToShow = computed(() => {
  // Don't show the repo in the files listing if it's the only repo available in the workspace.
  if (workspaceRepos.value.length <= 1 || swimmEditorServices.isIde) {
    return [];
  }

  if (isRepoListing.value) {
    return workspaceRepos.value;
  }

  return selectedRepo.value ? [selectedRepo.value] : [];
});

const folderTree = ref<FolderTree>();
const loadingTree = ref(false);

async function loadFolderTree(repoId: string) {
  const isCrossRepo = repoId !== swimmEditorServices.repoId.value;
  const branch = !isCrossRepo
    ? swimmEditorServices.branch.value
    : swimmEditorServices.repos.value.repos?.find((repo) => repo.id === repoId)?.defaultBranch;
  if (branch == null) {
    return;
  }

  loadingTree.value = true;
  folderTree.value = await swimmEditorServices.external.getRepoFolderTree(repoId, branch, isCrossRepo);
  loadingTree.value = false;
}

watch(
  () => repoId.value,
  async (repoId) => {
    if (repoId && swimmEditorServices) {
      await loadFolderTree(repoId);
    }
  },
  { immediate: true }
);

const query = ref<string>('');
watchEffect(() => (query.value = initialQuery.value));

function selectRepo(repo: WorkspaceRepo) {
  if (isRepoListing.value) {
    repoId.value = repo.id;
  }
  isRepoListing.value = !isRepoListing.value;
}

const tokenSuggestionsState = useTokenSuggestionsState(
  query,
  show,
  swimmEditorServices.external.tokenSuggestionsService,
  swimmEditorServices.sourceFiles.sourceFiles,
  repoId,
  swimmEditorServices.repoId,
  swimmEditorServices.swimmSmartElements,
  getFocsuedSnippetInfo(props.editor)
);

const tokenSelectionPreview = useTokenSelectionPreview(props.editor);

watch(() => query.value, tokenSelectionPreview.reset);

const selectedNodes = computed<SelectableFolderTree[]>(() =>
  flatten(
    Object.entries(swimmEditorServices.sourceFiles.sourceFiles.value).map(([repoId, sourceFilesInRepo]) =>
      getRepoSelectedNodes(repoId, sourceFilesInRepo)
    )
  )
);

function getRepoSelectedNodes(repoId: string, sourceFiles: string[]): SelectableFolderTree[] {
  return sourceFiles.map((filePath) => pathToSelectableFolderTree(repoId, filePath)) ?? [];
}

function pathToSelectableFolderTree(repoId: string, filePath: string): SelectableFolderTree {
  return {
    type: 'file',
    name: path.basename(filePath),
    path: filePath,
    forceEnabled: swimmEditorServices.referencedFiles.value.get(repoId)?.includes(filePath),
    repo: swimmEditorServices.repos.value.repos.filter((iter) => iter.id === repoId)[0],
    isCrossRepo: repoId !== swimmEditorServices.repoId.value,
  };
}

function addSelectedFile(node: FolderTree) {
  swimmEditorServices.sourceFiles.addUserSelectedFile(repoId.value, node.path);
  swimmEditorServices.external.trackEvent(productEvents.TOKEN_SELECTION_ADVANCED_MODE_SELECTED_FILE, {
    'Document ID': swimmEditorServices.unitId.value,
  });
}

function removeSelectedFile(node: SelectableFolderTree) {
  swimmEditorServices.sourceFiles.removeUserSelectedFile(node.repo?.id ?? repoId.value, node.path);
  swimmEditorServices.external.trackEvent(productEvents.TOKEN_SELECTION_ADVANCED_MODE_UNSELECTED_FILE, {
    'Document ID': swimmEditorServices.unitId.value,
  });
}

function onKeydown(e: KeyboardEvent) {
  if (e.ctrlKey && e.shiftKey && e.key === 'K') {
    e.preventDefault();
    showSimple();
  }
}

function selectToken(token: TokenSuggestion) {
  result.value = { showSimple: false, lastQuery: query.value, selectedToken: token };
  show.value = false;
  swimmEditorServices.external.trackEvent(productEvents.SMART_TEXT_TOKEN_ADDED, {
    Context: 'Advanced View',
    Global: token.static,
  });
  tokenSelectionPreview.reset();
}

function showSimple() {
  result.value = { showSimple: true, lastQuery: query.value };
  show.value = false;
  tokenSelectionPreview.reset();
}

function close() {
  result.value = { showSimple: false, lastQuery: query.value };
  show.value = false;
  tokenSelectionPreview.reset();
}
</script>

<template>
  <SwModal :show-modal="show" :padded="false" @close="close" @keydown="onKeydown">
    <div class="swm-token-selection-advanced-modal__container">
      <SwmSelectionContentTokenAdvanced
        v-model="query"
        :workspace-repos="reposToShow"
        :token-suggestions="tokenSuggestionsState.tokenSuggestions"
        :selected-nodes="selectedNodes"
        :node="folderTree!"
        :loading-node="swimmEditorServices.repos.value.loading || loadingTree"
        :loading-token-suggestions="tokenSuggestionsState.loading"
        :no-results="tokenSuggestionsState.noResults"
        @select-token="selectToken"
        @focused-token="tokenSelectionPreview.set"
        @hovered-token="tokenSelectionPreview.set"
        @selected-nodes:add="addSelectedFile"
        @selected-nodes:remove="removeSelectedFile"
        @select-repo="selectRepo"
        @show-simple="showSimple"
        @close="close"
      >
        <template #preview="{ cachedHighlighters }">
          <SwmSelectionContentTokenPreview
            v-if="tokenSelectionPreview.state.value.show"
            :code="tokenSelectionPreview.state.value.code"
            :token-suggestion="tokenSelectionPreview.state.value.token"
            :max-rows="9"
            :cached-highlighters="cachedHighlighters"
          />
        </template>
      </SwmSelectionContentTokenAdvanced>
    </div>
  </SwModal>
</template>

<style scoped lang="scss">
.swm-token-selection-advanced-modal__container {
  height: 80vh;
  width: 100%;
}
</style>
