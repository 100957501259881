<script lang="ts">
export type SelectableFolderTree = FolderTree & {
  forceEnabled?: boolean;
  repo?: WorkspaceRepo;
  isCrossRepo?: boolean; // use if this information is needed
};
</script>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, useSlots, watch } from 'vue';
import type { FolderTree, FolderTreeDirectory, WorkspaceRepo } from '@swimm/shared';
import { pathToId } from '../../lib/node';

import MenuItem from '../MenuItem/MenuItem.vue';
import BaseProse from '../../components/BaseProse/BaseProse.vue';
import BaseHighlight from '../../components/BaseHighlight/BaseHighlight.vue';
import BaseIcon, { type IconsType } from '../../components/BaseIcon/BaseIcon.vue';
import BaseTruncate from '../../components/BaseTruncate/BaseTruncate.vue';
import { BaseTooltip } from '../..';

type ExtendedFolderTree = SelectableFolderTree & {
  closed: boolean;
  level: number;
  parentIndex: number | undefined;
  visibleChildren: number;
};

const props = withDefaults(
  defineProps<{
    query?: string;
    node: FolderTree;
    selectedPath?: string;
    levelOffset?: number;
    selectable?: boolean;
    selectableFolders?: boolean;
    selectedNodes?: SelectableFolderTree[];
  }>(),
  {
    query: undefined,
    selectedPath: undefined,
    levelOffset: 0,
    selectedNodes: undefined,
  }
);

const emit = defineEmits<{
  change: [];
  selectNode: [node: FolderTree];
  'selectedNodes:add': [node: FolderTree];
  'selectedNodes:remove': [node: SelectableFolderTree];
  focusedNode: [node: FolderTree | null];
  noResults: [];
  showingMore: [fromIndex: number];
}>();

const slots = useSlots();

const hasItemTooltip = computed(() => {
  return !!slots['item-tooltip'];
});

const flatNodes = ref<ExtendedFolderTree[]>(initializeFlatNodes(props.node, props.selectedPath, props.selectedNodes));
const searchableNodes = ref<ExtendedFolderTree[] | undefined>();
const page = ref(1);
const limit = ref(30);
const itemRefs = ref<(HTMLElement | null)[]>([]);

const noResults = computed(() => props.query && filteredNodes.value && !filteredNodes.value.length);

const isSearching = computed(
  () => (props.query && props.query.length && filteredNodes.value && filteredNodes.value.length) || noResults.value
);

const filteredNodes = computed(() => {
  const query = props.query;

  if (!props.node || !query || !searchableNodes.value) {
    return;
  }

  const lowerCaseQuery = query.toLowerCase();

  const nameMatches = searchableNodes.value.filter((node) => node.name.toLowerCase().includes(lowerCaseQuery));

  const pathMatches = searchableNodes.value.filter(
    (node) => !node.name.toLowerCase().includes(lowerCaseQuery) && node.path.toLowerCase().includes(lowerCaseQuery)
  );

  return nameMatches.concat(pathMatches);
});

const paginatedFilteredNodes = computed(() => {
  if (!filteredNodes.value) {
    return [];
  }

  return filteredNodes.value.slice(0, page.value * limit.value);
});

const canShowMore = computed(() => {
  if (!filteredNodes.value) {
    return false;
  }

  return page.value * limit.value < filteredNodes.value.length;
});

async function showMore() {
  const currentIndex = paginatedFilteredNodes.value ? paginatedFilteredNodes.value.length : 0;
  emit('showingMore', currentIndex + 1);
  emit('change');

  page.value++;
}

function isAncestorOfSelected(node: FolderTree, selectedPath: string): boolean {
  if (node.path === selectedPath) {
    return true;
  }

  if (node.type === 'directory') {
    for (const child of node.children) {
      if (isAncestorOfSelected(child, selectedPath)) {
        return true;
      }
    }
  }

  return false;
}

function getSelectedNode(node: FolderTree) {
  return props.selectedNodes?.find((existingNode) => node.path === existingNode.path);
}

function initializeFlatNodes(
  rootNode: FolderTree | undefined,
  selectedPath: string | undefined = '',
  selectedNodes: SelectableFolderTree[] | undefined,
  level = 1
): ExtendedFolderTree[] {
  const nodes: ExtendedFolderTree[] = [];

  if (!rootNode || rootNode.type !== 'directory') {
    return nodes;
  }

  for (const node of rootNode.children) {
    let isOpen = isAncestorOfSelected(node, selectedPath);
    if (!isOpen && selectedNodes?.length) {
      isOpen = selectedNodes.some((selectedNode) => isAncestorOfSelected(node, selectedNode.path));
    }
    nodes.push({
      ...node,
      ...(getSelectedNode(node) || {}),
      closed: !isOpen,
      level,
      parentIndex: undefined,
      visibleChildren: 0,
    });

    if (isOpen && isNodeDirectory(node)) {
      nodes.push(...initializeFlatNodes(node, selectedPath, selectedNodes, level + 1));
    }
  }

  return nodes;
}

function setRef(el: { root: HTMLElement | null }, index: number) {
  if (el && el.root) {
    itemRefs.value[index] = el.root;
  }
}

function handleFocused(node: ExtendedFolderTree | null) {
  if (!node) {
    return;
  }

  emit('focusedNode', normalizeNode(node));
}

function handleSelectNode(node: ExtendedFolderTree, index: number, event?: Event) {
  if (props.selectable && node.type === 'file') {
    const fileExists = props.selectedNodes?.find((existingNode) => existingNode.path === node.path);

    if (fileExists) {
      // We only remove a selected file if it isn't forceEnabled.
      if (!fileExists.forceEnabled) {
        emit('selectedNodes:remove', { ...normalizeNode(node), repo: node.repo });
      }

      // Otherwise add the new file to and emit.
    } else {
      emit('selectedNodes:add', normalizeNode(node));
    }

    // We emit selectNode if a file is click, or if we're searching
    // in which case directories emit selectNode as well.
  } else if (node.type === 'file' || props.query?.length) {
    const normalizedNode = normalizeNode(node);

    emit('selectNode', normalizedNode);
  } else if (isNodeDirectory(node) && props.selectableFolders) {
    // Handle directories selection
    const directoryExists = props.selectedNodes?.find((existingNode) => existingNode.path === node.path);

    if (directoryExists) {
      // We only remove a selected directory if it isn't forceEnabled.
      if (!directoryExists.forceEnabled) {
        emit('selectedNodes:remove', { ...normalizeNode(node), repo: node.repo });
      }

      // Otherwise add the new directory to and emit.
    } else {
      emit('selectedNodes:add', normalizeNode(node));
    }

    for (const child of node.children) {
      // If the directory is selected, add all its child files to the selected nodes; otherwise, remove them.
      if (!isNodeDirectory(child)) {
        if (!directoryExists) {
          emit('selectedNodes:add', normalizeNode(child));
        } else {
          emit('selectedNodes:remove', normalizeNode(child));
        }
      }
    }
    // If the directory is closed, open it
    if (node.closed) {
      toggleFolder(index);
    }
  } else if (isNodeDirectory(node)) {
    toggleFolder(index);
  }
}

function handleOnNodeClick(node: ExtendedFolderTree, index: number) {
  if (props.selectableFolders && isNodeDirectory(node)) {
    toggleFolder(index);
  } else {
    handleSelectNode(node, index);
  }
}

function handleKeydown(e: KeyboardEvent, node: ExtendedFolderTree, index: number) {
  if (e.key === 'Enter') {
    e.stopPropagation();

    handleSelectNode(node, index);
  } else if (e.key === 'ArrowRight') {
    e.stopPropagation();

    openFolder(index);
  } else if (e.key === 'ArrowLeft') {
    e.stopPropagation();

    if (node.closed && node.parentIndex !== undefined) {
      closeFolder(node.parentIndex);

      itemRefs.value[node.parentIndex]?.focus();
    } else {
      closeFolder(index);
    }
  }
}

function openFolder(index: number) {
  if (flatNodes.value[index].closed) {
    flatNodes.value[index].closed = false;
    addChildNodes(index);
    emit('change');
  }
}

function closeFolder(index: number) {
  if (!flatNodes.value[index].closed) {
    removeChildNodes(index);
    flatNodes.value[index].closed = true;
    emit('change');
  }
}
function isNodeDirectory(node: FolderTree): node is FolderTreeDirectory {
  return node.type === 'directory';
}

/**
 * Toggles the folder at the given index.
 * If the folder is closed, it opens the folder and recursively opens all child directories.
 * If the folder is open, it closes the folder.
 *
 * @param {number} index - The index of the folder to toggle.
 */
function toggleFolder(index: number) {
  const currentNode = flatNodes.value[index];

  if (currentNode.closed) {
    openFolder(index);
  } else {
    closeFolder(index);
  }
}
function addChildNodes(index: number) {
  const node = flatNodes.value[index];

  if (isNodeDirectory(node)) {
    // If node has a level increase it by 1 or set it to 1.
    const level = node.level ? node.level + 1 : 1;

    // Add all it's child nodes to the listing
    flatNodes.value.splice(
      index + 1,
      0,
      ...node.children.map((node: FolderTree) => {
        return {
          ...node,
          ...(getSelectedNode(node) || {}),
          closed: true,
          level,
          parentIndex: index,
          visibleChildren: 0,
        };
      })
    );

    // Set it's visibileChildren to to children length.
    node.visibleChildren = node.children?.length;
  } else {
    node.visibleChildren = 0;
  }

  syncVisibleChildrenCounters(node, 'add');
}

function removeChildNodes(index: number) {
  const node = flatNodes.value[index];

  // Re-sync children counters before clearing visible children from node.
  // They need to be decremented from the hierachy before being set to 0.
  syncVisibleChildrenCounters(node, 'remove');

  // Remove children from listing
  flatNodes.value.splice(index + 1, node.visibleChildren);

  // Set visible children to 0
  node.visibleChildren = 0;
}

function syncVisibleChildrenCounters(node: ExtendedFolderTree, operation: 'add' | 'remove') {
  let currentNode = node;

  // Store the number of children that have just become visible
  const alterBy = currentNode.visibleChildren;

  // Continue looping until there's no parent node
  while (currentNode.parentIndex !== undefined) {
    const parentNode = flatNodes.value[currentNode.parentIndex];

    // If operation is 'add', increment new number of children, else decrement
    if (operation === 'add') {
      parentNode.visibleChildren = parentNode.visibleChildren + alterBy;
    } else if (operation === 'remove') {
      parentNode.visibleChildren = parentNode.visibleChildren - alterBy;
    }

    // Move up the tree
    currentNode = parentNode;
  }
}

function computeNodeStyle(node: ExtendedFolderTree) {
  const bodyStyles = window.getComputedStyle(document.body);
  const spaceSmall = bodyStyles.getPropertyValue('--space-small').trim();
  const spaceXXSmall = bodyStyles.getPropertyValue('--space-xxsmall').trim();

  const multiplier = props.levelOffset + node.level - 1;

  if (!multiplier) {
    return;
  }

  return {
    paddingLeft: (parseInt(spaceSmall) + parseInt(spaceXXSmall)) * multiplier + 8 + 'px',
  };
}

function getIcon(node: ExtendedFolderTree): IconsType {
  if (isNodeDirectory(node)) {
    if (node.closed || props.query) {
      return 'folder';
    } else {
      return 'folder-open';
    }
  }

  return 'file';
}

function getDirectoryPath(path: string) {
  const directoryPath = path.substring(0, path.lastIndexOf('/'));

  return directoryPath && `${directoryPath}/`;
}

function normalizeNode(node: ExtendedFolderTree | FolderTree): FolderTree {
  if (node.type === 'file') {
    return {
      name: node.name,
      path: node.path,
      type: 'file',
    };
  }

  return {
    name: node.name,
    path: node.path,
    type: 'directory',
    children: node.children.map(normalizeNode),

    // Include the comment property if it's present
    comment: node.comment,
  };
}

function setSearchableNodes() {
  if (!props.node) {
    return;
  }

  const result: SelectableFolderTree[] = [];

  // Recursive function to traverse the object tree
  function traverse(node: SelectableFolderTree) {
    // For searching we're only interested in files
    if (node.type === 'file') {
      result.push(node);

      // If the node is a directory, recurse on its children
    } else if (isNodeDirectory(node)) {
      for (const child of node.children) {
        traverse(child);
      }
    }
  }

  // Start traversal from the root object
  traverse(props.node);

  return result
    .sort((a, b) => {
      const nameA = a.name.startsWith('.') ? a.name.slice(1).toLowerCase() : a.name.toLowerCase();
      const nameB = b.name.startsWith('.') ? b.name.slice(1).toLowerCase() : b.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
    .map((node) => {
      return {
        ...node,
        closed: true,
        level: 1,
        parentIndex: undefined,
        visibleChildren: 0,
      };
    });
}

onMounted(async () => {
  await nextTick();

  searchableNodes.value = setSearchableNodes();
});

// Re-initialize flatNodes is selected changes.
watch(
  () => props.selectedPath,
  (newSelectedPath) => {
    flatNodes.value = initializeFlatNodes(props.node, newSelectedPath, props.selectedNodes);
  },
  { deep: true }
);

watch(
  () => props.node,
  () => {
    flatNodes.value = initializeFlatNodes(props.node, props.selectedPath, props.selectedNodes);
  },
  { deep: true }
);
</script>

<template>
  <template v-if="isSearching">
    <BaseProse v-if="noResults" class="node-tree__empty-state" size="small">No results found.</BaseProse>
    <template v-else>
      <MenuItem
        v-for="(item, index) in paginatedFilteredNodes"
        :key="`${pathToId(item.path)}-search-${index}`"
        :ref="(el: any) => setRef(el, index)"
        class="node-tree__item"
        @click="handleSelectNode(item, index)"
        @keydown.enter="handleSelectNode(item, index)"
      >
        <template #leftIcon>
          <input
            v-if="selectable && item.type === 'file'"
            type="checkbox"
            class="node-tree__item-checkbox"
            :checked="!!getSelectedNode(item)"
            :disabled="getSelectedNode(item)?.forceEnabled"
          />
          <BaseIcon :name="getIcon(item)" />
        </template>
        <BaseHighlight :string="item.name" :query="query" />
        <BaseTruncate v-if="getDirectoryPath(item.path)" align="right"
          ><small class="node-tree__path">
            <BaseHighlight :string="getDirectoryPath(item.path)" :query="query" />
          </small>
        </BaseTruncate>
      </MenuItem>
      <MenuItem
        v-if="canShowMore"
        wrapper="div"
        class="node-tree__show-more"
        @click="showMore"
        @keydown.enter="showMore"
        >Show more…</MenuItem
      >
    </template>
  </template>
  <template v-else>
    <MenuItem
      v-for="(item, index) in flatNodes"
      :id="pathToId(item.path)"
      :key="`${pathToId(item.path)}-${index}`"
      :ref="(el: any) => setRef(el, index)"
      class="node-tree__item"
      :selected="item.path === selectedPath"
      :style="computeNodeStyle(item)"
      @click="handleOnNodeClick(item, index)"
      @focusin="handleFocused(item)"
      @keydown="handleKeydown($event, item, index)"
    >
      <template #leftIcon>
        <input
          v-if="selectable && (!isNodeDirectory(item) || selectableFolders)"
          type="checkbox"
          class="node-tree__item-checkbox"
          :checked="!!getSelectedNode(item)"
          :disabled="item.forceEnabled"
          :aria-labelledby="pathToId(item.path)"
          @click.stop="(event) => handleSelectNode(item, index, event)"
        />
        <BaseIcon :name="getIcon(item)" />
      </template>
      <BaseTooltip>
        <template v-if="hasItemTooltip" #content>
          <slot name="item-tooltip" :item="item" />
        </template>
        {{ item.name }}
      </BaseTooltip>

      <template #additional>
        <slot name="itemAdditional" :item="normalizeNode(item)" />
      </template>
    </MenuItem>
  </template>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.node-tree {
  $self: &;

  @include basic-resets;

  &__item-checkbox {
    cursor: pointer;
  }

  &__item-checkbox:focus {
    outline: none;
  }

  &__empty-state {
    margin: var(--space-small);
    text-align: center;
  }

  &__path {
    color: var(--color-text-secondary);
    line-height: 1;
    width: 100%;
    overflow: hidden;
  }
}
</style>
