<script setup>
import { Icon } from '@swimm/ui';
import { computed } from 'vue';

const props = defineProps({
  popularDocs: { type: Array, default: null },
});

const filteredDocs = computed(() => props.popularDocs.filter((doc) => doc.views !== 0));
</script>

<template>
  <div v-if="!popularDocs.length" class="wrapper">
    <Loader />
  </div>
  <div class="wrapper" v-else>
    <div class="wrapper">
      <SwText variant="headline3">Popular docs</SwText>
      <SwText class="description">All time most frequently viewed docs across your team. </SwText>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              <SwText variant="body-S">NAME</SwText>
            </th>
            <th><SwText variant="body-S">VIEWS</SwText></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(doc, index) in filteredDocs" :key="index">
            <td>
              <SwText variant="body-S" class="number"> {{ index + 1 }}.</SwText>
            </td>
            <td>
              <a :href="doc.link" target="_blank">
                <SwText variant="body-S" class="doc-name">
                  <Icon no-padding class="icon" name="doc" /> {{ doc.name }}
                </SwText>
              </a>
            </td>
            <td>
              <SwText variant="body-S" class="views">
                <Icon no-padding class="icon" name="eye" /> {{ doc.views }}
              </SwText>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;

  .description {
    margin-top: var(--space-base);
    margin-bottom: var(--space-sm);
    color: var(--text-color-secondary);
  }
}

table {
  border-collapse: collapse;
}

th {
  text-align: left;
  color: var(--text-color-secondary);
  padding-bottom: var(--space-md);
}

td {
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
}

tr:not(:first-child):hover {
  background-color: var(--color-surface);
}

tr > td:first-child {
  text-align: center;
}

.number {
  color: var(--text-color-secondary);
  padding-right: var(--space-base);
}

.views {
  padding-right: var(--space-base);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.doc-name {
  padding-right: var(--space-xl);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 15vw;
}

.icon {
  color: var(--text-color-primary);
  font-size: var(--fontsize-m);
}

.empty-state {
  flex: 1;
  justify-content: center;
  text-align: center;
}
</style>
