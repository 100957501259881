// @ts-strict
import path from 'path-browserify';
import {
  AllMergeRequestsOptions,
  CommitAction,
  Gitlab,
  NamespaceSchema,
  RepositoryFileExpandedSchema,
} from '@gitbeaker/rest';
import type { AxiosResponse, Method } from 'axios';
import axios from 'axios';
import Cache from 'lru-cache';
import * as config from '../../config';
import { SWM_FOLDER_IN_REPO } from '../../config';
import * as objectUtils from '../../objectUtils';
import type { ResultWithReturnCode, SwmResourceFile } from '../../types';
import { GitProviderName, PathType, SwmResourceState } from '../../types';
import { b64decodeAndRemoveCR, b64encodeString } from '../../utils/string-utils';
import UrlUtils from '../../utils/url-utils';
import gitProviderUtils from '../git-provider-utils';
import { ChangeRequestData, changeRequestsPendingDocsReducer, isSwmImageInRepo } from '../git-utils-common';
import {
  APIRequestType,
  Branch,
  type Commit,
  CreatedPR,
  DiffFileMetadata,
  DriverOptions,
  GitDriverBase,
  PartialRemoteRepository,
  PendingPR,
  PrData,
  ProviderSpecificTerms,
  PullRequestState,
  RemoteRepository,
  RepoIdOrRepoData,
  githubChangeStatus,
} from './git-provider-base';
import { extractResourceIdFromPath } from '../../doc-logic';
import { getLoggerNew } from '#logger';

const logger = getLoggerNew("packages/shared/src/git-utils/gitdrivers/gitlab-driver.ts");
const MAX_ITEMS_TO_FETCH = 100;
const TOKEN_EXPIRY_TIME_IN_SECONDS = 115 * 60; // The token expires after 2 hours, we will refresh it after 1:55 hours

const CACHE_TTL_MS = 5_000;

interface GitbeakerRequesterError extends Error {
  cause: {
    description: string;
    request: Request;
    response: Response;
  };
}

type GetRepoTreeCacheKey = `RID:${string}-BSHA:${string}-PATH:${string}-RECURSIVE:${string}`;

export class GitLabDriver extends ProviderSpecificTerms implements GitDriverBase {
  private _refreshTokenRequestCache: Promise<void>;
  private infoFromPR = `
            iid
            title
            description
            createdAt
            updatedAt
            author{
              username
            }
            state
            webUrl
            sourceBranch
            diffRefs{
              headSha
              startSha
            }
            diffStats {
              path
              additions
              deletions
            }
            diffStatsSummary {
              additions
            }`;

  private baseUrl;
  private authToken;
  private hostname;
  private isEnterprise;

  public constructor({ baseUrl = 'https://gitlab.com', authToken = null } = {}) {
    super();
    this.baseUrl = baseUrl.endsWith('/') ? baseUrl.substring(0, baseUrl.length - 1) : baseUrl;
    this.authToken = authToken;
    this.hostname = UrlUtils.getUrlHostname(this.baseUrl);
    this.isEnterprise = baseUrl !== gitProviderUtils.DEFAULT_GITLAB_API;
  }

  private invalidateCaches({
    repoId,
    revision,
    filePath,
  }: {
    repoId?: string;
    revision?: string;
    filePath?: string;
  }): void {
    if (!revision || !repoId) {
      this.getBranchCache.clear();
      this.fetchFileDataCache.clear();
      this.getPathTypeCache.clear();
      return;
    }
    const branchCacheKey = `${revision}-${repoId}`;
    this.getBranchCache.delete(branchCacheKey);
    if (filePath) {
      const fetchFileKey = `${repoId}-${filePath}-${revision}-${false}`;
      this.fetchFileDataCache.delete(fetchFileKey);
      const pathTypeKey = `${repoId}-${filePath}-${revision}`;
      this.getPathTypeCache.delete(pathTypeKey);
    } else {
      this.fetchFileDataCache.clear();
      this.getPathTypeCache.clear();
    }
  }

  private async refreshAuthToken() {
    const newToken = await gitProviderUtils.refreshAuthToken({
      provider: this.isEnterprise ? GitProviderName.GitLabEnterprise : GitProviderName.GitLab,
      hostname: this.hostname,
      expiryTimeInSeconds: TOKEN_EXPIRY_TIME_IN_SECONDS,
    });

    if (newToken) {
      this.authToken = newToken;
    }

    this._refreshTokenRequestCache = null;
  }

  private async getAuthToken() {
    if (!this.authToken) {
      this.authToken = await gitProviderUtils.getGitHostingToken(this.hostname);
    }

    if (!this.authToken) {
      const err = 'Initializing gitbeaker failed for no token found.';
      logger.error({ err });
      throw new Error(err);
    }

    if (!this._refreshTokenRequestCache) {
      this._refreshTokenRequestCache = this.refreshAuthToken();
    }

    await this._refreshTokenRequestCache;

    return this.authToken;
  }

  /**
   * Returns a gitbeaker instance initialized with gitlab auth token
   */
  private async getGitBeakerInstanceWithAuth() {
    const oauthToken = await this.getAuthToken();

    if (!oauthToken) {
      const err = 'Initializing gitbeaker for gitlab failed for no token found.';
      logger.error(err);
      throw new Error(err);
    }

    return new Gitlab({ host: this.baseUrl, oauthToken });
  }

  /**
   * Get the "native" GitLab API endpoint URL
   * @param type - GraphQL / REST
   * @param host - optional, for on-prem
   * @Returns the URL prefix for the native GQL/Rest API call
   */
  private getGitLabURLPrefix(type: APIRequestType, host = this.baseUrl): string {
    const apiURL = type === APIRequestType.GRAPHQL ? '/api/graphql' : '/api/v4';
    return `${host}${apiURL}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async sendApiRequest<T = any>(
    type: APIRequestType,
    method: Method,
    uri: string,
    data?: object
  ): Promise<AxiosResponse<T>> {
    return axios({
      method,
      url: `${this.getGitLabURLPrefix(type)}/${uri}`,
      data,
      headers: { Authorization: `Bearer ${await this.getAuthToken()}` },
    });
  }

  private async sendGraphQLQuery(query: string, nestedProperty?: string) {
    let result = (await this.sendApiRequest(APIRequestType.GRAPHQL, 'post', '', { query })).data.data;
    if (nestedProperty) {
      const nestedProperties = nestedProperty.split('.');
      for (const [index, prop] of nestedProperties.entries()) {
        result = result[prop];
        if (index < nestedProperties.length - 1 && !objectUtils.isObject(result)) {
          throw new Error(
            `Unable to get nested property '${nestedProperty}' from GraphQL response: '${prop}' is ${result}, not an object`
          );
        }
      }
    }
    return result;
  }

  private async getGitLabProjectId(repoId: string) {
    const repoData = await gitProviderUtils.getRepoStateData(repoId);
    return `${repoData.owner}/${repoData.repoName}`;
  }

  private fetchFileDataCache = new Cache<string, RepositoryFileExpandedSchema>({
    ttl: CACHE_TTL_MS,
    ttlResolution: CACHE_TTL_MS / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });

  private async fetchFileDataFromGitLab({
    repoId,
    filePath,
    revision = 'HEAD',
    safe = false,
  }: {
    filePath: string;
    repoId: string;
    revision: string;
    safe?: boolean;
  }): Promise<RepositoryFileExpandedSchema> {
    const cacheKey = `${repoId}-${filePath}-${revision}-${safe}`;
    const cacheHit = this.fetchFileDataCache.get(cacheKey);
    if (cacheHit) {
      return cacheHit;
    }
    const api = await this.getGitBeakerInstanceWithAuth();
    const projectId = await this.getGitLabProjectId(repoId);
    try {
      const result = await api.RepositoryFiles.show(projectId, filePath, revision);
      this.fetchFileDataCache.set(cacheKey, result);
      return result;
    } catch (err) {
      const error = err as unknown as GitbeakerRequesterError;
      // Do not throw exception on HTTP 404 (file not found) if safe is true
      if (safe && error?.cause?.response?.status === 404) {
        return null;
      }

      let unitId = '';
      try {
        unitId = extractResourceIdFromPath(filePath);
      } catch (ex) {
        // Do nothing
      }

      logger.warn(
        { err },
        `Failed getting file data from gitlab. Repo: ${repoId}, ${
          unitId ? `failing unitId: ${unitId}` : `Failing file extension: ${path.extname(filePath)}`
        }`
      );
      throw err;
    }
  }

  private async fetchCommitList({
    repoId,
    branch,
    maxCommitsNumber,
    relativeFilePath,
    maxPagesNumber = MAX_ITEMS_TO_FETCH,
  }: {
    repoId: string;
    branch: string;
    maxCommitsNumber?: number;
    relativeFilePath?: string;
    maxPagesNumber?: number;
  }) {
    try {
      const api = await this.getGitBeakerInstanceWithAuth();
      const projectId = await this.getGitLabProjectId(repoId);

      let page = 1;
      const perPage = Math.min(maxCommitsNumber, MAX_ITEMS_TO_FETCH) || MAX_ITEMS_TO_FETCH;
      let commitsChunk = [];
      let allCommits: Awaited<ReturnType<typeof api.Commits.all>> = [];
      do {
        commitsChunk = await api.Commits.all(projectId, {
          path: relativeFilePath,
          refName: branch,
          perPage,
          page,
        });
        allCommits = [...allCommits, ...commitsChunk];
        page++;
      } while (
        commitsChunk.length === MAX_ITEMS_TO_FETCH &&
        (!maxCommitsNumber || allCommits.length < maxCommitsNumber) &&
        page < maxPagesNumber
      );
      return allCommits;
    } catch (error) {
      return [];
    }
  }

  deleteTokenFromMemory(): void {
    this.authToken = null;
  }

  async getUserData(
    _provider: GitProviderName,
    _driverOptions?: DriverOptions
  ): Promise<{ login: string; id: number }> {
    const api = await this.getGitBeakerInstanceWithAuth();
    const user = await api.Users.showCurrentUser();
    return { login: user.username, id: user.id };
  }

  private async getOrganizations(): Promise<NamespaceSchema[]> {
    const api = await this.getGitBeakerInstanceWithAuth();
    const allNamespaces = await api.Namespaces.all();
    return allNamespaces.filter((namespace) => namespace.kind === 'group');
  }

  /**
   * NOTE: this function isn't used right now (used for GitHub Marketplace).
   * The implementation is specific for this usecase, do not use for any other purpose.
   */
  async getOrganizationData(
    _provider: GitProviderName,
    _driverOptions?: DriverOptions
  ): Promise<{ name: string; numOfDevelopers: number }> {
    const namespaces = await this.getOrganizations();
    return namespaces.length === 1
      ? { name: namespaces[0]?.name, numOfDevelopers: namespaces[0]?.billable_members_count }
      : { name: '', numOfDevelopers: 0 };
  }

  /*
   * Returns the name of a request for changes, i.e a Merge Request
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getChangeRequestName({ repoId }: { repoId: string }): Promise<string> {
    return this.getTerminology(GitProviderName.GitLab).pullRequest;
  }

  async createBranch({
    repoId,
    branchName,
    sourceSha,
  }: {
    repoId: string;
    branchName: string;
    sourceSha: string;
  }): Promise<void> {
    const api = await this.getGitBeakerInstanceWithAuth();
    const projectId = await this.getGitLabProjectId(repoId);
    await api.Branches.create(projectId, branchName, sourceSha);
  }

  async createPullRequest({
    repoId,
    fromBranch,
    toBranch,
    title,
    body,
  }: {
    repoId: string;
    fromBranch: string;
    toBranch: string;
    title?: string;
    body?: string;
  }): Promise<CreatedPR> {
    const api = await this.getGitBeakerInstanceWithAuth();
    const projectId = await this.getGitLabProjectId(repoId);
    const mergeRequestData = await api.MergeRequests.create(projectId, fromBranch, toBranch, title, {
      description: body,
    });
    return { url: mergeRequestData.web_url };
  }

  async deleteFileIfExists({
    filePath,
    branch,
    commitMessage,
    repoId,
  }: {
    repoId: string;
    branch: string;
    filePath: string;
    commitMessage: string;
  }): Promise<void> {
    const projectId = await this.getGitLabProjectId(repoId);
    const isFileExists = await this.isFileExistsOnRevision({ filePath, repoId, revision: branch });
    const encodedFilePath = encodeURIComponent(filePath);
    if (isFileExists) {
      // Currently, using the web REST API as of an open issue: https://github.com/jdalrymple/gitbeaker/issues/2319
      const uri = `projects/${encodeURIComponent(
        projectId
      )}/repository/files/${encodedFilePath}?branch=${branch}&commit_message=${commitMessage}`;
      await this.sendApiRequest(APIRequestType.REST, 'delete', uri);
      this.invalidateCaches({ repoId, revision: branch, filePath });
    }
  }

  async isBranchExists({ repoId, branchName }: { repoId: string; branchName: string }): Promise<boolean> {
    try {
      await this.getBranch({ repoId, branchName });
      return true;
    } catch (err: unknown) {
      const error = err as GitbeakerRequesterError;
      if (error.cause.response.status === 404) {
        return false;
      }

      throw err;
    }
  }

  private getBranchCache = new Cache<string, Branch>({
    ttl: CACHE_TTL_MS,
    ttlResolution: CACHE_TTL_MS / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });

  async getBranch({
    repoId,
    branchName,
    cacheBuster,
  }: {
    repoId: string;
    branchName: string;
    cacheBuster?: boolean;
  }): Promise<Branch> {
    const cacheKey = `${branchName}-${repoId}`;
    if (!cacheBuster) {
      const cacheHit = this.getBranchCache.get(cacheKey);
      if (cacheHit) {
        return cacheHit;
      }
    }
    const projectId = await this.getGitLabProjectId(repoId);
    const gitlab = await this.getGitBeakerInstanceWithAuth();
    const queryOptions = cacheBuster ? { cacheBuster: Math.random() } : {};
    const result = await gitlab.Branches.show(projectId, branchName, queryOptions as unknown);
    const branch = {
      id: result.commit.id,
      name: branchName,
      sha: result.commit.id,
      lastUpdated: result.commit.committed_date,
      protected: result.protected,
    };
    this.getBranchCache.set(cacheKey, branch);
    return branch;
  }

  private async getGitLabMergeRequest(
    projectId: string,
    state: string,
    page: number
  ): Promise<{ branches: Branch[]; next: boolean }> {
    const gitlab = await this.getGitBeakerInstanceWithAuth();
    const {
      data,
      paginationInfo: { next },
    } = await gitlab.MergeRequests.all({
      projectId,
      state: state as AllMergeRequestsOptions['state'],
      showExpanded: true,
      page,
      perPage: MAX_ITEMS_TO_FETCH,
    });
    return {
      branches: data.map((pr) => ({
        id: pr.source_branch,
        name: pr.source_branch,
        sha: pr.sha,
        lastUpdated: pr.updated_at,
        // not clear how to get this info from the API
        protected: false,
      })),
      next: !!next,
    };
  }

  async *getBranches({
    repoId,
    prStates,
  }: {
    repoId: string;
    prStates?: PullRequestState[];
  }): AsyncIterable<{ hasNextPage: boolean; branches: Branch[] }> {
    const projectId = await this.getGitLabProjectId(repoId);
    const gitlab = await this.getGitBeakerInstanceWithAuth();

    let page = 1;
    let hasNextPage = false;
    const queryOptions = {
      perPage: MAX_ITEMS_TO_FETCH,
      showExpanded: true,
    };

    let currentPRStateIndex = 0;
    let currentState = prStates?.[currentPRStateIndex];

    do {
      let branches: Branch[] = [];
      if (prStates?.length) {
        if (currentState) {
          ({ branches, next: hasNextPage } = await this.getGitLabMergeRequest(projectId, currentState, page++));
          if (!hasNextPage) {
            currentPRStateIndex++;
            currentState = prStates[currentPRStateIndex];
            hasNextPage = !!currentState;
            page = 1;
          }
        }
      } else {
        const {
          data,
          paginationInfo: { next },
        } = await gitlab.Branches.all(projectId, {
          ...queryOptions,
          page: page++,
          showExpanded: true,
        });
        hasNextPage = !!next;
        branches.push(
          ...data.map((branch) => ({
            id: branch.commit.Id as string,
            name: branch.name,
            sha: branch.commit.Id as string,
            protected: branch.protected,
            lastUpdated: branch.commit.committed_date as string,
          }))
        );
      }
      yield {
        hasNextPage,
        branches,
      };
    } while (hasNextPage);
  }

  private async getFileBlobSha({
    repoId,
    filePath,
    revision,
  }: {
    repoId: string;
    filePath: string;
    revision?: string;
  }): Promise<string> {
    try {
      const fileData = await this.fetchFileDataFromGitLab({ repoId, filePath, revision });
      return fileData.blob_id;
    } catch (err) {
      logger.warn({ err }, `Got error trying to get files blobsha, error: ${err}`);
      return '';
    }
  }

  async getFileContentFromRevision({
    filePath,
    repoId,
    revision,
    raw = false,
    safe = false,
  }: {
    filePath: string;
    repoId: string;
    revision: string;
    raw?: boolean;
    safe?: boolean;
  }): Promise<string> {
    const fileData = await this.fetchFileDataFromGitLab({ filePath, repoId, revision, safe });
    if (safe && !fileData) {
      return null;
    }
    if (raw || !fileData.content) {
      return fileData.content;
    }

    return b64decodeAndRemoveCR(fileData.content);
  }

  async getGitDiffRemote({ repoId, base, head }: { repoId: string; base: string; head: string }): Promise<string> {
    const api = await this.getGitBeakerInstanceWithAuth();
    const projectId = await this.getGitLabProjectId(repoId);
    const glComparison = await api.Repositories.compare(projectId, base, head);
    const diffStrings: string[] = [];
    for (const diff of glComparison.diffs) {
      const diffLines: string[] = [];
      diffLines.push(`diff --git a/${diff.old_path} b/${diff.new_path}`);
      diffLines.push(`--- ${diff.new_file ? '/dev/null' : 'a/' + diff.old_path}`);
      diffLines.push(`+++ ${diff.deleted_file ? '/dev/null' : 'b/' + diff.new_path}`);
      diffLines.push(diff.diff);
      diffStrings.push(diffLines.join('\n'));
    }
    return diffStrings.join('\n');
  }

  async getLastCommitShaWhereFileExisted(
    relativeFilePath: string,
    repoId: string,
    destCommit: string
  ): Promise<string> {
    try {
      const commits = await this.fetchCommitList({
        repoId,
        maxCommitsNumber: 1,
        relativeFilePath,
        branch: destCommit,
      });
      return commits[0].id;
    } catch (ex) {
      return '';
    }
  }

  async fetchFileCommitHistory(_params: {
    repoId: string;
    branch: string;
    relativeFilePath: string;
    maxCommitsNumber?: number;
    maxPagesNumber?: number;
  }): Promise<Commit[]> {
    throw new Error('Method fetchFileCommitHistory not implemented.');
  }

  private getPathTypeCache = new Cache<string, ReturnType<typeof this.getPathType>>({
    ttl: CACHE_TTL_MS,
    ttlResolution: CACHE_TTL_MS / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });

  async getPathType({
    path,
    repoId,
    revision,
  }: {
    path: string;
    repoId: string;
    revision: string;
  }): Promise<ResultWithReturnCode<{ pathType: PathType }, { errorMessage: string }>> {
    const cacheKey = `${repoId}-${path}-${revision}`;
    const cacheHit = this.getPathTypeCache.get(cacheKey);
    if (cacheHit) {
      return cacheHit;
    }
    const getPathTypeLogic = async () => {
      let pathType: PathType = PathType.Folder;
      const projectId = await this.getGitLabProjectId(repoId);
      const api = await this.getGitBeakerInstanceWithAuth();
      try {
        try {
          const treeData = await api.Repositories.allRepositoryTrees(projectId, {
            path: path,
            ref: revision,
            perPage: 100,
          });
          if (treeData.length === 0) {
            // TreeData will contain an empty array if the path is a file/ doesn't exist.
            // At some point GitLab changed their API to return 404 for files that don't exist, this way we handle both cases.
            throw new Error('Returned empty treeData');
          }
        } catch (err: unknown) {
          // If this function will throw - it means that the path doesn't exist.
          await this.fetchFileDataFromGitLab({ filePath: path, repoId, revision });
          pathType = PathType.File;
        }
        return { code: config.SUCCESS_RETURN_CODE, pathType: pathType } as const;
      } catch (err: unknown) {
        const error = err as GitbeakerRequesterError;
        return { code: config.ERROR_RETURN_CODE, errorMessage: error.message } as const;
      }
    };
    const logicPromise = getPathTypeLogic();
    this.getPathTypeCache.set(cacheKey, logicPromise);
    return logicPromise;
  }

  async getPendingDocsForBranch({
    repoId,
    branchName,
  }: {
    repoId: string;
    branchName: string;
  }): Promise<Record<string, PendingPR[]>> {
    const projectId = await this.getGitLabProjectId(repoId);
    const nodes = await this.sendGraphQLQuery(
      `{
        project(fullPath: "${projectId}") {
          mergeRequests(first:${MAX_ITEMS_TO_FETCH}, state: opened, sort: UPDATED_DESC, targetBranches:["${branchName}"]) {
            nodes {
              ${this.infoFromPR}
            }
          }
        }
      }`,
      'project.mergeRequests.nodes'
    );
    const mergeRequestsData: ChangeRequestData[] = nodes.map(
      (pr) =>
        ({
          files: pr.diffStats.map((file) => ({
            additionsInFile: file.additions,
            path: file.path,
            additions: file.additions,
            deletions: file.deletions,
          })),
          sourceBranchName: pr.sourceBranch,
          prId: parseInt(pr.iid, 10),
          prTitle: pr.title,
          createdAt: pr.createdAt,
          updatedAt: pr.updatedAt,
        } as ChangeRequestData)
    );
    return changeRequestsPendingDocsReducer(mergeRequestsData);
  }

  private mapPrFiles(pr) {
    return pr.diffStats.map((file) => ({ additionsInFile: file.additions, path: file.path }));
  }

  private mapPrData(pr): PrData {
    return {
      files: this.mapPrFiles(pr),
      state: pr.state,
      prId: pr.iid,
      filesWithAdditions: pr.diffStatsSummary.additions,
      createdAt: pr.createdAt,
      updatedAt: pr.updatedAt,
      url: pr.webUrl,
      title: pr.title,
      description: pr.body,
      prHeadSha: pr.diffRefs?.headSha,
      prBaseSha: pr.diffRefs?.startSha,
      sourceBranchName: pr.sourceBranch,
      author: { username: pr.author.username },
    };
  }

  async getPr({ repoId, prId }: { repoId: string; prId: string }): Promise<PrData> {
    const projectId = await this.getGitLabProjectId(repoId);
    const mergeRequest = await this.sendGraphQLQuery(
      `{
        project(fullPath: "${projectId}") {
          mergeRequest(iid: "${prId}") {
            ${this.infoFromPR}
          }
        }
      }`,
      'project.mergeRequest'
    );
    return this.mapPrData(mergeRequest);
  }

  async getPrs({ repoId, prState }: { repoId: string; prState?: string }): Promise<PrData[]> {
    const projectId = await this.getGitLabProjectId(repoId);
    const nodes = await this.sendGraphQLQuery(
      `{
        project(fullPath: "${projectId}") {
          mergeRequests(first:${MAX_ITEMS_TO_FETCH}, ${prState ? `state: ${prState},` : ''} sort: UPDATED_DESC) {
            nodes {
              ${this.infoFromPR}
            }
          }
        }
      }`,
      'project.mergeRequests.nodes'
    );
    return nodes.map((pr) => this.mapPrData(pr));
  }

  async getRepoRemoteData({ repoId, repoName, owner }: RepoIdOrRepoData): Promise<RemoteRepository> {
    const projectId = owner && repoName ? `${owner}/${repoName}` : await this.getGitLabProjectId(repoId);
    const gitlab = await this.getGitBeakerInstanceWithAuth();
    const project = await gitlab.Projects.show(projectId);

    return {
      name: project.path,
      owner: project.namespace.full_path,
      isPrivate: project.visibility !== 'public', // NOTE: for some reason, `visibility` isn't registered as part of the given type, but it's there.
      defaultBranch: project.default_branch,
      htmlUrl: project.web_url,
      cloneUrl: project.http_url_to_repo,
      fork: project.forked_from_project !== undefined, // NOTE: for some reason, `forked_from_project` isn't registered as part of the given type, but it's there.
      writeAccess: true,
    };
  }

  async getRepoRemoteDataBatch(): Promise<Record<string, PartialRemoteRepository>> {
    return {};
  }

  private getRepoTreeCache = new Cache<GetRepoTreeCacheKey, ReturnType<typeof this.getRepoTree>>({
    ttl: 300_000,
    ttlResolution: 300_000 / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });

  async getRepoTree({
    repoId,
    recursive,
    treeSha,
    cacheBuster,
    path,
  }: {
    repoId: string;
    recursive?: boolean;
    treeSha?: string;
    cacheBuster?: boolean;
    path?: string;
  }): Promise<{ path?: string; mode?: string; type?: string; sha?: string; size?: number; url?: string }[]> {
    const branch = await this.getBranch({ repoId, branchName: treeSha });
    const cacheKey: GetRepoTreeCacheKey = `RID:${repoId}-BSHA:${branch.sha}-PATH:${path}-RECURSIVE:${recursive}`;
    const cacheHit = this.getRepoTreeCache.get(cacheKey);
    if (cacheHit) {
      return cacheHit;
    }
    const getRepoTreeLogic = async () => {
      const api = await this.getGitBeakerInstanceWithAuth();
      const projectId = await this.getGitLabProjectId(repoId);
      const cacheBusterOptions = cacheBuster ? { cacheBuster: Math.random() } : {};
      const tree = await api.Repositories.allRepositoryTrees(projectId, {
        recursive,
        ref: treeSha,
        path,
        ...cacheBusterOptions,
        perPage: 100,
      });
      return tree.map((file) => ({ path: file.path, mode: file.mode, type: file.type, sha: file.id }));
    };
    const operation = getRepoTreeLogic();
    this.getRepoTreeCache.set(cacheKey, operation);
    return operation;
  }

  async *getUserRemoteRepositories(
    provider: GitProviderName, // eslint-disable-line @typescript-eslint/no-unused-vars
    driverOptions?: DriverOptions // eslint-disable-line @typescript-eslint/no-unused-vars
  ): AsyncIterable<RemoteRepository> {
    const gitlab = await this.getGitBeakerInstanceWithAuth();
    const ret = await gitlab.Projects.all({ membership: true });
    for (const project of ret) {
      yield {
        name: project.path,
        owner: project.namespace.full_path,
        isPrivate: project.visibility !== 'public', // NOTE: for some reason, `visibility` isn't registered as part of the given type, but it's there.
        defaultBranch: project.default_branch,
        htmlUrl: project.web_url,
        cloneUrl: project.http_url_to_repo,
        fork: project.forked_from_project !== undefined, // NOTE: for some reason, `forked_from_project` isn't registered as part of the given type, but it's there.
        writeAccess: true,
      };
    }
  }

  async isFileExistsOnRevision({
    repoId,
    filePath,
    revision,
  }: {
    repoId: string;
    filePath: string;
    revision?: string;
  }): Promise<boolean> {
    const fileType = await this.getPathType({
      path: filePath,
      repoId,
      revision,
    });
    return fileType.code === config.SUCCESS_RETURN_CODE;
  }

  private createAction(file: SwmResourceFile): CommitAction {
    if (file.state === SwmResourceState.Created || file.state === SwmResourceState.Updated) {
      logger.debug(`Marking file for ${file.state === SwmResourceState.Created ? 'addition' : 'update'}: ${file.path}`);
      return {
        action: file.state === SwmResourceState.Created ? 'create' : 'update',
        filePath: file.path,
        content: file.isBase64Encoded ? file.content : b64encodeString(file.content),
        encoding: 'base64',
      };
    } else if (file.state === SwmResourceState.Renamed) {
      return {
        action: 'move',
        filePath: file.path,
        previousPath: file.oldPath,
        content: file.isBase64Encoded ? file.content : b64encodeString(file.content),
        encoding: 'base64',
      };
    } else if (file.state === SwmResourceState.Deleted) {
      return {
        action: 'delete',
        filePath: file.path,
      };
    } else {
      throw new Error(`Unsupported file state: ${file['state']}`);
    }
  }

  async pushMultipleFilesToBranch({
    files,
    repoId,
    branch,
    commitMessage,
  }: {
    files: SwmResourceFile[];
    repoId: string;
    branch: string;
    commitMessage: string;
  }) {
    const api = await this.getGitBeakerInstanceWithAuth();
    const projectId = await this.getGitLabProjectId(repoId);

    const actions: CommitAction[] = [];
    for (const file of files) {
      actions.push(this.createAction(file));
    }

    await api.Commits.create(projectId, branch, commitMessage, actions);
    this.invalidateCaches({ repoId, revision: branch });
  }

  async getAllSwmFilesContent({
    repoId,
    revision,
    path = SWM_FOLDER_IN_REPO,
  }: {
    repoId: string;
    revision: string;
    path?: string;
  }): Promise<{ path: string; content: string }[]> {
    const api = await this.getGitBeakerInstanceWithAuth();
    const projectId = await this.getGitLabProjectId(repoId);
    const files: { path: string; content: string }[] = [];
    const { data } = await api.Repositories.allRepositoryTrees(projectId, {
      recursive: true,
      ref: revision,
      path,
      perPage: MAX_ITEMS_TO_FETCH,
      showExpanded: true,
    });
    const result = await Promise.allSettled(
      data
        .filter((file) => !isSwmImageInRepo(file?.path ?? '')) // skip .swm images
        .map(async (file) => {
          const fileData = await this.getFileContentFromRevision({ repoId, revision, filePath: file.path });
          return { path: file.path, content: fileData };
        })
    );
    files.push(
      ...result
        .filter((res) => res.status === 'fulfilled')
        .map((fulfilled) => (fulfilled as PromiseFulfilledResult<{ path: string; content: string }>).value)
    );
    return files;
  }

  async getDiffFiles({
    repoId,
    compareFrom,
    compareTo,
    includeShas,
  }: {
    repoId: string;
    compareFrom: string;
    compareTo: string;
    includeShas?: boolean;
  }): Promise<DiffFileMetadata[]> {
    const api = await this.getGitBeakerInstanceWithAuth();
    const projectId = await this.getGitLabProjectId(repoId);
    const glComparison = await api.Repositories.compare(projectId, compareFrom, compareTo);
    const diffFilesMetadata: DiffFileMetadata[] = [];
    for (const diff of glComparison.diffs) {
      const diffStatus: githubChangeStatus = diff.new_file
        ? githubChangeStatus.Added
        : diff.deleted_file
        ? githubChangeStatus.Deleted
        : diff.renamed_file
        ? githubChangeStatus.Renamed
        : githubChangeStatus.Modified;

      let sha: string;
      if (includeShas) {
        sha = await this.getFileBlobSha({ repoId, filePath: diff.new_path, revision: compareTo });
      }

      diffFilesMetadata.push({
        newFilePath: diff.new_path,
        oldFilePath: diff.old_path,
        status: diffStatus,
        sha: sha,
      });
    }
    return diffFilesMetadata;
  }

  async getRepositoryLanguages(_repoId: string) {
    /**
     * NOTE: We removed the code here since we're unsure how the language-name inconsistency will affect CTags.
     * If you want the code that used to be here, check for a commit before 2023-08-07.
     */
    return undefined;
  }

  // NOTE: this function shouldn't be called directly! It is implemented in the main "gitwrapper.ts"!
  async getSwimmJsonContentsWrapper() {
    throw new Error(`getSwimmJsonContentsWrapper called not from the main wrapper!`);
  }

  // NOTE: this function shouldn't be called directly! It is implemented in the main "gitwrapper.ts"!
  async getFullFileContentFromFSWrapper({ repoId, revision }: { repoId: string; revision: string }): Promise<string> {
    throw new Error(
      `getFullFileContentFromFSWrapper called not from the main wrapper! with args: ${{
        filePath: 'hidden',
        repoId,
        revision,
      }}`
    );
  }

  async getUserActiveBranches(repoId: string, maxActiveBranchesToFetch: number): Promise<string[]> {
    const api = await this.getGitBeakerInstanceWithAuth();
    const projectId = await this.getGitLabProjectId(repoId);
    const { email } = await api.Users.showCurrentUser();
    let page = 1;
    const result: string[] = [];
    do {
      const {
        data,
        paginationInfo: { next },
      } = await api.Branches.all(projectId, {
        page: page++,
        showExpanded: true,
      });
      for (const branch of data ?? []) {
        if (result.length >= maxActiveBranchesToFetch) {
          break;
        } else if (branch.commit.committer_email === email && result.length < maxActiveBranchesToFetch) {
          result.push(branch.name as string);
        }
      }
      (!next || result.length >= maxActiveBranchesToFetch) && (page = 0);
    } while (page !== 0);
    return result;
  }

  async getUserOrganizations(_provider: GitProviderName, _driverOptions?: DriverOptions): Promise<string[]> {
    try {
      const namespaces = await this.getOrganizations();
      return namespaces.map((namespace) => namespace.name);
    } catch (err) {
      logger.error(`Failed to get user organizations from GitLab`);
      throw err;
    }
  }

  override async deleteBranch(repoId: string, branchName: string): Promise<void> {
    const projectId = await this.getGitLabProjectId(repoId);
    // The same problem like in issue: https://github.com/jdalrymple/gitbeaker/issues/2319
    // The API from library trying to get the body of response, but the code of response 204 doesn't have body
    // https://github.com/jdalrymple/gitbeaker/blob/main/packages/core/src/infrastructure/RequestHelper.ts#L403
    const uri = `projects/${encodeURIComponent(projectId)}/repository/branches/${encodeURIComponent(branchName)}`;
    await this.sendApiRequest(APIRequestType.REST, 'delete', uri);
  }

  override async closePullRequest(repoId: string, prId: number): Promise<void> {
    const projectId = await this.getGitLabProjectId(repoId);
    const api = await this.getGitBeakerInstanceWithAuth();
    await api.MergeRequests.edit(projectId, prId, { stateEvent: 'close' });
  }
}
