import { type Ref, ref } from 'vue';

import {
  type StaticAnalysisIndex,
  type TokenSuggestionsMap,
  getValidTokenSuggestion,
  toTokenSuggestionsMap,
} from '@swimm/editor';
import { type TokenSuggestion, getLoggerNew, gitwrapper } from '@swimm/shared';

import { getRepoDefaultBranch } from '@/remote-adapters/local_repo';
import { useCodeAnalysis } from './codeAnalysis';
import { staticAnalysisDatabase } from './staticAnalysisDatabase';

const logger = getLoggerNew(__modulename);

export function useWebAppStaticAnalysisIndex(docRepoId: string, docBranch: Ref<string>): StaticAnalysisIndex {
  const codeAnalysis = useCodeAnalysis();

  const suggestionsBuildTimestamp = ref(0);

  const index = ref(new Map<string, Promise<TokenSuggestion[]> | TokenSuggestion[]>());
  const indexMap = ref(new Map<string, TokenSuggestionsMap>());

  const load = async (repoId: string, options?: { force?: boolean }): Promise<TokenSuggestion[]> => {
    const { force } = Object.assign({}, { force: false }, options);
    const indexed = index.value.get(repoId);
    if (!force && indexed) {
      return indexed;
    }
    const promise = innerLoad(repoId);
    index.value.set(repoId, promise);
    indexMap.value.delete(repoId);
    promise.then((result) => {
      index.value.set(repoId, result);
      indexMap.value.set(repoId, toTokenSuggestionsMap(result));
    });
    return promise;
  };

  const get = (repoId: string): { loading: boolean; suggestions: TokenSuggestion[] } => {
    const indexed = index.value.get(repoId);
    if (indexed == null) {
      load(repoId);
      return { loading: true, suggestions: [] };
    }
    if (indexed instanceof Promise) {
      return { loading: true, suggestions: [] };
    }
    return { loading: false, suggestions: indexed as TokenSuggestion[] };
  };

  const getAsyncMap = async (repoId: string): Promise<TokenSuggestionsMap> => {
    const map = indexMap.value.get(repoId);
    if (map != null) {
      return map; // this will not be promise
    }
    await load(repoId);
    return indexMap.value.get(repoId);
  };

  const clear = (): void => {
    index.value.clear();
    indexMap.value.clear();
  };

  const innerLoad = async (repoId: string): Promise<TokenSuggestion[]> => {
    try {
      const branch = await getBranch(repoId);
      if (branch == null) {
        logger.error({ repoId }, 'Failed to get branch for repo');
        return [];
      }
      const commitSha = (await gitwrapper.getBranch({ repoId, branchName: branch })).sha;
      if (commitSha == null) {
        logger.error({ repoId, branch }, 'Failed to get commit sha for branch');
        return [];
      }
      const tokensInDatabase = await staticAnalysisDatabase.get(repoId, commitSha);
      if (tokensInDatabase != null) {
        return tokensInDatabase;
      }
      const tokens = (await codeAnalysis.call(repoId, branch, commitSha))
        .map((suggestion) => getValidTokenSuggestion(suggestion))
        .filter((suggestion): suggestion is TokenSuggestion => suggestion != null);
      staticAnalysisDatabase.set(repoId, commitSha, tokens);
      return tokens;
    } catch (err) {
      logger.error({ err, repoId }, 'Failed to run static analysis on repo');
      return [];
    }
  };

  const getBranch = async (repoId: string): Promise<string | null> => {
    if (repoId === docRepoId) {
      return docBranch.value;
    }
    const result = await getRepoDefaultBranch({ repoId });
    if (result.code === 0) {
      return result.branch;
    }
    return null;
  };

  return { load, get, getAsyncMap, clear, suggestionsBuildTimestamp };
}
