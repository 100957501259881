<script setup lang="ts">
import { BaseButton, BaseHeading, BaseIcon, BaseLayoutGap, BaseProse } from '@swimm/reefui';

defineProps<{
  changesButtonText: string;
}>();

const emit = defineEmits<{
  (e: 'select-snippet'): void;
  (e: 'add-whole-file'): void;
  (e: 'start-from-branch-changes'): void;
  (e: 'collapse'): void;
}>();
</script>

<template>
  <div class="empty-state">
    <BaseLayoutGap direction="column" alignment="stretch" size="medium">
      <BaseLayoutGap direction="row" class="clickable" alignment="stretch" @click="emit('collapse')">
        <BaseLayoutGap direction="row" size="small" class="title-row">
          <BaseLayoutGap direction="row" alignment="center">
            <BaseIcon name="magic" />
            <BaseHeading :level="5">Generate draft with AI</BaseHeading>
          </BaseLayoutGap>
          <BaseProse variant="secondary" size="small">0 snippets selected</BaseProse>
        </BaseLayoutGap>
        <BaseIcon name="arrow-down" @click="emit('collapse')" />
      </BaseLayoutGap>
      <BaseLayoutGap direction="column" alignment="center" size="small">
        <BaseProse variant="secondary">
          Add some code files or snippets to the doc and we’ll generate a draft from them
        </BaseProse>
        <BaseLayoutGap direction="row" alignment="center">
          <BaseButton variant="secondary" @click="emit('add-whole-file')"
            ><template #leftIcon><BaseIcon name="file-created" /></template>Code file</BaseButton
          >
          <BaseButton variant="secondary" @click="emit('select-snippet')"
            ><template #leftIcon><BaseIcon name="add" /></template>Snippet</BaseButton
          >
          <BaseButton variant="secondary" @click="emit('start-from-branch-changes')"
            ><template #leftIcon><BaseIcon name="branch" /></template>{{ changesButtonText }}</BaseButton
          >
        </BaseLayoutGap>
      </BaseLayoutGap>
    </BaseLayoutGap>
  </div>
</template>

<style scoped>
.empty-state {
  padding: var(--space-base);
  overflow: auto;
}

/* eslint-disable vue-scoped-css/no-unused-selector */
.icon.icon-magic {
  color: var(--text-color-magic-strong);
}

.title-row {
  flex-wrap: wrap !important;
  row-gap: var(--space-base) !important;
}
</style>
