<script setup lang="ts">
import { BaseIcon, BaseLayoutIcons, BaseProse, IconsType } from '@swimm/reefui';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { computed } from 'vue';

const props = defineProps<{
  folderId: string;
  repoId: string;
  showFolderIcon?: boolean;
  repoIcon?: IconsType;
  repoName?: string;
}>();

const { getFolderBreadCrumbs } = useFoldersStore();

const folderCrumbs = computed(() => getFolderBreadCrumbs(props.folderId, props.repoId));

const folderShortPath = computed(() => {
  let path = props.repoName ?? '';
  if (folderCrumbs.value.length <= 1) {
    // Root folder
    return path;
  }
  const folder = folderCrumbs.value[folderCrumbs.value.length - 1];
  if (folderCrumbs.value.length === 2) {
    // Under root folder
    path = path ? `${path} / ` : '';
    return path + folder.name;
  }
  if (props.repoName) {
    return folderCrumbs.value.length === 3
      ? `${props.repoName} / ${folderCrumbs.value
          .slice(-2)
          .map((folder) => folder.name)
          .join(' / ')}`
      : `${props.repoName} / ... / ${folder.name}`;
  }
  return ` ... / ${folder.name}`;
});

const folderPath = computed(() => {
  if (props.repoName) {
    return folderShortPath.value;
  } else {
    const crumbsNames = folderCrumbs.value.map((folder) => folder.name);
    return crumbsNames.join(' / ');
  }
});
</script>

<template>
  <div v-if="folderPath" class="folder-path-container" data-testid="folder-path" v-tooltip="folderPath">
    <BaseProse size="small" class="folder-path">
      <BaseLayoutIcons>
        <template #leftIcon>
          <BaseIcon v-if="repoIcon" :name="repoIcon" />
          <BaseIcon v-if="showFolderIcon" name="folder" />
        </template>
        {{ folderShortPath }}
      </BaseLayoutIcons>
    </BaseProse>
  </div>
</template>

<style scoped lang="postcss">
.folder-path-container {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;

  .folder-path {
    color: var(--text-color-secondary);
  }

  .repo-icon {
    color: var(--text-color-primary);
    font-size: var(--fontsize-s);
  }
}
</style>
