<script setup lang="ts">
import { BaseButton, BaseLayoutGap, BaseProse } from '@swimm/reefui';

const emit = defineEmits<{
  (e: 'start-with-branch-changes'): void;
  (e: 'start-with-code'): void;
}>();

defineProps<{
  branchChangesOptionName: string;
  codeStartOptionName: string;
}>();
</script>

<template>
  <BaseLayoutGap direction="column">
    <BaseProse variant="placeholder"> Or start with... </BaseProse>
    <BaseLayoutGap direction="row">
      <BaseButton variant="secondary" class="quick-start-button" @click="emit('start-with-branch-changes')">
        <BaseLayoutGap>
          <img class="logo" src="./assets/branch-changes-icon.svg" />
          {{ branchChangesOptionName }}</BaseLayoutGap
        ></BaseButton
      >
      <BaseButton variant="secondary" class="quick-start-button" @click="emit('start-with-code')">
        <BaseLayoutGap>
          <img class="logo" src="./assets/terminal-icon.svg" />{{ codeStartOptionName }}</BaseLayoutGap
        ></BaseButton
      >
    </BaseLayoutGap>
  </BaseLayoutGap>
</template>
<style scoped lang="postcss">
.quick-start-button {
  border: 1px solid var(--color-border-default-subtle) !important;
  box-shadow: none !important;

  &:hover {
    background-color: var(--color-bg-surface) !important;
  }
}
</style>
