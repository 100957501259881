<script setup lang="ts">
import SwText from './SwText.vue';
import Icon from './Icon.vue';
import { computed } from 'vue';

const props = defineProps({
  disabled: { type: Boolean, default: false },
  optionsDisabled: { type: Boolean, default: false },
  moreIcon: { type: String, default: 'more' },
  variant: { type: String, default: 'primary' },
});

const emit = defineEmits(['click', 'opened']);

function onClick() {
  if (props.disabled) {
    return;
  }

  emit('click');
}

const computedClasses = computed(() => ({
  [`${props.variant}`]: true,
  disabled: props.disabled,
}));

const moreButtonClasses = computed(() => ({
  ...computedClasses.value,
  disabled: props.disabled || props.optionsDisabled,
}));
</script>

<template>
  <div class="split-button" role="button" :aria-disabled="disabled">
    <SwText variant="body-S" role="button" :class="['main-button', computedClasses]" weight="bolder" @click="onClick">
      <slot />
    </SwText>
    <VDropdown
      theme="dropdown-no-arrow"
      placement="bottom-end"
      class="dropdown"
      :distance="0"
      :disabled="disabled || optionsDisabled"
      :popper-hide-triggers="(triggers: string[]) => [...triggers, 'focus']"
      @show="$emit('opened')"
    >
      <div :class="['more-button', moreButtonClasses]">
        <Icon no-padding :name="props.moreIcon" />
      </div>
      <template #popper>
        <slot name="options" />
      </template>
    </VDropdown>
  </div>
</template>

<style scoped lang="postcss">
.split-button {
  height: 32px;
  display: flex;
  gap: 1px;
  align-items: center;
  justify-content: center;
  color: var(--text-color-on-dark);
  font-weight: 800;

  .dropdown {
    display: flex;
    align-self: stretch;
  }

  .main-button {
    cursor: pointer;
    background-color: var(--color-brand);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid transparent;
    padding: var(--space-base) var(--space-sm);
    align-self: stretch;

    &:hover {
      background-color: var(--color-brand-hover);
    }

    &.disabled {
      cursor: no-drop;
      color: var(--text-color-on-dark);
      opacity: 0.7;
    }

    &.secondary {
      color: var(--text-color-primary);
      background: transparent;
      border-color: var(--color-border-default-strong);

      &:hover {
        background: var(--color-hover);
      }
    }

    &.magic {
      background: var(--color-bg-magic);

      &:hover {
        background-color: var(--color-bg-magic-hover);
      }
    }
  }

  .more-button {
    cursor: pointer;
    background-color: var(--color-brand);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: var(--space-base);
    border: 1px solid transparent;
    border-left: none;

    &:hover {
      background-color: var(--color-brand-hover);
    }

    &.disabled {
      cursor: no-drop;
      opacity: 0.7;
      color: var(--text-color-on-dark);
    }

    &.secondary {
      color: var(--text-color-primary);
      background: transparent;
      border-color: var(--color-border-default-strong);

      &:hover {
        background: var(--color-hover);
      }
    }

    &.magic {
      background: var(--color-bg-magic);

      &:hover {
        background-color: var(--color-bg-magic-hover);
      }
    }
  }
}
</style>
