import { APIClient, Bitbucket, Schema } from 'bitbucket';
import Hex from 'crypto-js/enc-hex.js';
import sha1 from 'crypto-js/sha1.js';
import { StatusCodes } from 'http-status-codes';
import Cache from 'lru-cache';
import { ERROR_RETURN_CODE, SUCCESS_RETURN_CODE, SWM_FOLDER_IN_REPO } from '../../config';
import { getLoggerNew } from '#logger';
import type { ResultWithReturnCode, SwmResourceFile } from '../../types';
import { GitProviderName, PathType, SwmResourceState } from '../../types';
import { promiseAllLimit } from '../../utils/promise-utils';
import gitProviderUtils from '../git-provider-utils';
import { ChangeRequestData, changeRequestsPendingDocsReducer } from '../git-utils-common';
import {
  Branch,
  type Commit,
  CreatedPR,
  DiffFileMetadata,
  DriverOptions,
  GitDriverBase,
  PartialRemoteRepository,
  PendingPR,
  PrData,
  PrDataWithTargetBranch,
  ProviderSpecificTerms,
  PullRequestState,
  RemoteRepository,
  RepoIdOrRepoData,
  bitBucketStatus2GitHubStatus,
} from './git-provider-base';
import { pathBasename } from '../../utils/file-utils';

const logger = getLoggerNew("packages/shared/src/git-utils/gitdrivers/bitbucket-driver.ts");

interface BitbucketCloudRepoInformation {
  project: string;
  workspace: string;
  repo_slug: string;
}

interface BitbucketDiffInformation {
  type: string;
  path: string;
  escaped_path: string;
  links: {
    self: {
      href: string;
    };
  };
}

interface BitbucketCloudDiffState {
  type: string;
  lines_added: number;
  lines_removed: number;
  status: string;
  old: BitbucketDiffInformation | null;
  new: BitbucketDiffInformation | null;
}

interface BitbucketDiffInformationPaginated<T> {
  next?: string;
  page?: number;
  pagelen?: number;
  previous?: string;
  size?: number;
  values?: T[];
}

interface FetchFileContentResponse {
  sha: string;
  content: string | null;
}

type BitbucketDiffInformationDiffStateResponse = BitbucketDiffInformationPaginated<BitbucketCloudDiffState>;

type GetBranchCacheKey = `RID:${string}-BNAME${string}`;
type GetRepoTreeCacheKey = `RID:${string}-BSHA:${string}-PATH:${string}-RECURSIVE:${string}`;

const FETCH_FILE_CACHE_TTL_MS = 20000;
const BRANCH_CACHE_TTL_MS = 5_000;

const DEFAULT_BITBUCKET_API = 'https://api.bitbucket.org/2.0';
const BRANCHES_FIELDS = 'values.name,next,values.target.hash,values.target.date';
const MAX_ITEMS_TO_FETCH = 100;
const MAX_LIST_PRS_PAGELEN = 50; // Bitbucket's limitation so we can't use MAX_ITEMS_TO_FETCH

// To avoid catching the error code 406 from the bitbucket API, we must limit the number of promises we run in parallel.
// 5, it's half of the usual count of previous responses. And it works well
const PROMISE_ALL_LIMIT = 5;
const TOKEN_EXPIRY_TIME_IN_SECONDS = 115 * 60; // The token expires after 2 hours, we will refresh it after 1:55 hours

const BitbucketType2GitHubType = {
  commit_directory: 'tree',
  commit_file: 'blob',
} as const;

export class BitbucketDriver extends ProviderSpecificTerms implements GitDriverBase {
  private bitbucketClient: APIClient;
  // Using the "notice" parameter to disable a [message](https://github.com/MunifTanjim/node-bitbucket/blob/master/src/plugins/notice/index.ts#L7) from the library.
  private clientConfig = { baseUrl: DEFAULT_BITBUCKET_API, auth: { token: '' }, notice: false };
  private repoInformationMap: Map<string, BitbucketCloudRepoInformation> = new Map();
  private fetchFileContentCache = new Cache<string, string>({
    ttl: FETCH_FILE_CACHE_TTL_MS,
    ttlResolution: FETCH_FILE_CACHE_TTL_MS / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });
  private _refreshTokenRequestCache: Promise<void>;
  private hostname = gitProviderUtils.DEFAULT_BITBUCKET_HOSTNAME;

  public constructor({ authToken = null }: DriverOptions = {}) {
    super();
    if (authToken) {
      this.clientConfig.auth.token = authToken;
    }
  }

  private async refreshAuthToken() {
    const newToken = await gitProviderUtils.refreshAuthToken({
      provider: GitProviderName.Bitbucket,
      hostname: this.hostname,
      expiryTimeInSeconds: TOKEN_EXPIRY_TIME_IN_SECONDS,
    });

    if (newToken) {
      this.clientConfig.auth.token = newToken;
      this.bitbucketClient = new Bitbucket(this.clientConfig);
    }

    this._refreshTokenRequestCache = null;
  }

  private async getAuthAPI() {
    if (!this.clientConfig.auth.token) {
      const token = await gitProviderUtils.getGitHostingToken(this.hostname);
      this.clientConfig.auth.token = token;
      this.bitbucketClient = new Bitbucket(this.clientConfig);
    }

    if (!this._refreshTokenRequestCache) {
      this._refreshTokenRequestCache = this.refreshAuthToken();
    }

    await this._refreshTokenRequestCache;

    return this.bitbucketClient;
  }

  private async getRepoInformation(
    repoId: string
  ): Promise<BitbucketCloudRepoInformation & { branch: string; owner: string; repoName: string }> {
    const { branch, owner, repoName } = await gitProviderUtils.getRepoStateData(repoId);
    const api = await this.getAuthAPI();
    if (!this.repoInformationMap.has(repoId)) {
      const { data } = await api.request('GET /repositories/{owner}/{repo_slug}', {
        owner,
        repo_slug: repoName,
        fields: 'project.uuid,workspace.uuid,uuid,owner.uuid,owner.username',
      });
      this.repoInformationMap.set(repoId, {
        project: data.project.uuid,
        workspace: data.workspace.uuid,
        repo_slug: data.uuid,
      });
    }
    const repoInfo = this.repoInformationMap.get(repoId);
    return { ...repoInfo, branch, owner, repoName };
  }

  private getBranchCache = new Cache<GetBranchCacheKey, ReturnType<typeof this.getBranch>>({
    ttl: BRANCH_CACHE_TTL_MS,
    ttlResolution: BRANCH_CACHE_TTL_MS / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });

  async getBranch({ repoId, branchName }: { repoId: string; branchName: string }): Promise<Branch | null> {
    const cacheKey: GetBranchCacheKey = `RID:${repoId}-BNAME:${branchName}`;
    const cacheHit = this.getBranchCache.get(cacheKey);
    if (cacheHit) {
      return cacheHit;
    }
    const getBranchLogic = async () => {
      const { owner: workspace, repoName: repo_slug } = await this.getRepoInformation(repoId);
      const api = await this.getAuthAPI();
      try {
        const { data } = await api.refs.getBranch({
          workspace,
          repo_slug,
          name: branchName,
          fields: 'name,target.hash,target.date',
        });
        const branch = {
          id: data.name,
          name: data.name,
          sha: data.target.hash,
          protected: false,
          lastUpdated: data.target.date,
        };
        return branch;
      } catch (err) {
        logger.error({ err }, `getting branch information failed for ${repoId}`);
        throw err;
      }
    };
    const operation = getBranchLogic();
    this.getBranchCache.set(cacheKey, operation);
    return operation;
  }

  deleteTokenFromMemory(): void {
    this.clientConfig.auth.token = null;
  }

  // TODO: Test with Access Token with Org Permissions
  async getUserData(_provider: GitProviderName): Promise<{ login: string; id: string }> {
    // This API can work only with the user token, or OAuth token.
    const api = await this.getAuthAPI();
    let data: Schema.Account = { username: '', uuid: '' } as Schema.Account;
    try {
      ({ data } = await api.user.get({ fields: 'username,uuid' }));
    } catch (e) {
      this.processError(e);
    }
    return { login: data.username, id: data.uuid };
  }

  // TODO: Test with Access Token with Org Permissions
  async getOrganizationData(_provider: GitProviderName): Promise<{ name: string; numOfDevelopers: number }> {
    const api = await this.getAuthAPI();
    let name = '';
    let numOfDevelopers = 0;
    try {
      const { data } = await api.workspaces.getWorkspaces({ fields: 'values.slug,values.name' });
      const slug = data.values.length === 1 ? data.values[0].slug : '';
      if (slug) {
        const { data: members } = await api.workspaces.getMembersForWorkspace({
          workspace: slug,
          fields: 'size',
        });
        numOfDevelopers = members.size;
        name = data.values[0].name;
      }
    } catch (e) {
      this.processError(e);
    }
    return { name, numOfDevelopers };
  }

  async isBranchExists({ repoId, branchName }: { repoId: string; branchName: string }): Promise<boolean> {
    try {
      return (await this.getBranch({ repoId, branchName })) !== null;
    } catch {
      return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getChangeRequestName({ repoId }: { repoId: string }): Promise<string> {
    return this.getTerminology(GitProviderName.Bitbucket).pullRequest;
  }

  private async getBranchesByPullRequestStatuses({
    repo_slug,
    workspace,
    nextURL,
    prStates,
  }: {
    repo_slug: string;
    workspace: string;
    nextURL?: string;
    prStates: PullRequestState[];
  }) {
    const api = await this.getAuthAPI();
    const { data } = (await api.pullrequests.list({
      repo_slug,
      workspace,
      ...this.getNextPageFromURL(nextURL),
      pagelen: MAX_LIST_PRS_PAGELEN,
      q: prStates.map((prState) => `state="${prState}"`).join(' OR '),
      fields: 'values.source.branch.name,next,values.source.commit.hash,values.source.commit.date',
    })) as unknown as {
      data: {
        values: { source: { branch: { name: string }; commit: { hash: string; date: string } } }[];
        next: string;
      };
    };
    const branches = data.values.map<Branch>(({ source }) => ({
      name: source.branch.name,
      id: source.branch.name,
      sha: source.commit.hash,
      lastUpdated: source.commit.date,
      protected: false,
    }));
    return { nextURL: data.next, branches };
  }

  async *getBranches({
    repoId,
    prStates,
  }: {
    repoId: string;
    prStates?: PullRequestState[];
  }): AsyncIterable<{ hasNextPage: boolean; branches: Branch[] }> {
    const api = await this.getAuthAPI();
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);

    let nextURL = '';

    do {
      let branches: Branch[] = [];
      let hasNextPage = false;
      try {
        if (prStates?.length) {
          const { nextURL: upcomingURL, branches: prBranches } = await this.getBranchesByPullRequestStatuses({
            repo_slug,
            workspace,
            nextURL,
            prStates,
          });
          nextURL = upcomingURL;
          branches = prBranches;
        } else {
          const { data } = await api.repositories.listBranches({
            repo_slug,
            workspace,
            ...this.getNextPageFromURL(nextURL),
            pagelen: 100,
            // show only this fields to reduce the amount of data we fetch
            fields: BRANCHES_FIELDS,
          });
          nextURL = data.next;
          branches = data.values.map<Branch>(({ name, target }) => ({
            id: name,
            name: name,
            sha: target.hash,
            protected: false,
            lastUpdated: target.date,
          }));
        }
        hasNextPage = !!this.getNextPageFromURL(nextURL)?.page;
      } catch (err) {
        logger.error({ err }, `Error fetching branches from Bitbucket`);
        hasNextPage = false;
      } finally {
        yield { hasNextPage, branches };
      }
    } while (nextURL);
  }

  private async getCommitInfo({
    workspace,
    repo_slug,
    prId,
    fields,
  }: {
    workspace: string;
    repo_slug: string;
    prId: number;
    fields?: string;
  }): Promise<BitbucketDiffInformationDiffStateResponse> {
    const api = await this.getAuthAPI();
    let page = 1;
    let isNext = false;
    const result: BitbucketDiffInformationDiffStateResponse = { values: [] };
    do {
      const { data } = await api.pullrequests.getDiffStat({
        repo_slug,
        workspace,
        pull_request_id: prId,
        fields: `${fields},next`,
        // The function of the library has a description of parameters without the page parameter
        // But the original API has this parameter https://developer.atlassian.com/cloud/bitbucket/rest/api-group-commits/#api-repositories-workspace-repo-slug-diffstat-spec-get
        // @ts-ignore
        page: page.toString(),
      });
      result.values.push(...data.values);
      isNext = !!data.next;
      page++;
    } while (isNext);
    return result;
  }

  private async mapPrData(
    pr: Schema.Pullrequest & { description?: string },
    {
      workspace,
      repo_slug,
    }: {
      workspace: string;
      repo_slug: string;
    }
  ): Promise<PrData> {
    const files = await this.getCommitInfo({ workspace, repo_slug, prId: pr.id, fields: 'values.old,values.new' });
    return mapBitbucketPrData(pr, files);
  }

  async getPr({ repoId, prId }: { repoId: string; prId: string }): Promise<PrData> {
    const api = await this.getAuthAPI();
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    const { data } = await api.pullrequests.get({
      repo_slug,
      workspace,
      pull_request_id: parseInt(prId),
      fields: [
        'id',
        'title',
        'state',
        'links.html.href',
        'created_on',
        'updated_on',
        'description',
        'source.commit.hash',
        'destination.commit.hash',
        'destination.branch.name',
        'source.branch.name',
        'author.display_name',
      ].join(','),
    });

    return this.mapPrData(data, { workspace, repo_slug });
  }

  async getPrs({ repoId, prState }: { repoId: string; prState?: string }): Promise<PrData[]> {
    const api = await this.getAuthAPI();
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    const pullRequests: PrData[] = [];
    let isNext = false;
    let page = 1;
    do {
      const { data } = await api.pullrequests.list({
        repo_slug,
        workspace,
        state: prState as 'OPEN' | 'MERGED' | 'DECLINED' | 'SUPERSEDED',
        page: page.toString(),
        pagelen: MAX_LIST_PRS_PAGELEN,
        fields: [
          'values.id',
          'values.title',
          'values.state',
          'values.links.html.href',
          'values.created_on',
          'values.updated_on',
          'values.description',
          'values.source.commit.hash',
          'values.destination.commit.hash',
          'values.destination.branch.name',
          'values.source.branch.name',
          'values.author.display_name',
          'next',
        ].join(','),
      });
      pullRequests.push(
        ...(await promiseAllLimit<PrData>(
          PROMISE_ALL_LIMIT,
          data.values.map((pr) => () => this.mapPrData(pr, { workspace, repo_slug }))
        ))
      );
      isNext = !!data.next && pullRequests.length < MAX_ITEMS_TO_FETCH;
      page++;
    } while (isNext);
    return pullRequests;
  }

  async getPendingDocsForBranch({
    repoId,
    branchName,
  }: {
    repoId: string;
    branchName: string;
  }): Promise<Record<string, PendingPR[]>> {
    const api = await this.getAuthAPI();
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    let page = 1;
    const prsData: ChangeRequestData[] = [];
    // Notice: if this function will be used with a big amount of PRs, we might need to limit the amount of PRs we fetch like getPrs function
    do {
      const { data: prs } = await api.pullrequests.list({
        repo_slug,
        workspace,
        q: `destination.branch.name="${branchName}" AND state="OPEN"`,
        fields:
          'values.source.commit.hash,' +
          'values.id,' +
          'values.title,' +
          'values.created_on,' +
          'values.updated_on,' +
          'values.source.branch.name,' +
          'next',
        page: page.toString(),
        pagelen: MAX_LIST_PRS_PAGELEN,
      });
      const result = await promiseAllLimit<ChangeRequestData>(
        PROMISE_ALL_LIMIT,
        prs.values.map((pr) => async () => {
          let prPage = 1;
          const prData: ChangeRequestData = {
            sourceBranchName: pr.source.branch.name,
            prId: pr.id,
            prTitle: pr.title,
            createdAt: pr.created_on,
            updatedAt: pr.updated_on,
            files: [],
          };

          do {
            const { data }: { data: BitbucketDiffInformationDiffStateResponse } = await api.pullrequests.getDiffStat({
              repo_slug,
              workspace,
              pull_request_id: pr.id,
              // The function of the library has a description of parameters without the page parameter
              // But the original API has a Paginated response https://developer.atlassian.com/cloud/bitbucket/rest/api-group-commits/#api-repositories-workspace-repo-slug-diffstat-spec-get
              // @ts-ignore
              page: prPage.toString(),
            });
            prData.files.push(
              ...data.values.map((file) => ({
                path: file.new?.path ?? file.old?.path ?? '',
                additions: file.lines_added,
                additionsInFile: file.lines_added,
                deletions: file.lines_removed,
                changeType: bitBucketStatus2GitHubStatus[file.status],
              }))
            );
            prPage++;
            if (!data.next) {
              prPage = 0;
            }
          } while (prPage !== 0);
          return prData;
        })
      );
      prsData.push(...result);
      page++;
      if (!prs.next) {
        page = 0;
      }
    } while (page !== 0);
    return changeRequestsPendingDocsReducer(prsData);
  }

  /**
   * For Bitbucket Cloud, some API endpoints can't deal with a "/" in the branch name.
   * So we need to check if the branch name contains a "/" and if so, we need to fetch the sha of the branch to use with the API
   * Bug related to this issue: https://jira.atlassian.com/browse/BCLOUD-20223, https://jira.atlassian.com/browse/BCLOUD-9969
   * @returns the sha of the branch or the branch name if it doesn't contain a "/"
   * @param repoId - the repository Id
   * @param branchName - the branch name
   * @private
   */
  private async getBranchShaIfNeeded({ repoId, branchName }: { repoId: string; branchName: string }): Promise<string> {
    if (branchName?.includes('/')) {
      return (await this.getBranch({ repoId, branchName }))?.sha;
    } else {
      return branchName;
    }
  }

  private async fetchFileContent({
    repo_slug,
    workspace,
    filePath,
    revision,
    safe = true,
    repoId,
  }: {
    repo_slug: string;
    workspace: string;
    filePath: string;
    revision?: string;
    safe?: boolean;
    repoId: string;
  }): Promise<FetchFileContentResponse['content'] | Schema.PaginatedTreeentries> {
    const api = await this.getAuthAPI();
    try {
      // no cache busting here since it was done in buggy way see pr #21082
      const { data } = await api.repositories.readSrc({
        repo_slug,
        workspace,
        path: filePath,
        commit: await this.getBranchShaIfNeeded({ repoId, branchName: revision }),
      });
      return data;
    } catch (err) {
      if (err.status === StatusCodes.NOT_FOUND && safe) {
        return null;
      }
      logger.error({ err }, `Error fetching file content from Bitbucket`);
      throw err;
    }
  }

  private async fetchFileContentFromBitbucket({
    repoId,
    filePath,
    revision,
    safe = true,
  }: {
    repoId: string;
    filePath: string;
    revision: string;
    safe?: boolean;
  }): Promise<FetchFileContentResponse['content']> {
    const disableCache = filePath.indexOf(SWM_FOLDER_IN_REPO + '/') >= 0;
    const cacheKey = `${repoId}-${revision}-${filePath}`;
    if (!disableCache) {
      try {
        const cacheHit = this.fetchFileContentCache.get(cacheKey);
        if (cacheHit) {
          return cacheHit;
        }
      } catch (err) {
        logger.error({ err }, `FetchFileContentCache get threw an error`);
      }
    }

    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    const props = { repo_slug, workspace, filePath, revision, safe, repoId };

    const result = await this.fetchFileContent(props);

    // use the same check as done in getPathType
    if (typeof result !== 'string') {
      // This is a folder. We return null to indicate that we don't want to fetch the content of the folder
      return null;
    }

    if (!disableCache) {
      this.fetchFileContentCache.set(cacheKey, result as string);
    }

    return result as string;
  }

  async getFileBlobSha({
    repoId,
    filePath,
    revision,
  }: {
    repoId: string;
    filePath: string;
    revision: string;
  }): Promise<string> {
    const content = await this.fetchFileContentFromBitbucket({ repoId, filePath, revision });
    if (!content) {
      return '';
    }
    // The Bitbucket API returns the content as a string, so we need to count the sha1 of the string
    // This sha1 is not the same as the sha1 of the GitHub.
    // Probably, need to implement and check this way https://gist.github.com/nikolayemrikh/c8ee6b0bbeda0a2b65685844d503c6b9
    const sha = sha1(content).toString(Hex);
    return sha;
  }

  // TODO: unify this with `getFileContentAndBlobShaInRevision` since it's basically a flag
  async getFileContentFromRevision({
    filePath,
    repoId,
    revision,
    raw, // eslint-disable-line @typescript-eslint/no-unused-vars
    safe = false,
  }: {
    filePath: string;
    repoId: string;
    revision: string;
    raw?: boolean;
    safe?: boolean;
  }): Promise<string> {
    // TODO: perhaps in some cases it'll require to run removeCRLFCharacters from string-utils on the file content text
    return await this.fetchFileContentFromBitbucket({ repoId, filePath, revision, safe });
  }

  async isFileExistsOnRevision({
    repoId,
    filePath,
    revision,
  }: {
    repoId: string;
    filePath: string;
    revision?: string;
  }): Promise<boolean> {
    return !!(await this.getFileContentFromRevision({ repoId, filePath, revision, safe: true }));
  }

  async createBranch({
    repoId,
    branchName,
    sourceSha,
  }: {
    repoId: string;
    branchName: string;
    sourceSha: string;
  }): Promise<void> {
    const api = await this.getAuthAPI();
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    await api.refs.createBranch({
      repo_slug,
      workspace,
      _body: {
        name: branchName,
        target: {
          hash: sourceSha,
        },
      },
    });
  }

  async createPullRequest({
    repoId,
    fromBranch,
    toBranch,
    title,
    body,
  }: {
    repoId: string;
    fromBranch: string;
    toBranch: string;
    title?: string;
    body?: string;
  }): Promise<CreatedPR> {
    const api = await this.getAuthAPI();
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    const { data } = await api.repositories.createPullRequest({
      repo_slug,
      workspace,
      // The function of the library has a description of parameters without the fields parameter
      // But the main API has this parameter for a lot of APIs https://developer.atlassian.com/cloud/bitbucket/rest/intro/#fields-parameter-syntax
      // @ts-ignore
      fields: 'links.html',
      _body: {
        type: 'pullrequest',
        title,
        source: {
          branch: {
            name: fromBranch,
          },
        },
        destination: {
          branch: {
            name: toBranch,
          },
        },
        description: body,
      },
    });
    return { url: data.links.html.href };
  }

  async deleteFileIfExists({
    filePath,
    branch,
    commitMessage,
    repoId,
  }: {
    repoId: string;
    branch: string;
    filePath: string;
    commitMessage: string;
  }): Promise<void> {
    const api = await this.getAuthAPI();
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    const isFileExist = await this.isFileExistsOnRevision({
      repoId,
      revision: branch,
      filePath,
    });
    if (isFileExist) {
      await api.repositories.createSrcFileCommit({
        repo_slug,
        workspace,
        _body: {
          message: commitMessage,
          branch,
          files: filePath,
        },
      });
    }
    this.fetchFileContentCache.delete(`${repoId}-${branch}-${filePath}`);
  }

  async getLastCommitShaWhereFileExisted(
    relativeFilePath: string,
    repoId: string,
    destCommit: string
  ): Promise<string> {
    const api = await this.getAuthAPI();
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    const { data } = await api.repositories.listCommits({
      repo_slug,
      workspace,
      include: destCommit,
      // The function of the library has a description of parameters with the page parameter
      // But the original API has the path parameter instead iof the page https://developer.atlassian.com/cloud/bitbucket/rest/api-group-commits/#api-repositories-workspace-repo-slug-commits-get
      // @ts-ignore
      path: relativeFilePath,
      fields: 'values.hash',
    });
    return data.values?.[0]?.hash ?? '';
  }

  async fetchFileCommitHistory(_params: {
    repoId: string;
    branch: string;
    relativeFilePath: string;
    maxCommitsNumber?: number;
    maxPagesNumber?: number;
  }): Promise<Commit[]> {
    throw new Error('Method fetchFileCommitHistory not implemented.');
  }

  async getPathType({
    path,
    repoId,
    revision,
  }: {
    path: string;
    repoId: string;
    revision: string;
  }): Promise<ResultWithReturnCode<{ pathType: PathType }, { errorMessage: string }>> {
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    try {
      const result = await this.fetchFileContent({
        repo_slug,
        workspace,
        revision,
        filePath: path,
        repoId,
      });
      if (result === null) {
        return { code: ERROR_RETURN_CODE, errorMessage: 'Not found' };
      } else if (typeof result === 'string') {
        return { code: SUCCESS_RETURN_CODE, pathType: PathType.File };
      }
      return { code: SUCCESS_RETURN_CODE, pathType: PathType.Folder };
    } catch (err) {
      return { code: ERROR_RETURN_CODE, errorMessage: err.toString() };
    }
  }

  async getRepoRemoteData({ repoId, repoName, owner }: RepoIdOrRepoData): Promise<RemoteRepository> {
    const api = await this.getAuthAPI();
    const repoData =
      owner && repoName ? { workspace: owner, repo_slug: repoName } : await this.getRepoInformation(repoId);

    const { data } = await api.repositories.get({ repo_slug: repoData.repo_slug, workspace: repoData.workspace });
    return {
      cloneUrl: this.getFormattedHttpCloneUrl(data),
      defaultBranch: data.mainbranch.name,
      htmlUrl: data.links.html.href,
      isPrivate: data.is_private,
      name: data.slug as string,
      fork: data.fork_policy === 'allow_forks',
      writeAccess: true,
      // @ts-ignore - "workspace" is not typed well in the library
      owner: data.owner.username || data?.workspace?.slug,
    };
  }

  async getRepoRemoteDataBatch(): Promise<Record<string, PartialRemoteRepository>> {
    return {};
  }

  swmResourceFiletoFile(file: SwmResourceFile): File {
    if (file.state === SwmResourceState.Deleted) {
      throw new Error('should not be called');
    }
    const content = file.content;
    const buffer = Buffer.from(content, file.isBase64Encoded ? 'base64' : 'utf8');
    return new File([buffer], pathBasename(file.path));
  }

  async pushMultipleFilesToBranch({
    files,
    repoId,
    branch,
    commitMessage,
  }: {
    files: SwmResourceFile[];
    repoId: string;
    branch: string;
    commitMessage: string;
  }) {
    const api = await this.getAuthAPI();
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    // we need to use formData since we want to upload images
    // see here: https://community.atlassian.com/t5/Bitbucket-questions/commit-and-get-image-via-Bitbucket-API-using-javascript/qaq-p/1827549
    const formData = new FormData();
    formData.append('message', commitMessage);
    formData.append('branch', branch);
    for (const file of files) {
      this.fetchFileContentCache.delete(`${repoId}-${branch}-${file.path}`);

      switch (file.state) {
        case SwmResourceState.Renamed: {
          formData.append(file.path, this.swmResourceFiletoFile(file));
          formData.append('files', file.oldPath);
          break;
        }
        case SwmResourceState.Updated:
        case SwmResourceState.Created: {
          formData.append(file.path, this.swmResourceFiletoFile(file));
          break;
        }
        case SwmResourceState.Deleted: {
          formData.append('files', file.path);
          break;
        }
        default: {
          throw new Error(`Unsupported file state: ${file['state']}`);
        }
      }
    }

    await api.repositories.createSrcFileCommit({
      repo_slug,
      workspace,
      _body: formData,
    });
  }

  // TODO: should be changed for a general `gitDiff` function
  async getGitDiffRemote({ repoId, base, head }: { repoId: string; base: string; head: string }): Promise<string> {
    const api = await this.getAuthAPI();
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    const { data } = await api.repositories.getDiff({
      repo_slug,
      workspace,
      spec: `${head}..${base}`,
    });
    return data;
  }

  private getRepoTreeCache = new Cache<GetRepoTreeCacheKey, ReturnType<typeof this.getRepoTree>>({
    ttl: 300_000,
    ttlResolution: 300_000 / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });

  async getRepoTree({
    repoId,
    recursive = false,
    treeSha = 'HEAD',
    path,
  }: {
    repoId: string;
    recursive?: boolean;
    treeSha?: string;
    path?: string;
  }): Promise<
    {
      path?: string;
      mode?: string;
      type?: string;
      sha?: string;
      size?: number;
      url?: string;
    }[]
  > {
    type getRepoTreeReturn = Awaited<ReturnType<typeof this.getRepoTree>>;
    const branch = await this.getBranch({ repoId, branchName: treeSha });
    const cacheKey: GetRepoTreeCacheKey = `RID:${repoId}-BSHA:${branch.sha}-PATH:${path}-RECURSIVE:${recursive}`;
    const cacheHit = this.getRepoTreeCache.get(cacheKey);
    if (cacheHit) {
      return cacheHit;
    }
    const getRepoTreeLogic = async () => {
      const api = await this.getAuthAPI();
      const { repo_slug, workspace } = await this.getRepoInformation(repoId);
      const result: getRepoTreeReturn = [];
      const q = new URLSearchParams({
        fields: ['values.path', 'values.type', 'values.commit.hash', 'values.links.self.href', 'next'].join(','),
        pagelen: `${MAX_ITEMS_TO_FETCH}`,
      });
      let url = `/repositories/${encodeURIComponent(workspace)}/${encodeURIComponent(repo_slug)}/src/${branch.sha}/${
        path ? path + '/' : ''
      }?${q.toString()}`;
      const directoriesFound: string[] = [];
      do {
        try {
          const { data } = (await api.request(`GET ${url}`)) as { data: Schema.PaginatedFiles };
          result.push(
            ...data.values.map((file) => {
              const fileType = BitbucketType2GitHubType[file.type];
              if (fileType === 'tree') {
                directoriesFound.push(file.path);
              }
              return {
                path: file.path,
                type: fileType,
                //  NOTE: the hash is the same for all responses due to Bitbucket bug.
                sha: file.commit.hash,
                // @ts-ignore // Either the Schema is typed incorrectly, or we used the wrong type. Anyway, there's a `links.self.href` field.
                url: file.links.self.href,
              };
            })
          );
          url = data.next;
        } catch (err) {
          logger.error({ err }, `Error fetching repo tree from Bitbucket`);
          url = null;
        }
      } while (url);
      if (recursive) {
        const nestedGetRepoTreePromises = directoriesFound.map(async (dir) =>
          this.getRepoTree({ repoId, recursive, treeSha, path: dir })
        );
        const directoryResults = await Promise.all(nestedGetRepoTreePromises);
        result.push(...directoryResults.flat());
      }
      return result;
    };
    const operation = getRepoTreeLogic();
    this.getRepoTreeCache.set(cacheKey, operation);
    return operation;
  }

  async getDiffFiles({
    repoId,
    compareFrom,
    compareTo,
    includeShas,
  }: {
    repoId: string;
    compareFrom: string;
    compareTo: string;
    includeShas?: boolean;
  }): Promise<DiffFileMetadata[]> {
    let spec = `${compareTo}..${compareFrom}`;
    /** *
     * Unusual situation.
     * If we ask for changes between branches, for example, `mode_changes` as `from` to the `master` as `to`, we'll see the same result as in the UI.
     * But we don't have the result if we ask for changes between commits, the `somecommit~1` as `from` to the `somecommit` as `to`.
     * The correct result will be only if we are changing the commits between themselves.
     */
    if (`${compareTo}~1` === compareFrom) {
      /**
       * For the API enough send only the current commit hash for getting the changes in this commit.
       */
      spec = compareTo;
    }
    const api = await this.getAuthAPI();
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    let page = 1;
    let hasNextPage = false;
    const result = [] as DiffFileMetadata[];
    do {
      const { data } = await api.repositories.listDiffStats({
        ignore_whitespace: true,
        repo_slug,
        workspace,
        spec,
        page: page.toString(),
        fields: 'values.status,values.old.path,values.new.path,next,values.old.commit.hash,values.new.commit.hash',
      });
      data.values &&
        result.push(
          ...data.values.map((file) => {
            const commitSHA = file.old?.commit?.hash || file.new?.commit?.hash;
            return {
              newFilePath: (file.new?.path || file.old?.path) ?? '',
              oldFilePath: (file.old?.path || file.new?.path) ?? '',
              status: file.status,
              sha: includeShas ? commitSHA : undefined,
            };
          })
        );
      page++;
      hasNextPage = !!data.next;
    } while (hasNextPage);
    return result;
  }

  private getFormattedHttpCloneUrl(repo: Schema.Repository): string {
    const httpsLink = repo.links.clone.find((link) => link.name === 'https')?.href;
    const usernameInUrlRegex = new RegExp(`https://.*@${gitProviderUtils.DEFAULT_BITBUCKET_HOSTNAME}`, 'g');
    const formattedHttpsLink = httpsLink.replace(
      usernameInUrlRegex,
      `https://${gitProviderUtils.DEFAULT_BITBUCKET_HOSTNAME}`
    );
    return formattedHttpsLink;
  }

  // TODO: Test with Access Token with Org Permissions
  async *getUserRemoteRepositories(
    _provider: GitProviderName,
    driverOptions?: DriverOptions
  ): AsyncIterable<RemoteRepository> {
    const api = await this.getAuthAPI();
    // This API can work only with the user token, or OAuth token.
    try {
      let page = 1;
      do {
        const { data } = (await api.request('GET /user/permissions/repositories', {
          page: page.toString(),
          pagelen: MAX_ITEMS_TO_FETCH.toString(),
          sort: 'repository.full_name',
          fields: [
            'values.repository.full_name',
            'values.repository.owner.username',
            'values.repository.workspace.slug',
            'values.repository.is_private',
            'values.repository.fork_policy',
            'values.repository.links.html',
            'values.repository.links.clone.*',
            'values.repository.mainbranch.name',
            'values.permission',
            'next',
          ].join(','),
          baseUrl: driverOptions?.baseUrl ?? DEFAULT_BITBUCKET_API,
        })) as { data: Schema.PaginatedRepositoryPermissions };
        for (const { repository, permission } of data.values) {
          // Get and use the repository slugged name as it might contain spaces
          const splittedRepoFullPath = repository.full_name?.split('/');
          const name = splittedRepoFullPath.pop();

          // @ts-ignore - "workspace" is not typed well in the library
          const owner = repository.owner.username || repository.workspace.slug;
          yield {
            owner,
            name,
            isPrivate: repository.is_private,
            fork: repository.fork_policy === 'allow_forks',
            htmlUrl: repository.links.html.href,
            cloneUrl: this.getFormattedHttpCloneUrl(repository),
            defaultBranch: repository.mainbranch.name,
            writeAccess: ['admin', 'write'].includes(permission as string),
          };
        }
        if (data.next) {
          page++;
        } else {
          page = 0;
        }
      } while (page !== 0);
    } catch (e) {
      this.processError(e);
    }
  }

  async getRepositoryLanguages(repoId: string): Promise<{ [language: string]: number } | undefined> {
    const api = await this.getAuthAPI();
    try {
      const { repo_slug, workspace } = await this.getRepoInformation(repoId);
      const { data } = await api.repositories.get({ repo_slug, workspace, fields: 'language' });
      return data.language ? { [data.language]: 100 } : undefined;
    } catch (e) {
      return undefined;
    }
  }

  async getOrNullIf404(api: APIClient, url: string) {
    try {
      return await api.request(`GET ${url}`);
    } catch (err) {
      if (err.status === StatusCodes.NOT_FOUND) {
        return null;
      }
      throw err;
    }
  }

  async getAllSwmFilesContent({
    repoId,
    revision,
    path = SWM_FOLDER_IN_REPO,
  }: {
    repoId: string;
    revision: string;
    path?: string;
  }): Promise<{ path: string; content: string }[]> {
    const api = await this.getAuthAPI();
    const { repo_slug, workspace, repoName } = await this.getRepoInformation(repoId);
    const files: { path: string; content: string }[] = [];

    const hash = await this.getBranchShaIfNeeded({ repoId, branchName: revision });
    const q = new URLSearchParams({ fields: 'next,values.path,values.type,values.commit.hash' });
    let url = `/repositories/${encodeURIComponent(workspace)}/${encodeURIComponent(
      repo_slug
    )}/src/${hash}/${encodeURIComponent(path)}?${q.toString()}`;
    do {
      const resp = await this.getOrNullIf404(api, url);
      // 404 in this case, means there is no .swm
      if (resp == null) {
        logger.warn(`Could not find ${path} folder in ${repoName} (repoId=${repoId}) - return empty array`);
        return [];
      }
      const { data } = resp;
      for (const file of data.values) {
        // skip subfolders like .swm/images
        const fileType = BitbucketType2GitHubType[file.type];
        if (fileType !== 'blob') {
          continue;
        }
        const content = await this.fetchFileContentFromBitbucket({
          repoId,
          filePath: file.path,
          revision: file.commit.hash,
        });
        files.push({
          path: file.path,
          content: content ?? '',
        });
      }
      url = data.next;
    } while (url);

    return files;
  }

  private getNextPageFromURL(urlString: string): { page?: string } {
    try {
      if (!urlString) {
        return {};
      }

      const url = new URL(urlString);
      const searchParams = new URLSearchParams(url.search);

      // Get the value of the "next" parameter
      const nextPage = searchParams.get('page');
      return nextPage?.length ? { page: nextPage } : {};
    } catch (err) {
      logger.error({ err }, `Error parsing URL for branches next page`);
      return {};
    }
  }
  async getUserActiveBranches(repoId: string, maxActiveBranchesToFetch: number): Promise<string[]> {
    const api = await this.getAuthAPI();
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    const { id: user_uuid } = await this.getUserData(GitProviderName.Bitbucket);
    const result: string[] = [];
    let nextURL = '';
    let hasNextPage = false;
    do {
      const { data } = await api.repositories.listBranches({
        repo_slug,
        workspace,
        ...this.getNextPageFromURL(nextURL),
        pagelen: 100,
        fields: 'values.name,values.target.author.user.uuid,next',
      });
      for (const branch of data.values ?? []) {
        if (result.length >= maxActiveBranchesToFetch) {
          break;
        } else if (branch.target?.author?.user?.uuid === user_uuid && result.length < maxActiveBranchesToFetch) {
          result.push(branch.name as string);
        }
      }
      nextURL = data.next;
      hasNextPage = result.length < maxActiveBranchesToFetch && !!data.next;
    } while (hasNextPage);
    return result;
  }

  // TODO: Test with Access Token with Org Permissions
  async getUserOrganizations(_provider: GitProviderName): Promise<string[]> {
    const api = await this.getAuthAPI();
    const organizations: string[] = [];
    let page = 1;
    try {
      do {
        const { data } = await api.workspaces.getWorkspaces({
          page: page.toString(),
          pagelen: MAX_ITEMS_TO_FETCH,
          fields: 'values.slug,next',
        });
        organizations.push(...data.values.map((org) => org.slug));
        if (data.next) {
          page++;
        } else {
          page = 0;
        }
      } while (page !== 0);
    } catch (e) {
      this.processError(e);
    }
    return organizations;
  }

  private processError(e) {
    if (e.status === StatusCodes.UNAUTHORIZED) {
      if (e.error.error.message === 'Token is invalid or not supported for this endpoint.') {
        logger.error(
          'Bitbucket Cloud does not support personal access tokens for this endpoint. Please use OAuth instead.'
        );
      } else {
        logger.error('Invalid credentials. Please check your credentials and try again.');
      }
    } else {
      logger.error(`Unknown error while fetching repositories from Bitbucket Cloud. ${e.message}`);
    }
    throw e;
  }

  // This function need for clean test result after test run.
  // But it is can be used for clean all repositories in the future.
  override async deleteBranch(repoId: string, branchName: string): Promise<void> {
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    const api = await this.getAuthAPI();
    await api.refs.deleteBranch({
      repo_slug,
      workspace,
      name: branchName,
    });
  }

  // This function need for clean test result after test run.
  // But it is can be used for clean all repositories in the future.
  override async closePullRequest(repoId: string, prId: number): Promise<void> {
    const api = await this.getAuthAPI();
    const { repo_slug, workspace } = await this.getRepoInformation(repoId);
    await api.pullrequests.decline({ repo_slug, workspace, pull_request_id: prId });
  }
}

export async function mapBitbucketPrData(
  pr: Schema.Pullrequest & { description?: string },
  files?: BitbucketDiffInformationDiffStateResponse
): Promise<PrDataWithTargetBranch> {
  return {
    prId: pr.id,
    title: pr.title,
    state: pr.state,
    url: pr.links.html.href,
    createdAt: pr.created_on,
    updatedAt: pr.updated_on,
    description: pr.description ?? '',
    prHeadSha: pr.source.commit.hash,
    prBaseSha: pr.destination.commit.hash,
    sourceBranchName: pr.source.branch.name,
    targetBranchName: pr.destination.branch.name,
    author: { username: pr.author.display_name ?? '' },
    files: files?.values.map((file) => ({
      additionsInFile: 1,
      path: file.new?.path ?? file.old?.path ?? '',
    })),
    filesWithAdditions: files?.values.length,
  };
}
