<script setup lang="ts">
import {
  BaseButton,
  BaseHeading,
  BaseIcon,
  BaseInput,
  BaseLayoutGap,
  BaseProse,
  BaseTextarea,
  BaseTooltip,
} from '@swimm/reefui';
import { computed } from 'vue';
import GenerativeAIMessageBox from './GenerativeAIMessageBox.vue';
import { Checkbox, SwText } from '@swimm/ui';
import { isMac } from '@swimm/shared';

const props = defineProps<{
  snippetsLength: number;
  genAiCapAvailable: boolean;
  genAiEnabledForRepo: boolean;
  workspaceId: string;
  repoId: string;
  isWorkspaceAdmin: boolean;
  customPrompt: string;
  title: string;
  showSplitSnippetCheckbox: boolean;
  splitLongSnippets: boolean;
  showErrorMessage: boolean;
}>();

const MAX_SNIPPETS_ALLOWED = 120;

const emit = defineEmits<{
  (e: 'start-generate'): void;
  (e: 'collapse'): void;
  (e: 'clicked-repo-settings'): void;
  (e: 'update-custom-prompt', customPrompt: string): void;
  (e: 'update-title', title: string): void;
  (e: 'update-split-snippets', splitSnippet: boolean): void;
  (e: 'add-whole-file'): void;
}>();

const generationAlowedForRepo = computed(() => {
  return props.genAiCapAvailable && props.genAiEnabledForRepo;
});

const generationInputValid = computed(() => {
  return props.title.trim().length > 0 && props.snippetsLength > 0 && props.snippetsLength <= MAX_SNIPPETS_ALLOWED;
});

const disbaledTooltip = computed(() => {
  if (!props.genAiCapAvailable) {
    return "You've exceeded your workspace's Generative AI query quota. Contact support via info@swimm.io for more info.";
  }
  if (!props.genAiEnabledForRepo) {
    return 'Generative AI is not enabled for this repository.';
  }
  if (props.snippetsLength === 0) {
    return 'No snippets selected to generate a document from.';
  }
  if (props.title.trim().length === 0) {
    return 'Please provide a title.';
  }
  if (props.snippetsLength > MAX_SNIPPETS_ALLOWED) {
    return `Too many snippets selected. Please select up to ${MAX_SNIPPETS_ALLOWED} snippets.`;
  }
  return '';
});

const onAddFileClick = () => {
  emit('add-whole-file');
};

const enterClicked = (event: KeyboardEvent) => {
  // On ctrl+enter/cmd+enter click - start generation
  if (isMac() ? event.metaKey : event.ctrlKey) {
    emit('start-generate');
  }
};
</script>

<template>
  <div class="generate-doc-content">
    <BaseLayoutGap direction="column" alignment="stretch" size="small" class="generate-content">
      <BaseLayoutGap
        wrap
        class="clickable title-row-container"
        direction="row"
        alignment="stretch"
        @click="emit('collapse')"
      >
        <BaseLayoutGap direction="row" size="small" class="title-row">
          <BaseLayoutGap direction="row" size="xsmall" class="title-logo">
            <BaseIcon name="magic" />
            <BaseHeading :level="5">Generate draft with AI</BaseHeading>
          </BaseLayoutGap>
          <BaseProse variant="secondary" size="small"
            >{{ snippetsLength }} {{ snippetsLength === 1 ? 'snippet' : 'snippets' }} selected (edit/add
            above)</BaseProse
          >
          <BaseButton
            v-if="genAiEnabledForRepo"
            variant="secondary"
            size="small"
            @click.stop="onAddFileClick"
            class="add-file-button"
          >
            Add file
            <template #leftIcon>
              <BaseIcon name="file-created" />
            </template>
          </BaseButton>
        </BaseLayoutGap>
        <BaseButton variant="tertiary"
          ><template #leftIcon><BaseIcon name="arrow-down" @click="emit('collapse')" /></template
        ></BaseButton>
      </BaseLayoutGap>
      <SwText v-if="showErrorMessage" class="error-message"
        >Error happened while generating doc! You can continue with existing content or try again.</SwText
      >
      <GenerativeAIMessageBox
        v-if="!genAiEnabledForRepo"
        :workspace-id="workspaceId"
        :repo-id="repoId"
        :is-workspace-admin="isWorkspaceAdmin"
        @clicked-repo-settings="emit('clicked-repo-settings')"
      >
      </GenerativeAIMessageBox>
      <BaseLayoutGap v-else direction="column" class="form" size="small" alignment="stretch">
        <BaseLayoutGap direction="column" size="xsmall">
          <BaseHeading :level="5">Pick topic/title <sup class="mandatory-indication">*</sup></BaseHeading>
          <BaseInput
            :model-value="title"
            :placeholder="`How we implemented X...`"
            focus-on-mount
            @update:model-value="emit('update-title', $event)"
          />
        </BaseLayoutGap>
        <BaseLayoutGap direction="column" size="xsmall">
          <div>
            <BaseHeading :level="5">Instructions / doc outline</BaseHeading>
            <BaseProse size="xsmall" variant="secondary"> We’ll consider these when generating your draft </BaseProse>
          </div>
          <BaseTextarea
            :model-value="customPrompt"
            class="input-textarea"
            :placeholder="'Write the document based on the following table of contents:\n  1. Introduction\n  2. Code flow\n  3. Conclusion'"
            @update:model-value="emit('update-custom-prompt', $event)"
            @keyup.enter="enterClicked"
          />
        </BaseLayoutGap>
        <Checkbox
          v-if="showSplitSnippetCheckbox"
          :model-value="splitLongSnippets"
          size="small"
          class="options"
          @update:model-value="emit('update-split-snippets', $event)"
          ><BaseProse variant="secondary">Allow AI to split up large snippets</BaseProse></Checkbox
        >
        <BaseLayoutGap direction="row" alignment="left">
          <BaseTooltip :content="disbaledTooltip">
            <BaseButton
              variant="magic"
              :disabled="!generationAlowedForRepo || !generationInputValid"
              @click="emit('start-generate')"
              >Generate</BaseButton
            >
          </BaseTooltip>
        </BaseLayoutGap>
      </BaseLayoutGap>
    </BaseLayoutGap>
  </div>
</template>

<style scoped>
.generate-doc-content {
  padding: var(--space-base);

  .input-textarea {
    width: 100%;
    min-height: 6.4rem;
    font-size: var(--font-size-small);
  }
}

.mandatory-indication {
  color: var(--text-color-error);
}

/* eslint-disable vue-scoped-css/no-unused-selector */
.icon.icon-magic {
  color: var(--text-color-magic-strong);
}

.add-file-button {
  margin-left: auto !important;
}

.form {
  padding: 0 var(--space-sm) !important;
}

.title-row-container {
  padding-left: var(--space-small) !important;

  .title-row {
    flex-grow: 1;
    row-gap: var(--space-base);

    .title-logo {
      margin-left: calc(var(--space-small) * -1);
    }
  }
}

.error-message {
  color: var(--text-color-error);
}
</style>
