<script lang="ts">
export type RadioBoxOption = {
  value: string;
  title: string;
  description?: string;
  recommended?: boolean;
};
</script>

<script setup lang="ts">
defineProps<{
  disabled: boolean;
  modelValue: string;
  options: RadioBoxOption[];
}>();

const emit = defineEmits(['update:modelValue']);
</script>

<template>
  <div class="boxes">
    <div class="box" v-for="option of options" :key="option.value">
      <input
        :disabled="disabled"
        type="radio"
        value="repo"
        :checked="modelValue === option.value"
        @input="emit('update:modelValue', option.value)"
      />
      <div class="box-description">
        <SwText variant="subtitle-L" class="box-description-top"
          ><span class="title" @click="emit('update:modelValue', option.value)"
            ><strong>{{ option.title }}</strong></span
          >
          <SwText v-if="option.recommended" variant="body-S" component="span" class="recommended">
            (recommended)</SwText
          ></SwText
        >
        <SwText v-if="option.description" variant="body-S" class="box-description-text">{{
          option.description
        }}</SwText>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.boxes {
  display: flex;
  flex-direction: row;
  margin-top: var(--space-sm);
  gap: var(--space-base);

  .box {
    width: 100%;
    border: 1px var(--color-border-default) solid;
    border-radius: 4px;
    padding: var(--space-sm);
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: var(--space-sm);

    input {
      margin-right: 0;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    .box-description {
      margin-left: var(--space-base);

      .box-description-top {
        margin-bottom: var(--space-xs);

        .title {
          cursor: pointer;
        }

        .recommended {
          color: var(--text-color-secondary);
        }
      }

      .box-description-text {
        color: var(--text-color-secondary);
      }
    }
  }
}
</style>
