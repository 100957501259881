// @ts-strict
import type { ResultWithReturnCode, SwmResourceFile, XOR } from '../../types/common-types';
import { GitProviderInfo, GitProviderName, PathType } from '../../types/gitwrapper-types';
import type { RepoStateData } from '../../types/repo-types';
export type DriverOptions = { baseUrl?: string | undefined; tenantId?: string; authToken?: string | undefined };

export interface RepoIdOwnerName {
  repoId: string;
  owner: string;
  repoName: string;
}

export interface Branch {
  id: string;
  name: string;
  sha: string;
  protected: boolean;
  lastUpdated: string;
}

export interface Commit {
  sha: string;
  commitMessage: string;
}

export interface DiffFileMetadata {
  oldFilePath: string;
  newFilePath: string;
  status: string;
  sha?: string;
}

export interface RemoteRepository {
  owner: string;
  name: string;
  isPrivate: boolean;
  htmlUrl: string;
  fork: boolean;
  cloneUrl: string;
  defaultBranch: string;
  writeAccess: boolean;
}

export interface RepoTreePrams {
  repoId: string;
  recursive?: boolean;
  treeSha?: string;
  cacheBuster?: boolean;
}

export interface RepoTree {
  path?: string;
  mode?: string;
  type?: string;
  sha?: string;
  size?: number;
  url?: string;
}

export type PartialRemoteRepository = Pick<RemoteRepository, 'owner' | 'name' | 'defaultBranch'> | 'not-found';

export interface ProviderTerminology {
  displayName: string;
  pullRequest: string;
  pullRequestShort: string;
  repository: string;
  prState: Record<'open' | 'closed' | 'merged', PullRequestState>;
}

type MergeRequestState = 'OPEN' | 'CLOSED' | 'MERGED';

export const GitHubPullRequestState: Record<MergeRequestState, string> = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  MERGED: 'MERGED',
};

export const GitLabPullRequestState: Record<MergeRequestState, string> = {
  OPEN: 'opened',
  CLOSED: 'closed',
  MERGED: 'merged',
};

export const BitbucketPullRequestState: Record<MergeRequestState, string> = {
  OPEN: 'OPEN',
  CLOSED: 'DECLINED',
  MERGED: 'MERGED',
};

export const AzurePullRequestState: Record<MergeRequestState, string> = {
  OPEN: 'active',
  CLOSED: 'abandoned',
  MERGED: 'completed',
};

export type PullRequestState =
  | (typeof GitHubPullRequestState)[keyof typeof GitHubPullRequestState]
  | (typeof GitLabPullRequestState)[keyof typeof GitLabPullRequestState]
  | (typeof BitbucketPullRequestState)[keyof typeof BitbucketPullRequestState]
  | (typeof AzurePullRequestState)[keyof typeof AzurePullRequestState];
/**
 * PrHeadSha: the HEAD commit of the prs' branch
 * prBaseSha: the commit sha with which the pr is compared
 */
export interface PrData {
  files: {
    additionsInFile: number;
    path: string;
  }[];
  state: string;
  prId: number;
  filesWithAdditions: number;
  createdAt: string;
  updatedAt: string;
  url: string;
  title: string;
  description: string;
  prHeadSha: string;
  prBaseSha: string;
  sourceBranchName: string;
  author: { username: string };
  isDraft?: boolean;
}

export type PrDataWithTargetBranch = PrData & { targetBranchName: string };

export interface CreatedPR {
  url: string;
}

export interface PendingPR {
  path: string;
  created: string;
  modified: string;
  branch: string;
  pr: number;
  isAdded: boolean;
  isDeleted?: boolean;
  optionallyRemoved: boolean;
  prTitle: string;
}

export interface FileWithStatus {
  sha: string;
  filename: string;
  status: 'added' | 'removed' | 'modified' | 'renamed' | 'copied' | 'changed' | 'unchanged';
  additions: number;
  deletions: number;
  changes: number;
  blob_url: string;
  raw_url: string;
  contents_url: string;
  patch?: string;
  previous_filename?: string;
}

export enum githubChangeStatus {
  Modified = 'modified',
  Renamed = 'renamed',
  Deleted = 'removed',
  Added = 'added',
  Copied = 'copied',
  Unchanged = 'unchanged',
}

export const bitBucketStatus2GitHubStatus: Record<string, string> = {
  removed: 'DELETED',
  renamed: 'MODIFIED',
  added: 'ADDED',
  modified: 'MODIFIED',
};

export interface GitTreeItem {
  path: string;
  sha: string;
  mode: string;
  type: string;
}

export enum APIRequestType {
  REST = 'REST',
  GRAPHQL = 'GraphQL',
}

export const GitProviderTerminology: Record<GitProviderName, ProviderTerminology> = {
  [GitProviderName.GitHub]: {
    displayName: 'GitHub',
    pullRequest: 'Pull Request',
    pullRequestShort: 'PR',
    repository: 'Repository',
    prState: {
      open: GitHubPullRequestState.OPEN,
      closed: GitHubPullRequestState.CLOSED,
      merged: GitHubPullRequestState.MERGED,
    },
  },
  [GitProviderName.GitHubEnterprise]: {
    displayName: 'GitHub',
    pullRequest: 'Pull Request',
    pullRequestShort: 'PR',
    repository: 'Repository',
    prState: {
      open: GitHubPullRequestState.OPEN,
      closed: GitHubPullRequestState.CLOSED,
      merged: GitHubPullRequestState.MERGED,
    },
  },
  [GitProviderName.GitLab]: {
    displayName: 'GitLab',
    pullRequest: 'Merge Request',
    pullRequestShort: 'MR',
    repository: 'Project',
    prState: {
      open: GitLabPullRequestState.OPEN,
      closed: GitLabPullRequestState.CLOSED,
      merged: GitLabPullRequestState.MERGED,
    },
  },
  [GitProviderName.GitLabEnterprise]: {
    displayName: 'GitLab',
    pullRequest: 'Merge Request',
    pullRequestShort: 'MR',
    repository: 'Project',
    prState: {
      open: GitLabPullRequestState.OPEN,
      closed: GitLabPullRequestState.CLOSED,
      merged: GitLabPullRequestState.MERGED,
    },
  },
  [GitProviderName.Testing]: {
    displayName: 'GitHub',
    pullRequest: 'Pull Request',
    pullRequestShort: 'PR',
    repository: 'Repository',
    prState: {
      open: GitHubPullRequestState.OPEN,
      closed: GitHubPullRequestState.CLOSED,
      merged: GitHubPullRequestState.MERGED,
    },
  },
  [GitProviderName.Bitbucket]: {
    displayName: 'Bitbucket',
    pullRequest: 'Pull requests',
    pullRequestShort: 'PR',
    repository: 'Repositories',
    prState: {
      open: BitbucketPullRequestState.OPEN,
      closed: BitbucketPullRequestState.CLOSED,
      merged: BitbucketPullRequestState.MERGED,
    },
  },
  [GitProviderName.BitbucketDc]: {
    displayName: 'Bitbucket',
    pullRequest: 'Pull requests',
    pullRequestShort: 'PR',
    repository: 'Repositories',
    prState: {
      open: BitbucketPullRequestState.OPEN,
      closed: BitbucketPullRequestState.CLOSED,
      merged: BitbucketPullRequestState.MERGED,
    },
  },
  [GitProviderName.AzureDevops]: {
    displayName: 'Azure DevOps',
    pullRequest: 'Pull requests',
    pullRequestShort: 'PR',
    repository: 'Repos',
    prState: {
      open: AzurePullRequestState.OPEN,
      closed: AzurePullRequestState.CLOSED,
      merged: AzurePullRequestState.MERGED,
    },
  },
};

/**
 * Th e entire API for our Git providers is `repoId`-centric, in the sense that we need to know the repoId in order to divert the call to the correct underlying driver.
 * Since there are some cases at which we don't have a `repoId` passed (when we're initializing a repo, for example), we need to pass the raw parameters (name, owner)
 *  as well as the correct provider (in order to call the right driver).
 * This type is a logical XOR: you can either pass the first set of arguments (just `repoId`), or the second set (`repoName`, `owner`, `provider`).
 */
export type RepoIdOrRepoData = XOR<{ repoId: string }, RepoStateData & { repoId?: string }>;

export interface GitDriverBase extends ProviderSpecificTerms {
  deleteTokenFromMemory(provider: GitProviderName, driverOptions?: DriverOptions): void;

  getUserData(
    provider: GitProviderName,
    driverOptions?: DriverOptions
  ): Promise<{ login: string; id: number | string }>;

  getOrganizationData(
    provider: GitProviderName,
    driverOptions?: DriverOptions
  ): Promise<{ name: string; numOfDevelopers: number }>;

  isBranchExists({ repoId, branchName }: { repoId: string; branchName: string }): Promise<boolean>;

  getChangeRequestName({ repoId }: { repoId: string }): Promise<string>;

  getBranch({
    repoId,
    branchName,
    cacheBuster,
  }: {
    repoId: string;
    branchName: string;
    cacheBuster?: boolean;
  }): Promise<Branch>;

  getBranches({
    repoId,
    prStates,
  }: {
    repoId: string;
    prStates?: PullRequestState[];
  }): AsyncIterable<{ hasNextPage: boolean; branches: Branch[] }>;

  getPr({ repoId, prId }: { repoId: string; prId: string }): Promise<PrData>;

  getPrs({ repoId, prState }: { repoId: string; prState?: string }): Promise<PrData[]>;

  getPendingDocsForBranch({
    repoId,
    branchName,
  }: {
    repoId: string;
    branchName: string;
  }): Promise<Record<string, PendingPR[]>>;

  getFileContentFromRevision({
    filePath,
    repoId,
    revision,
    raw,
    safe,
  }: {
    filePath: string;
    repoId: string;
    revision: string;
    raw?: boolean;
    safe?: boolean;
  }): Promise<string>;

  isFileExistsOnRevision({
    repoId,
    filePath,
    revision,
  }: {
    repoId: string;
    filePath: string;
    revision?: string;
  }): Promise<boolean>;

  createBranch({
    repoId,
    branchName,
    sourceSha,
  }: {
    repoId: string;
    branchName: string;
    sourceSha: string;
  }): Promise<void>;

  createPullRequest({
    repoId,
    fromBranch,
    toBranch,
    title,
    body,
    draft,
  }: {
    repoId: string;
    fromBranch: string;
    toBranch: string;
    title?: string;
    body?: string;
    draft?: boolean;
  }): Promise<CreatedPR>;

  deleteFileIfExists({
    filePath,
    branch,
    commitMessage,
    repoId,
  }: {
    repoId: string;
    branch: string;
    filePath: string;
    commitMessage: string;
  }): Promise<void>;

  fetchFileCommitHistory({
    repoId,
    branch,
    relativeFilePath,
    maxCommitsNumber,
    maxPagesNumber,
  }: {
    repoId: string;
    branch: string;
    relativeFilePath: string;
    maxCommitsNumber?: number;
    maxPagesNumber?: number;
  }): Promise<Commit[]>;

  getLastCommitShaWhereFileExisted(relativeFilePath: string, repoId: string, destCommit: string): Promise<string>;

  getPathType({
    path,
    repoId,
    revision,
  }: {
    path: string;
    repoId: string;
    revision: string;
  }): Promise<ResultWithReturnCode<{ pathType: PathType }, { errorMessage: string }>>;

  /**
   *  Fetch repo metadata from provider
   * @param repoId the repoId, if availabe
   * @param repoName name of the repo (must if no repoId)
   * @param owner name of the organization / user who owns the repo (must if no repoId)
   * @param provider the git provider (must if no repoId)
   */
  getRepoRemoteData({ repoId, provider, repoName, owner }: RepoIdOrRepoData): Promise<PartialRemoteRepository>;

  getRepoRemoteDataBatch({
    provider,
    driverOptions,
    repos,
  }: {
    provider: GitProviderName;
    repos: RepoIdOwnerName[];
    driverOptions?: DriverOptions;
  }): Promise<Record<string, PartialRemoteRepository>>;

  pushMultipleFilesToBranch({
    files,
    repoId,
    branch,
    commitMessage,
  }: {
    files: SwmResourceFile[];
    repoId: string;
    branch: string;
    commitMessage: string;
  });

  // TODO:  should be changed for a general `gitDiff` function
  getGitDiffRemote({ repoId, base, head }: { repoId: string; base: string; head: string }): Promise<string>;

  // note:  for local gitwrapper includes also the untracked and staged files
  getRepoTree({
    repoId,
    recursive,
    treeSha,
    cacheBuster,
    path,
  }: {
    repoId: string;
    recursive?: boolean;
    treeSha?: string;
    cacheBuster?: boolean;
    path?: string;
  }): Promise<
    {
      path?: string;
      mode?: string;
      type?: string;
      sha?: string;
      size?: number;
      url?: string;
    }[]
  >;

  getDiffFiles({
    repoId,
    compareFrom,
    compareTo,
    includeShas,
  }: {
    repoId: string;
    compareFrom: string;
    compareTo: string;
    includeShas?: boolean;
  }): Promise<DiffFileMetadata[]>;

  getUserRemoteRepositories(provider: GitProviderName, driverOptions?: DriverOptions): AsyncIterable<RemoteRepository>;

  getRepositoryLanguages(repoId: string): Promise<{ [language: string]: number } | undefined>;

  getAllSwmFilesContent({
    repoId,
    revision,
    path,
  }: {
    repoId: string;
    revision: string;
    path?: string;
  }): Promise<{ path: string; content: string }[]>;

  getUserActiveBranches(repoId: string, maxActiveBranchesToFetch: number): Promise<string[]>;
  getUserOrganizations(provider: GitProviderName, driverOptions?: DriverOptions): Promise<string[]>;
}

export class ProviderSpecificTerms {
  getDisplayName(provider: GitProviderName): string {
    return GitProviderInfo[provider].displayName;
  }

  getTerminology(provider: GitProviderName): ProviderTerminology {
    return GitProviderTerminology[provider];
  }

  async deleteBranch(_repoId: string, _branchName: string): Promise<void> {
    throw new Error('Not implemented, yet');
  }

  async closePullRequest(_repoId: string, _prId: number): Promise<void> {
    throw new Error('Not implemented, yet');
  }
}
