// @ts-strict
import type { ResultWithReturnCode, SwmResourceFile } from '../../types/common-types';
import type { GetCurrentName } from '../../types/gitwrapper-types';
import { GitProviderName, PathType } from '../../types/gitwrapper-types';
import type {
  Branch,
  Commit,
  CreatedPR,
  DiffFileMetadata,
  DriverOptions,
  GitDriverBase,
  PartialRemoteRepository,
  PrData,
  PullRequestState,
  RemoteRepository,
  RepoIdOrRepoData,
  RepoIdOwnerName,
} from '../gitdrivers/git-provider-base';
import { PendingPR, ProviderSpecificTerms, githubChangeStatus } from '../gitdrivers/git-provider-base';
import { GitHubDriver } from '../gitdrivers/github-driver';
import { GitLabDriver } from '../gitdrivers/gitlab-driver';
import * as config from '../../config';
import { DETECT_RENAMES_PERCENTAGE } from '../../config';
import { createCounterObject, makeGetCurrentNameCacheKey, sortCounter } from '../helpers';
import { getRepoStateData } from '../git-provider-utils';
import { TestingDriver } from '../gitdrivers/testing-driver';
import { isEmpty } from '../../objectUtils';
import { AzureDriver } from '../gitdrivers/azure-driver';
import { BitbucketDriver } from '../gitdrivers/bitbucket-driver';
import { BitbucketDcDriver } from '../gitdrivers/bitbucket-dc-driver';
import { getLoggerNew } from '#logger';

const logger = getLoggerNew("packages/shared/src/git-utils/remote/gitwrapper.ts");

const defaultDrivers = {
  [GitProviderName.Testing]: TestingDriver,
  [GitProviderName.GitHub]: GitHubDriver,
  [GitProviderName.GitLab]: GitLabDriver,
  [GitProviderName.GitLabEnterprise]: GitLabDriver,
  [GitProviderName.GitHubEnterprise]: GitHubDriver,
  [GitProviderName.Bitbucket]: BitbucketDriver,
  [GitProviderName.BitbucketDc]: BitbucketDcDriver,
  [GitProviderName.AzureDevops]: AzureDriver,
};

class GitWrapper extends ProviderSpecificTerms implements GitDriverBase {
  private CURRENT_NAME_CACHE: Record<string, GetCurrentName> = {};

  // Will store any instance created with driverOptions through `getProvider`.
  private initializedDrivers: Record<string, GitDriverBase> = {};

  private getProvider({
    provider,
    driverOptions,
  }: {
    provider: GitProviderName;
    driverOptions?: DriverOptions;
  }): GitDriverBase {
    const providerKey =
      driverOptions && !isEmpty(driverOptions)
        ? `${provider}-${driverOptions.baseUrl}${driverOptions.tenantId ? `-${driverOptions.tenantId}` : ''}`
        : provider;

    if (!this.initializedDrivers[providerKey]) {
      if (!defaultDrivers[provider]) {
        throw new Error(`No driver found for provider ${provider}`);
      }
      logger.info(
        `Initializing driver for provider ${provider}, with base_url: ${driverOptions?.baseUrl} and tenant_id: ${driverOptions?.tenantId}`
      );
      this.initializedDrivers[providerKey] = new defaultDrivers[provider](driverOptions);
    }
    return this.initializedDrivers[providerKey];
  }

  private async getRepoProvider(
    repoId: string
  ): Promise<{ providerName: GitProviderName; driverOptions?: DriverOptions }> {
    const repo = await getRepoStateData(repoId);

    if (!repo?.provider || !Object.values(GitProviderName).includes(repo.provider)) {
      throw new Error(`Stored repo provider '${repo?.provider}' not included in allowed GitProviderNames.`);
    }
    // NOTE: It is imperative that `driverOptions` will either return `undefined` or as an empty object whenever there are no options passed.
    const driverOptions: DriverOptions | undefined = repo.api_url
      ? { baseUrl: repo.api_url, tenantId: repo.tenant_id }
      : undefined;
    return { providerName: repo.provider, driverOptions };
  }

  private async getDriverInstance(repoId: string): Promise<GitDriverBase> {
    const { providerName, driverOptions } = await this.getRepoProvider(repoId);
    return this.getProvider({ provider: providerName, driverOptions });
  }

  private registerGetCurrentNameCache(key: string, value: GetCurrentName): GetCurrentName {
    this.CURRENT_NAME_CACHE[key] = value;
    return value;
  }

  deleteTokenFromMemory(provider: GitProviderName, driverOptions?: DriverOptions): void {
    const driver = this.getProvider({ provider, driverOptions });
    return driver.deleteTokenFromMemory(provider, driverOptions);
  }

  async getUserData(
    provider: GitProviderName,
    driverOptions?: DriverOptions
  ): Promise<{ login: string; id: number | string }> {
    const driver = this.getProvider({ provider, driverOptions });
    return driver.getUserData(provider, driverOptions);
  }

  async getOrganizationData(
    provider: GitProviderName,
    driverOptions?: DriverOptions
  ): Promise<{ name: string; numOfDevelopers: number }> {
    const driver = this.getProvider({ provider, driverOptions });
    return driver.getOrganizationData(provider, driverOptions);
  }

  async isBranchExists({ repoId, branchName }: { repoId: string; branchName: string }): Promise<boolean> {
    const driver = await this.getDriverInstance(repoId);
    return driver.isBranchExists({ repoId, branchName });
  }

  async getChangeRequestName({ repoId }: { repoId: string }): Promise<string> {
    if (!repoId || repoId === 'ONBOARDING') {
      // I thought this might be a good place to handle instead of each one individually.
      return 'Pull Request';
    }
    const driver = await this.getDriverInstance(repoId);
    return await driver.getChangeRequestName({ repoId });
  }

  async getBranch({
    repoId,
    branchName,
    cacheBuster,
  }: {
    repoId: string;
    branchName: string;
    cacheBuster?: boolean;
  }): Promise<Branch> {
    const driver = await this.getDriverInstance(repoId);
    return driver.getBranch({ repoId, branchName, cacheBuster });
  }

  async *getBranches({
    repoId,
    prStates,
  }: {
    repoId: string;
    prStates?: PullRequestState[];
  }): AsyncIterable<{ hasNextPage: boolean; branches: Branch[] }> {
    const driver = await this.getDriverInstance(repoId);
    yield* driver.getBranches({ repoId, prStates });
  }

  async getPr({ repoId, prId }: { repoId: string; prId: string }): Promise<PrData> {
    const driver = await this.getDriverInstance(repoId);
    return driver.getPr({ repoId, prId });
  }

  async getPrs({ repoId, prState }: { repoId: string; prState?: string | undefined }): Promise<PrData[]> {
    const driver = await this.getDriverInstance(repoId);
    return driver.getPrs({ repoId, prState });
  }

  async getPendingDocsForBranch({
    repoId,
    branchName,
  }: {
    repoId: string;
    branchName: string;
  }): Promise<Record<string, PendingPR[]>> {
    const driver = await this.getDriverInstance(repoId);
    return driver.getPendingDocsForBranch({ repoId, branchName });
  }

  async getFileContentFromRevision({
    filePath,
    repoId,
    revision,
    raw,
    safe = false,
  }: {
    filePath: string;
    repoId: string;
    revision: string | undefined;
    raw?: boolean;
    safe?: boolean;
  }): Promise<string> {
    const driver = await this.getDriverInstance(repoId);
    return driver.getFileContentFromRevision({ filePath, repoId, revision, raw, safe });
  }

  async isFileExistsOnRevision({
    repoId,
    filePath,
    revision,
  }: {
    repoId: string;
    filePath: string;
    revision?: string | undefined;
  }): Promise<boolean> {
    const driver = await this.getDriverInstance(repoId);
    return driver.isFileExistsOnRevision({ repoId, filePath, revision });
  }

  async createBranch({
    repoId,
    branchName,
    sourceSha,
  }: {
    repoId: string;
    branchName: string;
    sourceSha: string;
  }): Promise<void> {
    const driver = await this.getDriverInstance(repoId);
    return driver.createBranch({ repoId, branchName, sourceSha });
  }

  async createPullRequest({
    repoId,
    fromBranch,
    toBranch,
    title,
    body,
  }: {
    repoId: string;
    fromBranch: string;
    toBranch: string;
    title?: string | undefined;
    body?: string | undefined;
  }): Promise<CreatedPR> {
    const driver = await this.getDriverInstance(repoId);
    return driver.createPullRequest({ repoId, fromBranch, toBranch, title, body });
  }

  async deleteFileIfExists({
    filePath,
    branch,
    commitMessage,
    repoId,
  }: {
    repoId: string;
    branch: string;
    filePath: string;
    commitMessage: string;
  }): Promise<void> {
    const driver = await this.getDriverInstance(repoId);
    return driver.deleteFileIfExists({ filePath, branch, commitMessage, repoId });
  }

  async getCurrentName({
    repoId,
    oldFilePath,
    destCommit,
  }: {
    repoId: string;
    oldFilePath: string;
    destCommit: string;
  }): Promise<GetCurrentName> {
    try {
      if (!destCommit) {
        const repoData = await this.getRepoRemoteData({ repoId });
        destCommit = repoData?.defaultBranch ?? '';
      }
      const branchSha = (await this.getBranch({ repoId, branchName: destCommit })).sha;
      const cacheKey = makeGetCurrentNameCacheKey({ oldFilePath, destCommit: branchSha, repoId });
      if (this.CURRENT_NAME_CACHE[cacheKey]) {
        return this.CURRENT_NAME_CACHE[cacheKey];
      }

      let pathTypeRes = await this.getPathType({ path: oldFilePath, repoId, revision: destCommit });
      if (pathTypeRes.code === config.SUCCESS_RETURN_CODE) {
        return this.registerGetCurrentNameCache(cacheKey, {
          code: config.SUCCESS_RETURN_CODE,
          exists: true,
          isRenamed: false,
          currentName: oldFilePath,
          isDirectory: pathTypeRes.pathType === PathType.Folder,
        });
      }

      let currentFilePath = oldFilePath;
      let lastRevision: string;

      do {
        lastRevision = await this.getLastCommitShaWhereFileExisted(currentFilePath, repoId, destCommit);
        if (!lastRevision) {
          // The file doesn't exist, so there should be a last revision where it appeared...
          return this.registerGetCurrentNameCache(cacheKey, { code: config.ERROR_RETURN_CODE });
        }
        const { isRenamed, newPath } = await this.getNewNameFromRenameCommit(currentFilePath, lastRevision, repoId);
        if (!isRenamed) {
          return this.registerGetCurrentNameCache(cacheKey, { code: config.SUCCESS_RETURN_CODE, exists: false });
        }
        currentFilePath = newPath;
        pathTypeRes = await this.getPathType({ path: currentFilePath, repoId, revision: destCommit });
      } while (pathTypeRes.code !== config.SUCCESS_RETURN_CODE);

      return this.registerGetCurrentNameCache(cacheKey, {
        code: config.SUCCESS_RETURN_CODE,
        exists: true,
        isRenamed: true,
        currentName: currentFilePath,
        isDirectory: pathTypeRes.pathType === PathType.Folder,
      });
    } catch (ex) {
      return { code: config.ERROR_RETURN_CODE };
    }
  }

  async getLastCommitShaWhereFileExisted(
    relativeFilePath: string,
    repoId: string,
    destCommit: string
  ): Promise<string> {
    const driver = await this.getDriverInstance(repoId);
    return driver.getLastCommitShaWhereFileExisted(relativeFilePath, repoId, destCommit);
  }

  async fetchFileCommitHistory({
    repoId,
    branch,
    maxCommitsNumber,
    relativeFilePath,
    maxPagesNumber,
  }: {
    repoId: string;
    branch: string;
    relativeFilePath: string;
    maxCommitsNumber?: number;
    maxPagesNumber?: number;
  }): Promise<Commit[]> {
    const driver = await this.getDriverInstance(repoId);
    return driver.fetchFileCommitHistory({ relativeFilePath, repoId, branch, maxCommitsNumber, maxPagesNumber });
  }

  /**
   * Given the commit where a folder was (maybe) renamed, finds the folder's new name.
   *
   * @param repoId - the repoId we're working on
   * @param oldPath - the path of the folder prior to the rename
   * @param renamesStatuses - the result of `getDiffFiles` for the relevant commit - with a Renamed status
   * @param deletedStatuses - the result of `getDiffFiles` for the relevant commit - with a Deleted status
   */
  private async getNewNameFromRenameCommitForFolder({
    oldPath,
    renamesStatuses,
    deletedStatuses,
  }: {
    repoId: string;
    oldPath: string;
    renamesStatuses: DiffFileMetadata[];
    deletedStatuses: DiffFileMetadata[];
  }): Promise<{ isRenamed: boolean; newPath: string }> {
    const failureResult = { isRenamed: false, newPath: '' };

    const deletedStatusesOfRelevantOldPath = deletedStatuses.filter((fileAndStatus) =>
      fileAndStatus.newFilePath.startsWith(oldPath)
    );
    const renamedStatusesOfRelevantOldPath = renamesStatuses.filter(
      (fileAndStatus) =>
        fileAndStatus.oldFilePath !== fileAndStatus.newFilePath && fileAndStatus.oldFilePath.startsWith(oldPath)
    );
    const numberOfElementsInPathPriorToRename =
      deletedStatusesOfRelevantOldPath.length + renamedStatusesOfRelevantOldPath.length;
    if (numberOfElementsInPathPriorToRename === 0) {
      return failureResult;
    }

    const oldPathLength = oldPath.length;
    const statusesWhereTheSuffixDidntChange = renamedStatusesOfRelevantOldPath.filter(
      (relevantPath) =>
        relevantPath.oldFilePath !== relevantPath.newFilePath &&
        relevantPath.newFilePath.endsWith(relevantPath.oldFilePath.slice(oldPathLength))
    );
    const newPathCandidates = statusesWhereTheSuffixDidntChange.map(
      (relevantPath) =>
        relevantPath.oldFilePath !== relevantPath.newFilePath &&
        relevantPath.newFilePath.slice(0, -relevantPath.oldFilePath.slice(oldPathLength).length)
    );
    const pathCandidatesCounter = sortCounter(createCounterObject(newPathCandidates));
    const [likelyNewPathCandidate, likelyNewPathCandidateCount] = pathCandidatesCounter[0];
    if (
      likelyNewPathCandidateCount / numberOfElementsInPathPriorToRename >=
      parseInt(DETECT_RENAMES_PERCENTAGE) / 100
    ) {
      return { isRenamed: true, newPath: likelyNewPathCandidate };
    }
    return failureResult;
  }

  private async getNewNameFromRenameCommit(
    oldPath: string,
    lastRevision: string,
    repoId: string
  ): Promise<{ isRenamed: boolean; newPath: string }> {
    const failureResult = { isRenamed: false, newPath: '' };
    try {
      const pathTypeResult = await this.getPathType({ path: oldPath, repoId: repoId, revision: `${lastRevision}~1` });
      if (pathTypeResult.code !== config.SUCCESS_RETURN_CODE) {
        return failureResult;
      }
      const diffFilesMetadata = await this.getDiffFiles({
        repoId,
        compareFrom: `${lastRevision}~1`,
        compareTo: lastRevision,
      });
      const renamesStatuses = diffFilesMetadata.filter((file) => file.status === githubChangeStatus.Renamed);
      if (pathTypeResult.pathType === PathType.File) {
        for (const fileAndStatus of renamesStatuses) {
          if (fileAndStatus.oldFilePath === oldPath) {
            return { isRenamed: true, newPath: fileAndStatus.newFilePath };
          }
        }
        return failureResult;
      }
      const deletedStatuses = diffFilesMetadata.filter((file) => file.status === githubChangeStatus.Deleted);
      return this.getNewNameFromRenameCommitForFolder({ repoId, oldPath, renamesStatuses, deletedStatuses });
    } catch (ex) {
      return failureResult;
    }
  }

  async getPathType({
    path,
    repoId,
    revision,
  }: {
    path: string;
    repoId: string;
    revision: string;
  }): Promise<ResultWithReturnCode<{ pathType: PathType }, { errorMessage: string }>> {
    const driver = await this.getDriverInstance(repoId);
    return driver.getPathType({ path, repoId, revision });
  }

  async getRepoRemoteData({
    repoId,
    provider,
    repoName,
    owner,
    api_url,
    tenant_id,
  }: RepoIdOrRepoData): Promise<RemoteRepository> {
    let driver;
    if (provider) {
      const getProviderArgs: { provider: GitProviderName; driverOptions?: DriverOptions } = {
        provider: provider || GitProviderName.GitHub,
      };
      if (api_url) {
        getProviderArgs.driverOptions = { baseUrl: api_url, tenantId: tenant_id };
      }
      driver = this.getProvider(getProviderArgs);
    } else {
      driver = await this.getDriverInstance(repoId);
    }
    return driver.getRepoRemoteData({ repoId, repoName, owner });
  }

  async pushMultipleFilesToBranch({
    files,
    repoId,
    branch,
    commitMessage,
  }: {
    files: SwmResourceFile[];
    repoId: string;
    branch: string;
    commitMessage: string;
  }) {
    const driver = await this.getDriverInstance(repoId);
    return driver.pushMultipleFilesToBranch({ files, repoId, branch, commitMessage });
  }

  async getGitDiffRemote({ repoId, base, head }: { repoId: string; base: string; head: string }): Promise<string> {
    const driver = await this.getDriverInstance(repoId);
    return driver.getGitDiffRemote({ repoId, base, head });
  }

  async getRepoTree({
    repoId,
    recursive,
    treeSha,
    cacheBuster,
    path,
  }: {
    repoId: string;
    recursive?: boolean;
    treeSha?: string;
    cacheBuster?: boolean;
    path?: string;
  }): Promise<{ path?: string; mode?: string; type?: string; sha?: string; size?: number; url?: string }[]> {
    const driver = await this.getDriverInstance(repoId);
    return driver.getRepoTree({ repoId, recursive, treeSha, cacheBuster, path });
  }

  async getDiffFiles({
    repoId,
    compareFrom,
    compareTo,
    includeShas,
  }: {
    repoId: string;
    compareFrom: string;
    compareTo: string;
    includeShas?: boolean;
  }): Promise<DiffFileMetadata[]> {
    const driver = await this.getDriverInstance(repoId);
    return driver.getDiffFiles({ repoId, compareFrom, compareTo, includeShas });
  }

  async *getUserRemoteRepositories(
    provider: GitProviderName,
    driverOptions?: DriverOptions
  ): AsyncIterable<RemoteRepository> {
    const driver = this.getProvider({ provider, driverOptions });
    yield* driver.getUserRemoteRepositories(provider, driverOptions);
  }

  async getRepoRemoteDataBatch({
    provider,
    driverOptions,
    repos,
  }: {
    provider: GitProviderName;
    repos: RepoIdOwnerName[];
    driverOptions?: DriverOptions;
  }): Promise<Record<string, PartialRemoteRepository>> {
    const driver = this.getProvider({ provider, driverOptions });
    return driver.getRepoRemoteDataBatch({ provider, driverOptions, repos });
  }

  async getRepositoryLanguages(repoId: string): Promise<{ [language: string]: number } | undefined> {
    const driver = await this.getDriverInstance(repoId);
    return driver.getRepositoryLanguages(repoId);
  }

  async getAllSwmFilesContent({
    repoId,
    revision,
    path,
  }: {
    repoId: string;
    revision: string;
    path?: string;
  }): Promise<{ path: string; content: string }[]> {
    const driver = await this.getDriverInstance(repoId);
    return driver.getAllSwmFilesContent({ repoId, revision, path });
  }

  // Polyfill for browser, it should never get here
  async getSwimmJsonContentsWrapper() {
    return {};
  }
  async getFullFileContentFromFSWrapper({
    filePath,
    repoId,
    revision,
  }: {
    filePath: string;
    repoId: string;
    revision: string;
  }) {
    const driver = await this.getDriverInstance(repoId);
    return driver.getFileContentFromRevision({ filePath, repoId, revision });
  }

  async getUserActiveBranches(repoId: string, maxActiveBranchesToFetch: number): Promise<string[]> {
    const driver = await this.getDriverInstance(repoId);
    return await driver.getUserActiveBranches(repoId, maxActiveBranchesToFetch);
  }

  async getUserOrganizations(provider: GitProviderName, driverOptions?: DriverOptions): Promise<string[]> {
    const driver = this.getProvider({ provider, driverOptions });
    return driver.getUserOrganizations(provider, driverOptions);
  }

  async getFileHtmlURL({
    repoId,
    filePath,
    revision,
    provider,
  }: {
    repoId: string;
    filePath: string;
    revision: string;
    provider: GitProviderName;
  }): Promise<string> {
    const repoData = await this.getRepoRemoteData({ repoId });
    let url = repoData.htmlUrl;

    switch (provider) {
      case GitProviderName.GitHub:
      case GitProviderName.GitHubEnterprise:
        url += `/blob/${revision}/${filePath}`;
        break;
      case GitProviderName.GitLab:
      case GitProviderName.GitLabEnterprise:
        url += `/-/blob/${revision}/${filePath}`;
        break;
      case GitProviderName.AzureDevops:
        url += `?path=/${filePath}&version=GB${revision}`;
        break;
      case GitProviderName.Bitbucket:
        url += `/src/${revision}/${filePath}`;
        break;
      case GitProviderName.BitbucketDc:
        url += `/browse/${filePath}/?at=${revision}`;
        break;
      default:
        break;
    }
    return url;
  }

  override async deleteBranch(repoId: string, branchName: string): Promise<void> {
    const driver = await this.getDriverInstance(repoId);
    return driver.deleteBranch(repoId, branchName);
  }

  override async closePullRequest(repoId: string, prId: number): Promise<void> {
    const driver = await this.getDriverInstance(repoId);
    return driver.closePullRequest(repoId, prId);
  }
}

const gitwrapper = new GitWrapper();
export default gitwrapper;
