<template>
  <SideMenuLayout :save-width="false" :is-small="true">
    <template #sideBar>
      <StepsProgressBar
        type-name="Playlist"
        :steps="progressBarSteps"
        :active-step-id="activeStepId"
        :steps-container="progressBarStepsContainer"
        :repos-statuses="reposStatuses"
        edit-mode
        :loading="loading"
        :disabled="playlistUnavailable"
        :mark-invalid-steps="markInvalidSteps"
        @select-step="selectStep"
        @add-unit="addStep"
        @remove-step="removeStep"
        @steps-sequence-update="updateStepsOrder"
        @edit-step="editStep"
        @container-name-updated="updatePlaylistName"
      >
        <template v-if="playlistUnavailable" #extra>
          <div class="steps-unavailable">Steps unavailable</div>
        </template>
      </StepsProgressBar>
    </template>
    <template #content>
      <TopMenuLayout :loading="loading" :should-lift-bottom-icons="isSwimmStep && isDoc">
        <template #topBar>
          <DummyRepoBanner v-if="!loading && isOnDummyRepoPage" />
          <TopBar :loading="loading" :show-workspace-breadcrumb="false">
            <DocumentationOptionsMenu
              :show-go-to-committed="!isNewDraft"
              :show-move-to-folder="!!playlistId"
              @committed-click="goToCommitted"
              @discard-click="handleDiscard"
              @change-folder="changeFolder"
            />
            <ShareButton entity="PLAYLIST" :repo-id="repoId" :entity-id="playlistId" />
            <BatchCommitButton
              v-if="repoId"
              :repo-id="repoId"
              :workspace-id="workspaceId"
              :branch="branch"
              v-tooltip="saveButtonTooltip"
            />
            <template #breadcrumbs>
              <DraftsSaveIndicator />
            </template>
          </TopBar>
        </template>
        <template v-if="playlistUnavailable" #content>
          <ContentErrorState type="playlist" :swimm-status="playlistStatus" :repo-id="repoId" />
        </template>
        <template v-else-if="!loading" #content>
          <section class="active-step">
            <div v-if="isIntro" class="edit-playlist-form content-padding-wrapper">
              <Tag class="tag" text="PLAYLIST" tag-style="empty-tag" />
              <TextField
                ref="playlistTitleElementRef"
                :model-value="playlist.name"
                @update:model-value="onPlaylistNameChanged"
                class="playlist-name data-hj-allow"
                placeholder="Untitled playlist"
                :maxlength="TITLE_MAX_LENGTH"
                :border="false"
                :class="{ highlighted: isTitleHighlighted }"
                headline
                focus-first
                required
              />
              <ResourceFolder
                :resource="{ ...playlist, id: playlistId }"
                :type="DocumentationTypes.PLAYLIST"
                :repo-id="repoId"
              />
              <MarkdownEditor
                class="playlist-markdown description"
                :markdown="description"
                :is-workspace="false"
                @markdown-changed="descriptionChanged"
                title-type="swimmEditDescription"
                data-testid="workspace-description"
                :repo-id="repoId"
                :workspace-id="workspaceId"
                :branch="branch"
                :playlist-id="playlistId || ''"
              />
            </div>
            <div v-else-if="isSummary" class="edit-playlist-form content-padding-wrapper">
              <SwText variant="headline1" component="h2" class="playlist-summary-title">Summary</SwText>
              <MarkdownEditor
                class="playlist-markdown"
                :markdown="summary"
                :is-workspace="false"
                @markdown-changed="summaryChanged"
                title-type="swimmEditSummary"
                data-testid="workspace-description"
                :repo-id="repoId"
                :workspace-id="workspaceId"
                :branch="branch"
                :playlist-id="playlistId || ''"
              />
            </div>
            <div v-else class="playlist-step-container">
              <div v-if="!isDoc" class="step-header">
                <ResourceHeadline
                  :resource-name="resourceHeadlineDetails.title"
                  :resource-type="resourceHeadlineDetails.type"
                  :resource-repo="activeSwimm.repoDetails"
                  :resource-creator="resourceHeadlineDetails.creator"
                  :repo-id="activeSwimm.repoId"
                  :resource-id="activeSwimm.id"
                />
              </div>
              <PlaylistStepResolver
                :step-index="currentStep"
                :step="activeSwimm"
                :key="activeSwimm.id"
                is-edit-mode
                @handle-step-saved="handleStepSaved"
              />
            </div>
          </section>
        </template>
      </TopMenuLayout>
      <AddItemsToSequenceModal
        v-if="!loading"
        :show-modal="shouldShowAddItemsModal"
        :swimms="swimmsToChoose"
        :already-selected-swimms="playlist.sequence"
        :repos="workspaceRepos"
        @close="shouldShowAddItemsModal = false"
        @done-selection="addSwimmsToPlaylist"
      />
      <EditLinkModal
        v-if="!loading && selectedLink"
        :show-modal="shouldShowExternalLinkModal"
        :link="selectedLink.link"
        :is-new="selectedLink.isNew"
        @done-edit-link="saveLink"
        @close="shouldShowExternalLinkModal = false"
      />
      <MoveFolderModal
        v-if="showMoveFolderModal"
        :show="showMoveFolderModal"
        :repo-id="repoId"
        @close="showMoveFolderModal = false"
        :items="resourceToMove"
      />
    </template>
  </SideMenuLayout>
  <RepoSettingsModal
    :show="showRepoSettingsModal"
    :repo-id="repoId"
    origin="Edit Playlist"
    :initial-tab-code="integrationSettingsTabs.GENERATIVE_AI"
    @close="showRepoSettingsModal = false"
  />
</template>

<script setup lang="ts">
import { useAnalytics } from '@/common/composables/useAnalytics';
import {
  ApplicabilityStatus,
  TITLE_MAX_LENGTH,
  config,
  decodeString,
  getGitProviderIconName,
  getLoggerNew,
  integrationSettingsTabs,
  isRepoIdDummyRepo,
  pageEvents,
  productEvents,
} from '@swimm/shared';
import { useStore } from 'vuex';
import StepsProgressBar from '@/common/components/organisms/StepsProgressBar.vue';
import TopBar from '@/common/components/TopBar/TopBar.vue';
import TopMenuLayout from '@/common/layouts/TopMenuLayout.vue';
import SideMenuLayout from '@/common/layouts/SideMenuLayout.vue';
import AddItemsToSequenceModal from '@/common/components/modals/AddItemsToSequenceModal.vue';
import MarkdownEditor from '@/modules/editor/components/MarkdownEditor.vue';
import PlaylistStepResolver from '@/common/components/Resolver/PlaylistStepResolver.vue';
import { useScroll } from '@swimm/editor';
import { SwText, Tag, TextField } from '@swimm/ui';
import EditLinkModal from '@/common/components/modals/EditLinkModal.vue';
import swal from 'sweetalert';
import { getResourceHeadlineType } from '@/common/utils/playlist-utils';
import ContentErrorState from '@/common/components/organisms/unavailable-doc-states/ContentErrorState.vue';
import BatchCommitButton from '@/modules/batch-commit/components/BatchCommitButton.vue';
import { usePageTitle } from '@/common/composables/pageTitle';
import { storeToRefs } from 'pinia';
import { useRepoSwitcher } from '@/common/composables/repoSwitcher';
import { useInitData } from '../composables/initData';
import { useRouting } from '../composables/routing';
import { useNavigate } from '../composables/navigate';
import { usePlaylist } from '@/modules/playlists/composables/playlist';
import { useEditPlaylistStore } from '@/modules/playlists/store/edit-playlist';
import { measurePerformance } from '../utils/sentry-measurements';
import type { PlaylistSequenceStep } from '@/modules/playlists/types';
import { type PlaylistProgressBarStep, PlaylistSequenceStepTypes } from '@/modules/playlists/types';
import {
  ComputedRef,
  computed,
  defineAsyncComponent,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  provide,
  ref,
  watch,
} from 'vue';
import type { RepoLoadingStatus } from '@/modules/playlists/store';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import DummyRepoBanner from '@/modules/demo/components/DummyRepoBanner.vue';
import { useDemoStore } from '@/modules/demo/demo';
import ShareButton from '@/common/components/TopBar/ShareButton.vue';
import DraftsSaveIndicator from '@/modules/drafts3/components/DraftsSaveIndicator.vue';
import DocumentationOptionsMenu from '@/common/components/organisms/DocumentationOptionsMenu.vue';
import ResourceFolder from '@/modules/folders/components/ResourceFolder.vue';
import { DocumentationTypes } from '@/common/consts';
import ResourceHeadline from '@/common/components/atoms/ResourceHeadline.vue';
import MoveFolderModal from '@/modules/folders/components/MoveFolderModal.vue';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { computeDraftDisplayName } from '@/common/utils/draft-utils';
import { DraftContent } from '@/modules/drafts3/db';
import { cloneDeep } from 'lodash-es';

const logger = getLoggerNew(__modulename);

const props = defineProps({
  repoId: {
    type: String,
    required: true,
  },
  workspaceId: {
    type: String,
    required: true,
  },
  branch: {
    type: String,
    required: true,
  },
});

onBeforeRouteUpdate((to, from, next) => {
  if (to.path === '/404') {
    next();
    return;
  }
  scrollToElementTop('main');
  next();
});

onBeforeUnmount(() => {
  resetStoreState();
});
const RepoSettingsModal = defineAsyncComponent(() => import('@/modules/repo/settings/RepoSettingsModal.vue'));

const store = useStore();
const route = useRoute();
const router = useRouter();
const analytics = useAnalytics();
const drafts3Store = useDrafts3Store();
const { connectToRepo } = useRepoSwitcher();
const { setPlaylistData, setWorkspaceData } = useInitData();
const { assertRouting, routeBackFromDoc, getCurrentOrDefaultBranch } = useRouting();
const { getRepoPath } = useNavigate();
const { playlistId, parseDBPlaylist, parseSequenceStepsForList } = usePlaylist();
const editPlaylistStore = useEditPlaylistStore();
const { playlist, currentStep, hasChanged, selectedLink, shouldShowExternalLinkModal, shouldShowAddItemsModal } =
  storeToRefs(editPlaylistStore);
const { saveLink, addStep, editStep, selectStep, removeStep, addSwimmsToPlaylist, resetStoreState } = editPlaylistStore;
const { pageTitle } = usePageTitle();
const demoStore = useDemoStore();
const { isOnDummyRepoPage } = storeToRefs(demoStore);

const { scrollToElementTop } = useScroll();

const db_getPlaylist = computed(() => store.getters['database/db_getPlaylist']);
const db_getSwimm = computed(() => store.getters['database/db_getSwimm']);
const db_getRepoMetadata = computed(() => store.getters['database/db_getRepoMetadata']);
const db_getWorkspaceResources = computed(() => store.getters['database/db_getWorkspaceResources']);
const fs_isUnitInvalid = computed(() => store.getters['filesystem/fs_isUnitInvalid']);
const fs_getPlaylistFromLocalRepo = computed(() => store.getters['filesystem/fs_getPlaylistFromLocalRepo']);
const fs_isUnitFileInRepoPath = computed(() => store.getters['filesystem/fs_isUnitFileInRepoPath']);
const fs_isPlaylistExist = computed(() => store.getters['filesystem/fs_isPlaylistExist']);

const fetchAllWorkspaceRepos = (args?) => store.dispatch('database/fetchAllWorkspaceRepos', args);
const fetchRepoChildren = (args?) => store.dispatch('database/fetchRepoChildren', args);
const fetchRepository = (args?) => store.dispatch('database/fetchRepository', args);
const getRepoSwmsLists = (args?) => store.dispatch('filesystem/getRepoSwmsLists', args);
const setOnlyDBPlaylistToFilesystem = (args?) => store.dispatch('filesystem/setOnlyDBPlaylistToFilesystem', args);

provide('openSettingsCallback', () => {
  showRepoSettingsModal.value = true;
});

const loading = ref(true);
const originalTitle = ref('');
const playlistUnavailable = ref(false);
const isNewPlaylist = ref(!playlistId.value);
const debouncedDraftSaver = ref(null);
const isDraft = ref(false);
const reposStatuses = ref({} as RepoLoadingStatus);
const isTitleHighlighted = ref(false);
const playlistTitleElementRef = ref(null);
const markInvalidSteps = ref(false);
const showMoveFolderModal = ref(false);
const showRepoSettingsModal = ref(false);
const resourceToMove = ref();
const loadingRepos = ref(true);

watch(
  () => props.workspaceId,
  async () => {
    loadingRepos.value = true;
    await setWorkspaceData(props.workspaceId);
    loadingRepos.value = false;
  },
  { immediate: true }
);

import { SwmCellType } from '@swimm/shared';

const description = computed<{ type: SwmCellType.Text; text: string }[]>(() => {
  return [{ type: SwmCellType.Text, text: playlist.value.description ? playlist.value.description : '' }];
});
const summary = computed<{ type: SwmCellType.Text; text: string }[]>(() => {
  return [{ type: SwmCellType.Text, text: playlist.value.summary ? playlist.value.summary : '' }];
});
const isSwimmStep = computed(() => {
  return !isIntro.value && !isSummary.value;
});
const isSummary = computed(() => {
  return currentStep.value === 'summary';
});
const isIntro = computed(() => {
  return currentStep.value === 'intro';
});
const isDoc = computed(() => {
  return activeSwimm.value && activeSwimm.value.type === 'unit';
});
const isEmptyPlaylist = computed(() => {
  return (
    !playlist.value.name?.trim().length &&
    !playlist.value.description?.trim().length &&
    !playlist.value.summary?.trim().length &&
    !playlist.value.sequence?.length
  );
});
const activeSwimm = computed(() => {
  const stepIndex = currentStep.value;
  const swimmInPlaylist = playlist.value.sequence[stepIndex];
  if (isSwimmStep.value && (stepIndex || stepIndex === '0') && !!swimmInPlaylist) {
    const activeStepRepoMetadata = db_getRepoMetadata.value(swimmInPlaylist.repoId);
    const repoDetails = activeStepRepoMetadata
      ? {
          id: swimmInPlaylist.repoId,
          name: activeStepRepoMetadata.name,
          imgSrc: activeStepRepoMetadata.logo,
          icon: activeStepRepoMetadata.provider && getGitProviderIconName(activeStepRepoMetadata.provider),
        }
      : null;

    if (swimmInPlaylist.type === PlaylistSequenceStepTypes.EXTERNAL_LINK) {
      return { ...swimmInPlaylist, repoDetails };
    } else if (swimmInPlaylist.type === 'playlist') {
      return {
        ...swimmInPlaylist,
        repoDetails,
        ...db_getPlaylist.value(swimmInPlaylist.repoId, swimmInPlaylist.id),
      };
    } else {
      return {
        ...swimmInPlaylist,
        repoDetails,
        ...db_getSwimm.value(swimmInPlaylist.repoId, swimmInPlaylist.id),
      };
    }
  }
  return { id: currentStep.value };
});

const isNewDraft = computed(() => {
  const draft = drafts3Store.drafts?.get(playlistId.value);
  return !!draft?.isNew;
});

const playlistStatus: ComputedRef<'Not found' | null> = computed(() => {
  if (playlistUnavailable.value) {
    return 'Not found';
  }

  return null;
});

const activeStepId = computed(() => {
  return activeSwimm.value.id || '';
});

const resourceHeadlineDetails = computed(() => {
  return {
    title: activeSwimm.value.name,
    type: getResourceHeadlineType({
      isSwimmStep: isSwimmStep.value,
      activeStep: activeSwimm.value,
      isSummary: isSummary.value,
    }),
    creator: activeSwimm.value.creator_name,
  };
});

const progressBarSteps = computed<PlaylistProgressBarStep[]>(() => {
  if (loading.value || playlistUnavailable.value) {
    return [];
  }

  return parseSequenceStepsForList({
    playlist: { ...playlist.value, id: playlistId.value },
    isEdit: true,
  });
});

const progressBarStepsContainer = computed(() => {
  return { name: playlist.value.name, iconName: 'playlist', type: 'Playlist' };
});

const swimms = computed(() => {
  const swimms = [];
  const workspaceResources: { id: string; repoId: string }[] = Object.values(
    db_getWorkspaceResources.value(props.workspaceId)
  );
  for (const repo of workspaceRepos.value) {
    // Get repo available resources that are both in db and filesystem (current branch)
    // add repoId to swimm files
    const {
      metadata: { id: workspaceRepoId, provider: repoProvider },
    } = repo;
    const swimmsOnDbAndFilesystem = workspaceResources
      .filter((swimm) => swimm.repoId === workspaceRepoId && fs_isUnitFileInRepoPath.value(swimm.id, workspaceRepoId))
      .map((swimm) => ({ ...swimm, repoId: workspaceRepoId, repoProvider }));
    swimms.push(...swimmsOnDbAndFilesystem);
  }

  const draftItems = [...drafts3Store.drafts.values()]
    // only drafts of new docs are here
    .filter((draftItem) => draftItem.isNew)
    .map((draftItem) => ({
      name: drafts3Store.getDraftTitle(draftItem),
      draftId: draftItem.id,
      repoId: props.repoId,
      type: draftItem.type === 'doc' ? 'unit' : config.SWIMM_FILE_TYPES.PLAYLIST,
      play_mode: config.UNIT_PLAY_MODES.WALKTHROUGH,
    }));
  swimms.push(...draftItems);

  return swimms.map((swimm) => ({
    ...swimm,
    id: swimm.draftId ? swimm.draftId : swimm.id,
    isDraftLink: Boolean(swimm.draftId),
  }));
});

const swimmsToChoose = computed(() => {
  return swimms.value.filter((resource) => resource.id !== playlistId.value && !isCurrentDraftPlaylist(resource.id));
});

const workspaceRepos = computed(() => {
  const repos = store.getters['database/db_getWorkspaceRepos'](props.workspaceId);
  if (isOnDummyRepoPage.value) {
    return repos.filter((repo) => isRepoIdDummyRepo(repo.metadata.id));
  }
  return repos.filter((repo) => !!repo && !!repo.metadata);
});

const saveButtonTooltip = computed(() => {
  if (!playlist.value.name || playlist.value.name.trim().length === 0) {
    return 'Playlist name is required';
  }
  if (hasInvalidSteps.value) {
    return 'Playlist must not have invalid steps, make sure you have no docs pending for PR';
  }
  return '';
});

const hasInvalidSteps = computed(() => {
  return playlist.value.sequence.some((step) => {
    const stepStatus = getStepStatus(step);
    const invalidStatusTypes: string[] = [ApplicabilityStatus.Unavailable, ApplicabilityStatus.Invalid];
    return step.type !== PlaylistSequenceStepTypes.EXTERNAL_LINK && invalidStatusTypes.includes(stepStatus);
  });
});

onBeforeMount(async () => {
  await loadEditPlaylistPage();

  watch(
    playlist,
    (newValue, oldValue) => {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        if (!isEmptyPlaylist.value) {
          handleDraftSave();
          hasChanged.value = true;
        }
      }
    },
    { deep: true }
  );
  watch(
    playlist,
    () => {
      pageTitle.value = `${isNewPlaylist.value ? 'New playlist' : 'Edit'}: ${computeDraftDisplayName(
        playlist.value?.name
      )}`;
    },
    { immediate: true, deep: true }
  );
});

watch(
  () => props.branch,
  async (newBranch, oldBranch) => {
    if (oldBranch) {
      // reload after branch change.
      await loadEditPlaylistPage();
    }
  }
);

async function loadEditPlaylistPage() {
  await measurePerformance({ name: 'edit-playlist-init' }, async () => {
    const assertResult = await assertRouting();
    if (!assertResult) {
      return;
    }

    // swmdv3 - check if there are any drafts to determine if the playlist it new
    const draft3 = drafts3Store.drafts?.get(playlistId.value);
    if (draft3) {
      isNewPlaylist.value = draft3.isNew || false;
    }

    if (isNewPlaylist.value) {
      analytics.pageVisit(pageEvents.NEW_PLAYLIST, {
        'Workspace ID': props.workspaceId,
        'Repo ID': props.repoId,
      });
    } else {
      analytics.pageVisit(pageEvents.EDIT_PLAYLIST, {
        'Workspace ID': props.workspaceId,
        'Repo ID': props.repoId,
        'Playlist ID': playlistId.value,
      });
    }

    if (!isNewPlaylist.value) {
      // Set repo status to available
      reposStatuses.value[props.repoId] = { loading: false, available: true };

      // Load playlist
      await setPlaylistData({
        playlistId: playlistId.value,
        repoId: props.repoId,
      });
      // If the playlist not in the filesystem then get it from db
      if (!fs_isPlaylistExist.value(playlistId.value, props.repoId)) {
        const dbPlaylist = await parseDBPlaylist();
        // TODO: handle unavailable playlist
        if (!dbPlaylist) {
          playlistUnavailable.value = true;
          loading.value = false;
          return;
        }
        setOnlyDBPlaylistToFilesystem({
          playlistId: playlistId.value,
          repoId: props.repoId,
          content: { ...dbPlaylist },
        });
      }
      const fs_playlist = fs_getPlaylistFromLocalRepo.value(playlistId.value, props.repoId);

      if (draft3 == null) {
        playlist.value = { ...fs_playlist };
        originalTitle.value = fs_playlist.name;
      } else {
        logger.debug(`Found draft for Playlist: ${playlistId.value}`);
        originalTitle.value = draft3.originalTitle;
        await loadCurrentPlaylistDraft();
      }
      if (route.query.step) {
        currentStep.value = route.query.step as string;
        const { step: _step, ...restOfQuery } = route.query;
        await router.replace({ query: restOfQuery });
      }
    } else {
      // Load new unit draft
      await loadPlaylistDraft(playlistId.value);
    }
    await loadWorkspaceRepos();
    // PERFORMANCE: Loading all docs from all repos can take a very, very long time - over 15s in the Swimm workspace.
    //              We therefore do this in the background.
    loadWorkspaceRepoChildrenInBackground().then();

    if (route.query.fix) {
      nextTick(() => {
        handleFixPlaylistRequest();
      });
    }

    loading.value = false;
  });
}

async function handleFixPlaylistRequest(fromQueryParam = true) {
  if (playlist.value.name?.trim() === '') {
    await focusTitle({ highlight: true });
  } else if (hasInvalidSteps.value) {
    if (fromQueryParam) {
      // When starting with a 'fix' query param - mark the invalid steps automatically.
      markInvalidSteps.value = true;
    } else {
      // When clicking on fix for the current playlist - blink, hiding at first for 0.5 sec because the steps might be alreay marked from the 'fix' query param.
      markInvalidSteps.value = false;
      await nextTick(() => {
        setTimeout(() => {
          markInvalidSteps.value = true;
        }, 500);
      });
      nextTick(() => {
        setTimeout(() => {
          markInvalidSteps.value = false;
        }, 2500);
      });
    }
  }
}

async function focusTitle({ highlight = false } = {}) {
  if (isIntro.value !== true) {
    await selectStep({ id: 'intro' });
  }
  playlistTitleElementRef.value?.focus();
  if (highlight) {
    isTitleHighlighted.value = true;
    setTimeout(() => {
      isTitleHighlighted.value = false;
    }, 1000);
  }
}

function descriptionChanged(data) {
  playlist.value = { ...playlist.value, description: data[0].text };
}
function summaryChanged(data) {
  playlist.value = { ...playlist.value, summary: data[0].text };
}
function onPlaylistNameChanged(newName) {
  playlist.value = { ...playlist.value, name: newName };
}
function getStepStatus(resource: PlaylistSequenceStep) {
  // On edit we don't care about unit prograss status.
  if (!fs_isUnitFileInRepoPath.value(resource.id, resource.repoId)) {
    return ApplicabilityStatus.Unavailable;
  }
  if (resource.type === PlaylistSequenceStepTypes.SWIMM && fs_isUnitInvalid.value(resource.id, resource.repoId)) {
    return ApplicabilityStatus.Invalid;
  }
  return config.SWIMMER_STATUSES.NOT_STARTED;
}

function isCurrentDraftPlaylist(resourceId) {
  if (!route.query.playlistDraft) {
    return false;
  }
  return isNewPlaylist.value && decodeString(route.query.playlistDraft as string) === resourceId;
}

function updateStepsOrder(steps: PlaylistSequenceStep[]) {
  playlist.value = { ...playlist.value, sequence: steps };
}

async function loadPlaylistDraft(draftId) {
  logger.debug(`Loading playlist draft, draftId: ${draftId}`);

  const draftLoadResult = drafts3Store.drafts.get(playlistId.value);
  if (draftLoadResult) {
    playlist.value = { ...playlist.value, ...draftLoadResult.content };
    originalTitle.value = draftLoadResult.originalTitle;
  }

  isDraft.value = true;
  // Manually set timers on + mark playlist as changed when loading draft
  hasChanged.value = true;
}

async function loadCurrentPlaylistDraft() {
  await loadPlaylistDraft(playlistId.value);
}
function updatePlaylistName(name) {
  playlist.value.name = name;
}
async function loadWorkspaceRepos() {
  await fetchAllWorkspaceRepos(props.workspaceId);
  for (const repo of workspaceRepos.value) {
    reposStatuses.value[repo.metadata.id] = {
      available: false,
      loading: true,
    };
  }
}
async function loadWorkspaceRepoChildrenInBackground() {
  for (const repo of workspaceRepos.value) {
    await connectToRepo({ repoId: repo.metadata.id, alertError: false });
    // Filesystem
    await getRepoSwmsLists({
      repoId: repo.metadata.id,
      branch: await getCurrentOrDefaultBranch(repo.metadata.id),
    });
    // Database
    fetchRepository({ repoId: repo.metadata.id });
    await fetchRepoChildren({ repoId: repo.metadata.id, children: ['swimms', 'playlists'] });
    reposStatuses.value[repo.metadata.id] = {
      available: true,
      loading: false,
    };
  }
  // Set repos of steps not in workspace as unavailable
  for (const step of playlist.value.sequence) {
    if (!(step.repoId in reposStatuses.value)) {
      reposStatuses.value[step.repoId] = {
        available: false,
        loading: false,
      };
    }
  }
}
async function handleDraftSave() {
  if (props.repoId == null) {
    return;
  }
  drafts3Store.saveEndTime = null;

  const playlistToSave = {
    content: cloneDeep(playlist.value),
    type: 'playlist',
  } as DraftContent;
  drafts3Store.saveDraft(playlistId.value, playlistToSave, { originalTitle: originalTitle.value });
}
async function handleStepSaved() {
  // The playlist is being loaded again for a case that one of the step names has been changed and the new
  // name should be displayed in the steps progress bar now
  await loadCurrentPlaylistDraft();
}
async function handleDiscard() {
  // Don't save more drafts while asking user to delete draft
  clearTimeout(debouncedDraftSaver.value);

  const shouldDelete = await swal({
    title: 'Are you sure you want to discard all changes made to this playlist?',
    text: 'This action will only remove edits made to this playlist. Individual docs will still be available in the repo page',
    dangerMode: true,
    buttons: {
      cancel: true,
      confirm: { text: 'Discard Changes', visible: true },
    },
  });
  if (!shouldDelete) {
    return;
  }

  await drafts3Store.discardDraft(playlistId.value);
  const analyticsData = {
    'Document ID': playlistId.value ? playlistId.value : 'No Committed Version',
  };
  analytics.track(productEvents.DISCARD_DOC_DRAFT, analyticsData);
  return routeBackFromDoc();
}
function getPlaylistRoute() {
  if (playlistId.value) {
    return `${getRepoPath(props.repoId, props.branch)}/playlists/${playlistId.value}`;
  }

  return null;
}
function goToCommitted() {
  const playlistRoute = getPlaylistRoute();
  if (playlistRoute) {
    router.push(playlistRoute);
  }
}

function changeFolder() {
  showMoveFolderModal.value = true;

  const playlistDraft = drafts3Store.drafts.get(playlistId.value);
  const playlist: { id?: string; draftId?: string; isNew?: boolean; folderId?: string } | undefined =
    playlistDraft?.isNew ? playlistDraft : db_getPlaylist.value(props.repoId, playlistId.value);

  resourceToMove.value = [{ ...playlist, documentationType: DocumentationTypes.PLAYLIST }];
}
</script>

<style scoped lang="postcss">
.active-step {
  padding: inherit;
  height: 100%;
  overflow-y: auto;
}

.step-header {
  padding-top: var(--space-xl);
  width: 100%;
  box-sizing: border-box;
}

.edit-playlist-form {
  margin: auto;
  margin-top: 60px;
  max-width: var(--narrow-content-width);
}

.description {
  margin-top: 50px;
}

.step-progress :deep(.steps-unavailable) {
  padding: 30px 0;
  text-align: center;
  color: var(--text-color-secondary);
}

.icon-action {
  padding: var(--space-xs);
}

.edit-playlist-form :deep(.ProseMirror .is-editor-empty::after) {
  content: attr(data-placeholder);
  position: absolute;
  top: 10px;
  color: var(--text-color-disable);
  pointer-events: none;
}

.playlist-name {
  font-size: var(--fontsize-xl);
  font-weight: bold;
  border: none;
  background: transparent;
  outline: none;
  position: relative;
  align-items: center;
  width: 100%;
  max-width: var(--narrow-content-width);
  word-break: break-word;
}

.tag {
  color: var(--text-color-primary);
}

.playlist-summary-title {
  font-size: var(--fontsize-xl);
  margin-bottom: var(--space-md);
}

.playlist-markdown {
  &:deep(.editor-content) {
    word-break: break-word;
  }
}

.playlist-name.highlighted :deep(input::placeholder) {
  color: var(--text-color-error);
  opacity: 0.6;
}

.playlist-step-container {
  height: 100%;
  overflow-y: auto;
}
</style>
