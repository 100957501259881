<script lang="ts">
import { type Spacing } from '../../types';

export const LoadingVariants = ['primary', 'secondary'] as const;
export type LoadingVariantsType = (typeof LoadingVariants)[number];

export const LoadingSizes = ['xsmall', 'small', 'medium', 'large'] as const;
export type LoadingSizesType = Extract<Spacing, (typeof LoadingSizes)[number]>;
</script>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    /**
     * The variant of loader.
     */
    variant?: LoadingVariantsType;
    /**
     * The size of the loader.
     */
    size?: LoadingSizesType;
  }>(),
  {
    variant: 'primary',
    size: 'medium',
  }
);

const computedClasses = computed(() => ({
  [`loading--${props.variant}`]: true,
  [`loading--${props.size}`]: props.size,
}));
</script>

<template>
  <div class="loading" :class="computedClasses" role="alert" aria-busy="true" />
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.loading {
  $self: &;

  @include defaults;

  background-repeat: no-repeat;
  background-position: center;

  &--primary {
    background-image: url('../../assets/images/loading.gif');
  }

  &--secondary {
    background-image: url('../../assets/images/secondary-loading.gif');
  }

  &--large {
    background-size: 150px;
    min-height: 150px;
    min-width: 150px;
  }

  &--medium {
    background-size: 70px;
    min-height: 70px;
    min-width: 70px;
  }

  &--small {
    background-size: 30px;
    min-height: 30px;
    min-width: 30px;
  }

  &--xsmall {
    background-size: 20px;
    min-height: 20px;
    min-width: 20px;
  }
}
</style>
