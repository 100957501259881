<script setup lang="ts">
import Ribbon from '@/common/components/atoms/Ribbon.vue';
import { BaseButton } from '@swimm/reefui';

defineProps<{
  canEdit: boolean;
}>();

const emit = defineEmits<{
  (e: 'apply');
  (e: 'edit');
}>();
</script>

<template>
  <Ribbon
    ribbon-title="Referenced Code Updated"
    ribbon-description="Swimm will automatically update referenced code in this document on your next commit."
  >
    <template v-if="canEdit">
      <BaseButton variant="primary" @click="emit('apply')"> Apply Update </BaseButton>
    </template>
    <template v-else>
      <BaseButton variant="primary" @click="emit('edit')"> Edit Document </BaseButton>
    </template>
  </Ribbon>
</template>
