<template>
  <SwText variant="body-S" class="item">
    <div class="node-container" @click="$emit('node-clicked')">
      <Icon class="node-icon" v-show="iconName && isFirst" :name="iconName" />
      <ReviewAutosyncNodeText
        :is-first="isFirst"
        :node-text="name"
        :is-path="isPath"
        :is-generic-text="isGenericText"
        :prefix="prefix"
      />
    </div>
    <div
      v-show="isFirst"
      @click="handleAction"
      class="action-icon-container"
      :class="{ 'action-hover': !isActionCalled }"
    >
      <Icon v-if="!isActionCalled" :name="isAutosyncable ? 'check' : 'trash-outline'" class="action-icon" />
      <Loader v-else secondary class="loader" />
    </div>
  </SwText>
</template>

<script setup lang="ts">
import { Icon, Loader } from '@swimm/ui';
import { computed, ref } from 'vue';
import ReviewAutosyncNodeText from './ReviewAutosyncNodeText.vue';
import { NodeTypes } from '@swimm/editor';
import { SwText } from '@swimm/ui';
interface ReviewAutosyncNodeProps {
  id: string;
  prefix: string;
  name: string;
  type: string;
  iconName: string;
  isAutosyncable: boolean;
  isFirst: boolean;
}
const props = withDefaults(defineProps<ReviewAutosyncNodeProps>(), {
  isFirst: false,
});

const isActionCalled = ref(false);

function handleAction() {
  isActionCalled.value = true;
  emit('action');
}
const emit = defineEmits<{
  (event: 'node-clicked'): void;
  (event: 'action'): void;
}>();

const isPath = computed(() => props.type === NodeTypes.PATH);

const isGenericText = computed(() => props.type === NodeTypes.GENERIC_TEXT);
</script>

<style scoped lang="postcss">
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  max-height: 30px;
  opacity: 1;
}

.node-container {
  display: flex;
  width: 100%;
  align-items: center;
  padding: var(--space-xs);
  gap: var(--space-xs);
}

.node-container:hover {
  background-color: var(--color-hover);
  border-radius: 4px;
}

.sync {
  color: var(--text-color-magic-strong);
}

.node-icon {
  color: var(--color-invert);
  font-size: var(--body-L);
  padding: 0px;
}

.action-icon {
  padding: 0;
  font-size: var(--body-L);
  border-radius: var(--space-xs);
}

.action-icon-container {
  border-radius: var(--space-xs);
  padding: var(--space-xs);

  .loader.loader {
    --loader-size: 24px;
  }
}

.action-hover:hover {
  background-color: var(--color-hover);
}
</style>
