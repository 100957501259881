<script setup lang="ts">
import { watchEffect } from 'vue';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { useNavigate } from '@/common/composables/navigate';
import { BaseLoading } from '@swimm/reefui';

const props = defineProps({
  workspaceId: { type: String, required: true },
  repoId: { type: String, required: true },
  branch: { type: String, required: true },
  folderId: { type: String, default: null },
});

const drafts3Store = useDrafts3Store();
const { navigateToPageAndTerminateWorker, getRepoPath } = useNavigate();

// TODO This should be handled at a higher level by some other store...
watchEffect(() => {
  drafts3Store.workspaceId = props.workspaceId;
});
watchEffect(() => {
  drafts3Store.repoId = props.repoId;
});
watchEffect(() => {
  drafts3Store.branch = props.branch;
});

drafts3Store.newPlaylist().then((playlistId) => {
  if (props.folderId != null) {
    drafts3Store.updateAttrs(playlistId, { folderId: props.folderId });
  }

  // TODO Figure out if we can call router.push with a route props object instead of
  // navigateToPageAndTerminateWorker, where the worker is handled transparently
  // by the router or layout component.
  return navigateToPageAndTerminateWorker({
    newRoute: `${getRepoPath()}/playlists/${playlistId}/edit`,
    replace: true,
  });
});
</script>

<template>
  <div>
    <BaseLoading variant="primary" size="large" />
  </div>
</template>
