<script setup>
import { SwModal } from '@swimm/ui';
import InstallSlackApp from '@/modules/slack-app/components/InstallSlackApp.vue';

defineProps({
  show: { type: Boolean, required: true },
  repoId: { type: String, required: true },
  workspaceId: { type: String, required: true },
  description: { type: String, required: true },
  origin: { type: String, default: '' },
});
const emit = defineEmits(['close']);
</script>

<template>
  <SwModal :show-modal="show" :header-border="false" :padded="false" @close="emit('close')">
    <div class="content">
      <InstallSlackApp :repo-id="repoId" :workspace-id="workspaceId" :description="description" :origin="origin" />
    </div>
  </SwModal>
</template>

<style scoped lang="postcss">
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-xl);
}
</style>
