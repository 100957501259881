<script setup lang="ts">
import { BaseBlock, BaseButton, BaseProse } from '@swimm/reefui';

const props = defineProps<{
  workspaceId: string;
  repoId: string;
  isWorkspaceAdmin: boolean;
  isRules?: boolean;
}>();

const emit = defineEmits<{
  (e: 'clicked-repo-settings'): void;
}>();
</script>

<template>
  <BaseBlock v-if="props.isWorkspaceAdmin" variant="warning"
    ><BaseProse variant="warning" size="small"
      >Creating {{ isRules ? 'rules' : 'docs' }} using AI is disabled for this repo, enable it in
      <BaseButton variant="link" @click="emit('clicked-repo-settings')">repo settings</BaseButton>.
    </BaseProse>
  </BaseBlock>
  <BaseBlock v-else variant="warning">
    <BaseProse variant="warning"
      >Creating {{ isRules ? 'rules' : 'docs' }} using AI is disabled for this repo. Ask an Admin to enable it from the
      repo settings</BaseProse
    >
  </BaseBlock>
</template>

<style scoped lang="postcss">
.link {
  cursor: pointer;
  color: var(--text-color-link);
}
</style>
