import trim from 'lodash-es/trim';
import { dedent } from 'ts-dedent';
import { ObjectInfo, RawProcessFile } from './source-types';
import { AdditionalInfo, ObjectRef, ValueObject } from './output-types';
import { LineWords, getLoggerNew, splitLineByWords } from '@swimm/shared';
import { getFullSuggestionForQuery } from '@swimm/editor';
import type { Language, SyntaxNode, Tree } from '@/common/tree-sitter';
import { captureStringValue, captureValue } from './FileParser';

const logger = getLoggerNew('ppg/source-json-parser.ts');

export const EMPTY_VALUE_OBJECT: ValueObject = {
  line: 1,
  wordStartIndex: 0,
  wordEndIndex: 10,
  lineText: '',
  value: '',
};
export const DESCRIPTION_REGEX = /@description[=:]\s?\\*"?([^[\]\r\n,"\\]+)\\*"?/;
export const NEW_LINE_REGEX = /\r?\n/;

export function escapeRegexValue(value: string) {
  return value.replace(/[.*+?^${}()|[\]\\"]/g, '\\$&');
}

/**
 * Find the line index of a key-value pair in a file
 * @param fileTextLines - the original file content split by lines
 * @param key - the json key to search for
 * @param value - the value to search for
 * @param startAt - used to reduce false matches by starting a search passed a certain line (an attempt to avoid falsely identifying to lines with similar value)
 */
export function getLineIndex(fileTextLines: string[], key: string, value: string, startAt = -1) {
  // Values that have new lines are hard to capture with regex.
  // Splitting here on the first line as a good enough approximation of the value's identity
  // Then escaping special regex characters so the value match will take the actual character
  const escapedValue = value && escapeRegexValue(value.split(NEW_LINE_REGEX)[0]);

  // The regex will match the key and the value to find the file line they came from (vital for creating smart tokens later)
  const regExp = new RegExp(`["']${key}["']\\s?:\\s?["']?${String.raw`${escapedValue}`}`);

  return fileTextLines.findIndex((line, index) => {
    // Replacing \" with " is a hack for files where they decided to surround the description with escaped quotes - which fails the regex
    const match = line.replace(/\\{1}/g, '').match(regExp);
    return match != null && index > startAt;
  });
}

export function computeValueObjectWithEndIndex(
  lineText: string,
  lineIndex: number,
  wordIndex: number,
  value: string
): ValueObject {
  const completeValueData = getFullSuggestionForQuery(
    value,
    {
      token: value.split(/\s/)[0], // The function uses the first word to get the full token
      position: { path: '', line: lineIndex, wordStart: wordIndex, wordEnd: wordIndex },
      lineData: lineText,
      repoId: '',
      static: false,
    },
    { exactMatch: true }
  );

  return {
    line: completeValueData.position.line,
    wordStartIndex: completeValueData.position.wordStart,
    wordEndIndex: completeValueData.position.wordEnd,
    lineText: completeValueData.lineData,
    value: completeValueData.token,
  };
}

/**
 * Find the word index of a value in a line
 * We are matching the beginning of the value with a matching word in the line
 * used for composing smart tokens later
 * @param textLine
 * @param value
 */
export function getWordIndex(textLine: string, value: string) {
  const valueWords = splitLineByWords(value);
  const lineWords = splitLineByWords(textLine);
  return lineWords.reduce((acc, word, index) => {
    if (acc > -1) {
      return acc;
    }
    if (valueWords[0] === word) {
      if (
        valueWords
          .slice(1)
          .map((followingWord, i) => followingWord === lineWords[index + i + 1])
          .every(Boolean)
      ) {
        return index;
      }
    }
    return -1;
  }, -1);
}

/**
 * Compose a value object from a key-value pair in a file
 * Used to identify this value as a smart token
 * @param fileTextLines
 * @param key
 * @param value
 * @param startAt - used to reduce false matches by starting a search passed a certain line (an attempt to avoid falsely identifying to lines with similar value)
 * @param lineIndex - used to skip the search and directly use the line index
 */
export function _composeValueObject(
  fileTextLines: string[],
  key: string,
  value: string,
  startAt = -1,
  lineIndex = -1
): ValueObject {
  if (!value) {
    return { ...EMPTY_VALUE_OBJECT, lineText: key, value: `Missing value for ${key}` };
  }

  const line = lineIndex > -1 ? lineIndex : getLineIndex(fileTextLines, key, value, startAt);

  if (line === -1) {
    return { ...EMPTY_VALUE_OBJECT, lineText: key, value: `Missing value for ${key}` };
  }

  // Replacing \" with " is a hack for files where they decided to surround the description with escaped quotes - which fails the search function
  const lineText = fileTextLines[line].replace(/(\\{1})/g, '');

  const wordIndex = getWordIndex(lineText, value.split(NEW_LINE_REGEX)[0]);
  // multi line values are hard to match with the file's stringified version
  // we are taking the first line out of the value to match it with the file's line as a good enough approximation of the token
  const valueObject = computeValueObjectWithEndIndex(lineText, line + 1, wordIndex, value.split(NEW_LINE_REGEX)[0]);

  // Place the original line data into the token info - so autosync properly matches it
  return { ...valueObject, lineText: fileTextLines[line] };
}

export function composeValueObject(fileTextLines: string[], node: SyntaxNode, key: string): ValueObject {
  if (!node || !node.text) {
    return { ...EMPTY_VALUE_OBJECT, lineText: key, value: `Missing value for ${key}` };
  }

  const relevantLine = fileTextLines[node.startPosition.row];
  const lineWords: LineWords = new LineWords(relevantLine);

  // When we query tree-sitter for string instead of string_content (to avoid certain types of values being cut off)
  // we get the value surrounded with quotes.
  // We are removing those for the end result to look as expected
  const nodeText = node.text.indexOf('"') === 0 ? trim(node.text, '"') : node.text;
  const textColumn = node.text.indexOf('"') === 0 ? node.startPosition.column + 1 : node.startPosition.column;
  const wordIndex = lineWords.characterIndexToWordIndex(textColumn);

  return computeValueObjectWithEndIndex(relevantLine, node.startPosition.row + 1, wordIndex, nodeText);
}

export function _parseDescriptionField(
  fileTextLines: string[],
  descriptionField: string,
  nameField: string,
  objectRefs: Record<string, ObjectRef>
) {
  if (!descriptionField) {
    logger.warn(`Description field for ${nameField} is empty`);
    return {};
  }

  const lineIndex = getLineIndex(fileTextLines, 'description', descriptionField);

  if (lineIndex === -1) {
    logger.warn(`Description field not found for ${nameField}`);
    return {};
  }

  const CONNECTIONS_REGEX = /@([\w]+)[ =]{1}\[([^\]]+)]/g; // Will match, as example, @sources [\n\t@connection1=SAP_SUP_Table_ANZ\n]
  const ATTRIBUTE_REGEX = /@(?!description)([\w]+)[=:]\s?\\*"?([^[\]\r\n,"\\]+)\\*"?/g; // Will match, as example, @sourceSystem=\"Stripe\"

  // Replacing \" with \ is a hack for files where they decided to surround the description with escaped quotes - which fails the search function
  const lineText = fileTextLines[lineIndex].replace(/(\\{1}")/g, '\\');
  const lineWords: LineWords = new LineWords(lineText);

  const additionalInfo: AdditionalInfo = {};

  const descriptionMatch = descriptionField.match(DESCRIPTION_REGEX);
  if (descriptionMatch?.[1]) {
    const value = descriptionMatch[1].trim();
    const wordIndex = lineWords.characterIndexToWordIndex(fileTextLines[lineIndex].search(value));

    // Translate wordStartIndex to start and end indices
    additionalInfo.description = computeValueObjectWithEndIndex(lineText, lineIndex + 1, wordIndex, value);

    // Revert changes made to line data - so autosync properly matches it
    if (additionalInfo.description) {
      additionalInfo.description.lineText = additionalInfo.description.lineText.replace(/(\\{1})\*(?!n)/g, '\\"');
    }
  }

  const connections = descriptionField.matchAll(CONNECTIONS_REGEX);
  for (const connection of connections) {
    if (connection.index > -1) {
      if (connection[2]) {
        additionalInfo[connection[1]] =
          connection[2].split(NEW_LINE_REGEX).reduce((acc, textLine) => {
            const value = textLine.trim().match(/@[\w]+=(.*)/)?.[1];
            if (value != null) {
              const matchingObjectRef = objectRefs[value];
              if (matchingObjectRef) {
                acc.push(matchingObjectRef);
              } else {
                logger.warn(`No matching object found with name ${value}. Using empty object.`);
                acc.push({
                  id: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for ${value}` },
                  name: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for ${value}` },
                  type: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for type` },
                  description: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for description` },
                  path: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for path` },
                  repoHandle: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for repoHandle` },
                  operation: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for operation` },
                });
              }
            }
            return acc;
          }, Array<ObjectRef>()) || [];
      }
    }
  }

  const attributes = descriptionField.matchAll(ATTRIBUTE_REGEX);
  for (const attribute of attributes) {
    if (attribute.index > -1) {
      if (attribute[2]) {
        const value = attribute[2].trim();
        if (value != null) {
          const wordIndex = lineWords.characterIndexToWordIndex(lineText.search(attribute[2].trim()));
          additionalInfo[attribute[1]] = computeValueObjectWithEndIndex(lineText, lineIndex + 1, wordIndex, value);

          if (additionalInfo[attribute[1]]) {
            (additionalInfo[attribute[1]] as ValueObject).lineText = fileTextLines[lineIndex];
          }
        }
      }
    }
  }

  // If the description field is a string description, not matching the expected syntax - use it as the description attribute
  if (Object.keys(additionalInfo).length === 0 && descriptionField) {
    additionalInfo.description = _composeValueObject(fileTextLines, 'description', descriptionField, -1, lineIndex);
  }

  return additionalInfo;
}

export function parseDescriptionField(
  fileTextLines: string[],
  node: SyntaxNode,
  nameField: string,
  objectRefs: Record<string, ObjectRef>
) {
  if (!node || !node.text) {
    logger.warn(`Description field for ${nameField} is empty`);
    return {};
  }

  const relevantLine = fileTextLines[node.startPosition.row];
  const lineWords: LineWords = new LineWords(relevantLine);

  const CONNECTIONS_REGEX = /@([\w]+)[ =]{1}\[([^\]\n]+)]/g; // Will match, as example, @sources [\n\t@connection1=SAP_SUP_Table_ANZ\n]
  const ATTRIBUTE_REGEX = /@(?!description)([\w]+)[=:]\s?\\*"?([^[\]\r\n,"\\]+)\\*"?/g; // Will match, as example, @sourceSystem=\"Stripe\"

  const additionalInfo: AdditionalInfo = {};

  const descriptionMatch = node.text.match(DESCRIPTION_REGEX);
  if (descriptionMatch?.[1]) {
    const value = descriptionMatch[1].trim();
    const wordIndex = lineWords.characterIndexToWordIndex(relevantLine.indexOf(value, descriptionMatch.index));

    // Translate wordStartIndex to start and end indices
    additionalInfo.description = computeValueObjectWithEndIndex(
      relevantLine,
      node.startPosition.row + 1,
      wordIndex,
      value
    );
  }

  const connections = node.text.matchAll(CONNECTIONS_REGEX);
  for (const connection of connections) {
    if (connection.index > -1) {
      if (connection[2]) {
        additionalInfo[connection[1]] =
          connection[2].split(NEW_LINE_REGEX).reduce((acc, textLine) => {
            const matches = textLine.trim().matchAll(/@[\w]+=([^@=,\s]*)/g);
            for (const match of matches) {
              let value = match[1];
              if (value) {
                // as text \n at the end of the phrase if not removed from the value and become a part of the token
                // Manually removing it here
                value = trim(value, '\n');

                // Sometimes newlines arrive as the actual distinct characters
                const stringifiedNewLineIndex = value.lastIndexOf('\\n');
                if (stringifiedNewLineIndex > -1) {
                  value = value.substring(0, stringifiedNewLineIndex);
                }
                const matchingObjectRef = objectRefs[value];
                if (matchingObjectRef) {
                  acc.push(matchingObjectRef);
                } else {
                  logger.warn(`No matching object found with name ${value}. Using empty object.`);
                  acc.push({
                    id: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for ${value}` },
                    name: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for ${value}` },
                    type: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for type` },
                    description: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for description` },
                    path: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for path` },
                    repoHandle: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for repoHandle` },
                    operation: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for operation` },
                  });
                }
              }
            }
            return acc;
          }, Array<ObjectRef>()) || [];
      }
    }
  }

  const attributes = node.text.matchAll(ATTRIBUTE_REGEX);
  for (const attribute of attributes) {
    if (attribute.index > -1) {
      if (attribute[2]) {
        const value = attribute[2].trim();
        if (value != null) {
          const wordIndex = lineWords.characterIndexToWordIndex(node.startPosition.column + node.text.search(value));
          additionalInfo[attribute[1]] = computeValueObjectWithEndIndex(
            relevantLine,
            node.startPosition.row + 1,
            wordIndex,
            value
          );
        }
      }
    }
  }

  // If the description field is a string description, not matching the expected syntax - use it as the description attribute
  if (Object.keys(additionalInfo).length === 0 && trim(node.text, '"')) {
    // capturedDescription is extracted using string and not string_content in order to make sure we get the full json value to parse
    // This means it might also come with surrounding quotes, which we need to remove
    const value = trim(node.text, '"');
    const characterStartIndex = node.startPosition.column + (node.text.length !== value.length ? 1 : 0);
    const wordIndex = lineWords.characterIndexToWordIndex(characterStartIndex);
    additionalInfo.description = computeValueObjectWithEndIndex(
      relevantLine,
      node.startPosition.row + 1,
      wordIndex,
      value
    );
  }

  return additionalInfo;
}

export function _parseOperation(
  object: ObjectInfo & { path?: string },
  fileTextLines: string[],
  lastMatchedLine: number
) {
  const operationContextAttribute = (object.metadata?.contextAttributes ?? [])[1];
  const rawOperation = operationContextAttribute?.value?.split('@')[1] ?? '';

  // The regex will match the key and the value to find the file line they came from (vital for creating smart tokens later)
  const regExp = new RegExp(`["']value["']\\s?:\\s?["']?${String.raw`${operationContextAttribute?.value ?? ''}`}`);

  const lineIndex = fileTextLines.findIndex((line, index) => {
    // Replacing \" with " is a hack for files where they decided to surround the description with escaped quotes - which fails the regex
    const match = line.replace(/\\{1}/g, '').match(regExp);
    return match != null && index > lastMatchedLine;
  });

  return _composeValueObject(fileTextLines, 'operation', rawOperation, lastMatchedLine, lineIndex);
}

export function parseOperation(node: SyntaxNode, language: Language, fileTextLines: string[]) {
  const query = language.query(dedent`
    ((pair key: (string (string_content) @key-name) 
    value: (array) @value) (#eq? @key-name "contextAttributes"))
  `);
  const capturedContentAttributes = query.captures(node);

  // capturedContentAttributes is an array containing both the key-name node and the value node
  const capturedOperationNode = capturedContentAttributes
    .find((child) => child.name === 'value')
    ?.node.children?.find((child) => {
      // The value node's children is the actual array of contextAttributes
      // But it also contains nodes for '[', ',' and ']' - which we are skipping. Their type is string.
      if (child.type !== 'object') {
        return false;
      }
      // There might be multiple items under contextAttributes (I've seen max 2 so far)
      // One is usually with the name "id" - which is not the one holding the operation code
      // We want to grab the other one
      const capturedValue = captureStringValue(child, language, 'name');
      return capturedValue?.text !== 'id';
    });

  const capturedOperation = capturedOperationNode ? captureStringValue(capturedOperationNode, language, 'value') : null;
  const rawOperation = capturedOperation?.text?.split('@')[1] ?? '';
  const charOffset = capturedOperation?.text?.indexOf('@') + 1 ?? -1;

  if (!capturedOperation || !capturedOperation.text || !rawOperation) {
    return { ...EMPTY_VALUE_OBJECT, lineText: 'operation', value: 'Missing value for operation' };
  }

  const relevantLine = fileTextLines[capturedOperation.startPosition.row];
  const lineWords: LineWords = new LineWords(relevantLine);

  const wordIndex = lineWords.characterIndexToWordIndex(capturedOperation.startPosition.column + charOffset);
  return computeValueObjectWithEndIndex(relevantLine, node.startPosition.row + 1, wordIndex, rawOperation);
}

export function _parseObjectRefs(
  fileJson: RawProcessFile,
  fileTextLines: string[],
  by: 'name' | 'type',
  filePath: string
): { [name: string]: ObjectRef } {
  if (!fileJson.objectRefs) {
    return {};
  }

  let lastMatchedLine = 0;
  // First run creates a map of all objectRefs
  const objectRefsMap: { [key: string]: ObjectRef } = fileJson.objectRefs.reduce((acc, object) => {
    const parsedObject = {
      id: _composeValueObject(fileTextLines, 'id', object.id, lastMatchedLine),
      name: _composeValueObject(fileTextLines, 'name', object.name, lastMatchedLine),
      type: _composeValueObject(fileTextLines, 'type', object.type as string, lastMatchedLine),
      path: _composeValueObject(fileTextLines, 'path', object.path as string, lastMatchedLine),
    };

    const repoHandle = _composeValueObject(
      fileTextLines,
      'repoHandle',
      object.metadata?.repoInfo?.repoHandle as string
    );
    const operation = _parseOperation(object, fileTextLines, lastMatchedLine);

    lastMatchedLine = Math.max(
      ...Object.values(parsedObject).reduce((acc, item) => {
        if (item.line != null) {
          acc.push(item.line);
        }
        return acc;
      }, []),
      lastMatchedLine
    );

    (parsedObject as ObjectRef).repoHandle = repoHandle;
    (parsedObject as ObjectRef).operation = operation;

    (parsedObject as ObjectRef).filePath = filePath;

    if (acc[object[by]]) {
      logger.warn(`Duplicate object ${by} found: ${object[by]}`);
    } else {
      acc[object[by]] = parsedObject;
    }

    return acc;
  }, {});

  // Second run enriches each object with connections and information from its description field
  // (Some of it referenced other objectRefs, and therefore has to have the complete map before running)
  return fileJson.objectRefs.reduce((acc, object) => {
    let descriptionFieldInfo = {};
    if (object.metadata.additionalInfo.description) {
      descriptionFieldInfo = _parseDescriptionField(
        fileTextLines,
        object.metadata?.additionalInfo?.description,
        object.name,
        acc
      );
    }

    for (const key of Object.keys(descriptionFieldInfo)) {
      objectRefsMap[object[by]][key] = descriptionFieldInfo[key];
    }

    return objectRefsMap;
  }, objectRefsMap);
}

export function parseObjectRefs(
  fileTree: Tree,
  fileTextLines: string[],
  by: 'name' | 'type',
  filePath: string
): { [name: string]: ObjectRef } {
  const capturedObjectRefs = captureValue(fileTree.rootNode, fileTree.getLanguage(), 'objectRefs', 'array')?.node;
  if (!capturedObjectRefs || capturedObjectRefs.type !== 'array' || capturedObjectRefs.children.length <= 2) {
    return {};
  }

  const objectRefsMap: { [key: string]: ObjectRef } = capturedObjectRefs.children.reduce((acc, node, index, array) => {
    if (index === 0 || index === array.length - 1 || node.type !== 'object') {
      return acc;
    }

    const parsedObject = {};
    for (const key of ['id', 'name', 'type', 'path', 'repoHandle']) {
      const capturedValue = captureStringValue(node, fileTree.getLanguage(), key);
      parsedObject[key] = composeValueObject(fileTextLines, capturedValue, key);
    }

    parsedObject['operation'] = parseOperation(node, fileTree.getLanguage(), fileTextLines);
    parsedObject['filePath'] = filePath;

    if (acc[parsedObject[by].value]) {
      logger.warn(`Duplicate object ${by} found: ${parsedObject[by].value}`);
    } else {
      acc[parsedObject[by].value] = parsedObject;
    }

    return acc;
  }, {});

  // Second run enriches each object with connections and information from its description field
  // (Some of it referenced other objectRefs, and therefore has to have the complete map before running)
  return capturedObjectRefs.children.reduce((acc, node, index, array) => {
    if (index === 0 || index === array.length - 1 || node.type !== 'object') {
      return acc;
    }
    let descriptionFieldInfo = {};

    const capturedDescription = captureValue(node, fileTree.getLanguage(), 'description', 'string')?.node;

    if (capturedDescription) {
      const objectName = captureStringValue(node, fileTree.getLanguage(), 'name');
      descriptionFieldInfo = parseDescriptionField(fileTextLines, capturedDescription, objectName?.text, acc);
    }

    const capturedValue = captureStringValue(node, fileTree.getLanguage(), by);
    for (const key of Object.keys(descriptionFieldInfo)) {
      acc[capturedValue?.text][key] = descriptionFieldInfo[key];
    }

    return acc;
  }, objectRefsMap);
}
