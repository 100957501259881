import trim from 'lodash-es/trim.js';
import path from 'path-browserify';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import type {
  Path,
  SmartElement,
  SmartElementWithApplicability,
  SmartElementWithApplicabilityAndNewInfo,
  Token,
} from '@swimm/shared';
import { ApplicabilityStatus } from '@swimm/shared';
import { getSwimmNodeId } from '@/swmd/swimm_node';
import { serializeSwmPathShort } from '@/markdownit/plugins/swm_path';

export function generatePlainMermaidText(
  node: ProseMirrorNode,
  smartElements?: Map<string, SmartElementWithApplicability<SmartElement>>
): string {
  const text: string[] = [];
  node.descendants((node) => {
    const textSoFar = text.join('');
    // When inside a click directive, we should render nodes differently so that the click handler can navigate to the
    // correct location.
    const isClickDirective = !!/^\s*click\s+(?<nodeId>\S+)\s+(?:call\s+)?(?<funcName>\S+)\s+['"]?$/m.exec(textSoFar);
    switch (node.type.name) {
      case 'text':
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        text.push(node.text!);
        break;
      case 'swmToken': {
        let filePath = node.attrs.path;
        let lineNumber = node.attrs.pos.line;
        let token = node.attrs.token;
        if (smartElements != null) {
          const smartElement = smartElements.get(getSwimmNodeId(node));
          if (
            smartElement != null &&
            [ApplicabilityStatus.Verified, ApplicabilityStatus.Autosyncable].includes(smartElement.applicability)
          ) {
            const newInfo = (smartElement as SmartElementWithApplicabilityAndNewInfo<Token>).newInfo;
            filePath = newInfo.filePath;
            lineNumber = newInfo.lineNumber;
            token = newInfo.symbolText;
          }
        }
        if (isClickDirective) {
          // In a click directive, render the token as a path and line number, so that the click handler can navigate to
          // the token's location upon clicking.
          text.push(`${trim(serializeSwmPathShort(filePath, /* short=*/ 0), path.sep)}:${lineNumber}`);
          break;
        }
        text.push(token);
        break;
      }
      case 'swmPath': {
        let filePath = node.attrs.href;
        if (smartElements != null) {
          const smartElement = smartElements.get(getSwimmNodeId(node));
          if (
            smartElement != null &&
            [ApplicabilityStatus.Verified, ApplicabilityStatus.Autosyncable].includes(smartElement.applicability)
          ) {
            filePath = (smartElement as SmartElementWithApplicabilityAndNewInfo<Path>).newInfo.filePath;
          }
        }
        text.push(
          trim(
            serializeSwmPathShort(
              filePath,
              // In a click directive, always render the full path so that the click handler knows where to navigate.
              isClickDirective ? 0 : node.attrs.short
            ),
            path.sep
          )
        );
        break;
      }
      default:
        text.push('UNKNOWN');
    }
  });

  return text.join('');
}

export function serializeSwimmMermaidText(swimm: string, plain: string): string {
  return (
    plain +
    '\n\n%% Swimm:\n' +
    swimm
      .split('\n')
      .map((s) => `%% ${s}`)
      .join('\n')
  );
}
