// @ts-ignore
import type { App, Element } from 'vue';

const components = import.meta.glob('@/common/components/atoms/*.vue', { eager: true });

export default function loadAllAtoms(app: App<Element>) {
  Object.keys(components).forEach((compPath = '') => {
    // @ts-ignore
    const comp = components[compPath].default;
    const compName = compPath.split('/').slice(-1)[0].replace('.vue', '');
    app.component(compName, comp);
  });
}
