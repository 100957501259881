<script setup lang="ts">
import { BaseButton, BaseHeading, BaseIcon, BaseLayoutGap, BaseProse } from '@swimm/reefui';

const emit = defineEmits<{
  (e: 'expand-drawer'): void;
  (e: 'collapse-drawer'): void;
  (e: 'dismiss-drawer'): void;
}>();

withDefaults(
  defineProps<{
    showEditSnippetMessage: boolean;
    expanded: boolean;
    fullBorderRadius?: boolean;
    showOnTop: boolean;
    noBottomSpacing: boolean;
  }>(),
  {
    showEditSnippetMessage: false,
    fullBorderRadius: false,
    showOnTop: false,
    noBottomSpacing: false,
  }
);
</script>

<template>
  <div
    :class="[
      'generate-drawer',
      { 'full-border-radius': fullBorderRadius, 'top-position': showOnTop, 'no-bottom-spacing': noBottomSpacing },
    ]"
  >
    <BaseLayoutGap v-if="!expanded" class="clickable" direction="row" alignment="stretch">
      <BaseLayoutGap size="small" class="header" @click="emit('expand-drawer')">
        <BaseLayoutGap size="xsmall">
          <BaseIcon name="magic" />
          <BaseHeading :level="5">Generate draft with AI</BaseHeading>
        </BaseLayoutGap>
        <BaseProse v-if="showEditSnippetMessage" variant="secondary" size="small"
          >Edit snippets and expand to continue</BaseProse
        >
      </BaseLayoutGap>
      <BaseLayoutGap size="xxsmall" class="header-buttons">
        <BaseButton variant="tertiary">
          <template #leftIcon>
            <BaseIcon name="arrow-up" @click="emit('expand-drawer')" />
          </template>
        </BaseButton>
        <BaseButton variant="tertiary">
          <template #leftIcon>
            <BaseIcon name="close" @click="emit('dismiss-drawer')" />
          </template>
        </BaseButton>
      </BaseLayoutGap>
    </BaseLayoutGap>
    <div v-show="expanded" class="content-container">
      <slot name="content" />
    </div>
  </div>
</template>

<style scoped>
.generate-drawer {
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  position: sticky;
  bottom: var(--space-small);
  z-index: var(--layer-modal);
  background-color: var(--color-bg);
  box-shadow: var(--box-shadow-medium);
  margin-left: var(--narrow-content-width-left-padding);
  margin-right: var(--narrow-content-width-right-padding);
  box-sizing: border-box;

  & > .content-container {
    padding: var(--space-xs) var(--space-base);
  }

  &.full-border-radius {
    border-radius: var(--border-radius);
  }

  &.no-bottom-spacing {
    bottom: 0;
    border-bottom: none;
  }

  &.top-position {
    position: fixed;
    top: var(--space-medium);
    bottom: auto;
  }
}

/* eslint-disable vue-scoped-css/no-unused-selector */
.icon.icon-magic {
  color: var(--text-color-magic-strong);
}

.header {
  flex-grow: 1 !important;
  flex-wrap: wrap !important;
  row-gap: var(--space-base) !important;
  padding: var(--space-xs) var(--space-sm) !important;
}

.header-buttons {
  padding: var(--space-xs) var(--space-base) !important;
}

.input-textarea {
  width: inherit;
  min-height: 6rem;
}
</style>
