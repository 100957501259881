import type { PlaylistSequenceStep } from './sequence';

export interface Playlist {
  name?: string;
  id?: string;
  draftId?: string;
  description?: string;
  summary?: string;
  sequence: PlaylistSequenceStep[];
  creator_name?: string;
}

export const EMPTY_PLAYLIST_CONTENT = {
  sequence: [],
};
