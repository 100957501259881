<script setup lang="ts">
import path from 'path-browserify';
import trimStart from 'lodash-es/trimStart';
import uniqBy from 'lodash-es/uniqBy';
import {
  BaseAnchor,
  BaseButton,
  BaseDivider,
  BaseDropdown,
  BaseHeading,
  BaseIcon,
  BaseInput,
  BaseLayoutGap,
  BaseLoading,
  BaseProse,
  NodeTree,
} from '@swimm/reefui';
import {
  FolderTree,
  SwimmDocument,
  WorkspaceDocumentTemplate,
  eventLogger,
  getLoggerNew,
  productEvents,
} from '@swimm/shared';
import { SwModal } from '@swimm/ui';
import { storeToRefs } from 'pinia';
import { Ref, computed, ref, watch } from 'vue';

import { useFsWrapper } from '@/modules/editor/tiptapEditor/compositions/fsWrapper';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useStore } from 'vuex';
import { useResourceActions } from '@/modules/resources/composables/resource-actions';
import InvalidInputError from '@/modules/customizations/custom-process-to-doc/ppg/invalidInputError';
import INSTRUCTIONS from '@/modules/customizations/custom-process-to-doc/ppg/instructions';
import {
  MissingMergeBaseError,
  useProcessToDocStore,
} from '@/modules/customizations/custom-process-to-doc/stores/processToDoc';

const props = withDefaults(
  defineProps<{
    repoId: string;
    branch: string;
  }>(),
  {
    repoId: '',
    branch: null,
  }
);

const logger = getLoggerNew(__modulename);

const store = useStore();
const { getResourceLink } = useResourceActions();
const { user } = storeToRefs(useAuthStore());
const { fsWrapper } = useFsWrapper();
const processToDocStore = useProcessToDocStore();
const { id: workspaceId } = storeToRefs(useWorkspaceStore());
const { documentTemplates, showProcessToDocModal, showProcessToDocModalOptions } = storeToRefs(processToDocStore);
const {
  closeProcessToDocModal,
  refreshDocumentTemplates,
  generate,
  regenerate,
  loadSwmd,
  getCurrentPaths,
  extractFilePaths,
} = processToDocStore;
const analytics = useAnalytics();

const isWorkspaceAdmin = computed<boolean>(() =>
  store.getters['database/db_isWorkspaceAdmin'](workspaceId.value, user.value.uid)
);

const selectedProcess = ref<WorkspaceDocumentTemplate>();
const selectedNodes = ref([]);
const docTitle = ref<string>('');
const currentDoc = ref<SwimmDocument>();
const preparingDoc = ref<boolean>(false);
const stateKey = ref(Date.now());

const generatedDocUrl = ref<string>('');
const error = ref<string>();
const doneMessage = ref<string>('');

const templates = computed(() => {
  if (documentTemplates.value instanceof Map) {
    return Array.from(documentTemplates.value.values()).map((template) => ({
      id: template.id,
      label: template.name,
      name: template.name,
      link: isWorkspaceAdmin.value
        ? getResourceLink({
            resource: { id: template.id },
            repoId: props.repoId,
            branch: props.branch,
            type: 'template',
          })
        : '',
    }));
  }
  return [];
});

const folderTree: Ref<FolderTree | null> = ref(null);
const loadingTree = computed<boolean>(() => {
  if (!props.repoId || !props.branch || !showProcessToDocModal.value) {
    return true;
  }

  if (!folderTree.value && error.value) {
    return false;
  }

  if (!folderTree.value) {
    return true;
  }

  return (
    // If regenerating - wait until the regeneration is fully prepared
    showProcessToDocModalOptions.value?.unitId && (!selectedNodes.value?.length || !docTitle.value)
  );
});

async function getRepoFolderTree(repoId: string, branch: string, isCrossRepo: boolean): Promise<FolderTree> {
  await fsWrapper.populateRepoFolderTree({ repoId: repoId, branch, isCrossRepo });
  return await fsWrapper.getRepoFolderTree(repoId);
}

function addSelectedNode(node: FolderTree) {
  selectedNodes.value.push(node);
}
function removeSelectedNode(node: FolderTree) {
  selectedNodes.value = selectedNodes.value.filter((n) => n.path !== node.path);
}
function resetSelectedNodes() {
  selectedNodes.value = [];
  stateKey.value = Date.now();
}

function selectDocumentTemplate(templateName: string) {
  selectedProcess.value = documentTemplates.value.get(templateName);
}

async function prepareDocRegeneration(docId: string) {
  currentDoc.value = await loadSwmd(docId);
  const filePaths = extractFilePaths(currentDoc.value);
  const currentFilePaths = await getCurrentPaths(filePaths, props.repoId, props.branch);

  // Fill selectedNodes with the files (and the folder nesting to them) so they are filled in the nodeTree
  selectedNodes.value = uniqBy(
    currentFilePaths.reduce((acc, filePath) => {
      const pathToFile = filePath.split(path.sep);
      if (pathToFile.length > 1) {
        let pathSoFar = '';
        const folderPath = pathToFile.slice(0, pathToFile.length - 1).map((folder) => {
          pathSoFar = path.join(pathSoFar, folder);
          return { name: folder, path: pathSoFar, type: 'directory' };
        });
        acc.push(...folderPath);
      }
      acc.push({
        path: trimStart(filePath, path.sep),
        name: path.basename(filePath),
        type: 'file',
      });
      return acc;
    }, []),
    'path'
  );

  docTitle.value = currentDoc.value.title;
}

watch(
  () => [props.repoId, props.branch, showProcessToDocModal.value, showProcessToDocModalOptions.value],
  () => {
    if (props.repoId && props.branch && showProcessToDocModal.value) {
      if (showProcessToDocModalOptions.value?.unitId) {
        prepareDocRegeneration(showProcessToDocModalOptions.value.unitId).then(() => {
          getRepoFolderTree(props.repoId, props.branch, false)
            .then((tree) => {
              folderTree.value = tree;
            })
            .catch(() => {
              error.value = 'Failed loading repo files';
            });
        });
      } else {
        getRepoFolderTree(props.repoId, props.branch, false)
          .then((tree) => {
            folderTree.value = tree;
          })
          .catch(() => {
            error.value = 'Failed loading repo files';
          });
      }
    }
  },
  { immediate: true }
);

watch(
  () => [selectedNodes.value, selectedProcess.value],
  () => {
    if (error.value) {
      error.value = undefined;
    }
  }
);

watch(
  () => showProcessToDocModal.value,
  (value) => {
    if (value) {
      refreshDocumentTemplates();
    }
  }
);

const isValid = computed(() => {
  return docTitle.value && selectedProcess.value && selectedNodes.value.length > 0;
});

function resetModal() {
  resetSelectedNodes();
  docTitle.value = '';
  currentDoc.value = undefined;
  selectedProcess.value = undefined;
  showProcessToDocModalOptions.value = null;
}

function onCloseModal() {
  closeProcessToDocModal();
  resetModal();
  doneMessage.value = undefined;
  generatedDocUrl.value = undefined;
}

async function onSubmit(): Promise<void> {
  generatedDocUrl.value = undefined;
  doneMessage.value = undefined;

  try {
    if (isValid.value) {
      preparingDoc.value = true;

      // selectedNodes also includes folders. We want to exclude those before triggering the generation process
      const nodesToProcess = selectedNodes.value.filter((node) => node.type === 'file');
      const filePaths = nodesToProcess.map((node) => node.path);
      const process = selectedProcess.value.name.toLowerCase() as 'cdi' | 'cai';

      let newDocId: string;
      if (currentDoc.value) {
        const result = await regenerate({
          current: {
            document: currentDoc.value,
            docId: showProcessToDocModalOptions.value.unitId,
            docPath: showProcessToDocModalOptions.value.unitPath,
          },
          process,
          filePaths,
          repoId: props.repoId,
          branch: props.branch,
        });
        newDocId = result.id;

        if (result.status === 'ok') {
          doneMessage.value = 'Successfully regenerated document';
        } else {
          doneMessage.value = 'Document regeneration failed. A new document has been created';
        }
      } else {
        newDocId = await generate(
          {
            title: docTitle.value,
            templateId: selectedProcess.value.id,
            process,
            filePaths,
          },
          props.repoId,
          props.branch
        );

        doneMessage.value = 'Successfully generated document';
      }

      generatedDocUrl.value = getResourceLink({
        resource: { id: newDocId, name: docTitle.value },
        repoId: props.repoId,
        branch: props.branch,
        type: 'document',
        editMode: true,
      });

      analytics.track(
        productEvents.PROCESS_DOC_CREATED,
        {
          template: selectedProcess.value.name,
          branch: props.branch,
          backofficeCode: eventLogger.SWIMM_EVENTS.PROCESS_DOC_CREATED.code,
        },
        { addRouteParams: true }
      );

      preparingDoc.value = false;
      resetModal();
    }
  } catch (err) {
    preparingDoc.value = false;

    if (err instanceof InvalidInputError || err instanceof MissingMergeBaseError) {
      error.value = err.message;
    } else {
      error.value = 'An error occurred while generating the document, please contact support.';
    }

    logger.error({ err }, 'Error processing template to doc');
  }
}
</script>

<template>
  <div>
    <SwModal :padded="false" :show-modal="showProcessToDocModal" @close="onCloseModal">
      <template #header>
        <BaseLayoutGap alignment="left">
          <BaseIcon name="magic" />
          <BaseProse weight="bolder"> Process to doc </BaseProse>
        </BaseLayoutGap>
      </template>
      <div class="prs-modal">
        <BaseLayoutGap direction="column" size="small" alignment="stretch">
          <BaseLayoutGap alignment="left" size="xxsmall">
            <BaseHeading :level="4"> Template </BaseHeading>
            <BaseHeading :level="4" style="color: var(--color-text-danger)"> *</BaseHeading>
          </BaseLayoutGap>
          <!-- TODO: key should be unique for the data (primary key) not for the parent state.
                Use v-model into the dropdown to reset the state after submit -->
          <BaseDropdown
            :key="`dropdown-${stateKey}`"
            @selected="(e) => selectDocumentTemplate(e.name)"
            placeholder="Select a template"
            :options="templates"
            :footer="true"
          />
          <BaseProse v-if="selectedProcess?.name" variant="secondary" size="small">
            <pre class="prs-modal__pre-wrap">{{ INSTRUCTIONS[selectedProcess.name] }}</pre>
          </BaseProse>
          <BaseLayoutGap alignment="left" size="xxsmall">
            <BaseHeading :level="4"> Input Files </BaseHeading>
            <BaseHeading :level="4" style="color: var(--color-text-danger)"> *</BaseHeading>
          </BaseLayoutGap>
          <div class="tree-container">
            <template v-if="loadingTree || preparingDoc"> <BaseLoading variant="primary" /> </template>
            <template v-else>
              <NodeTree
                :node="folderTree"
                :level-offset="1"
                :selected-nodes="selectedNodes"
                selectable
                selectable-folders
                @selected-nodes:add="addSelectedNode"
                @selected-nodes:remove="removeSelectedNode"
              />
            </template>
          </div>
          <BaseLayoutGap alignment="left" size="xxsmall">
            <BaseHeading :level="4"> Document Title </BaseHeading>
            <BaseHeading :level="4" style="color: var(--color-text-danger)"> *</BaseHeading>
          </BaseLayoutGap>
          <BaseInput
            v-model="docTitle"
            type="text"
            placeholder="Title"
            required
            :disabled="(showProcessToDocModalOptions?.unitId && !docTitle) || preparingDoc"
          >
            <template v-if="showProcessToDocModalOptions?.unitId && !docTitle"> Loading... </template>
          </BaseInput>
        </BaseLayoutGap>
        <BaseLayoutGap direction="row" alignment="right" class="buttons-container">
          <BaseButton @click="onSubmit" :disabled="!isValid || preparingDoc" :loading="preparingDoc">Submit</BaseButton>
        </BaseLayoutGap>

        <template v-if="generatedDocUrl">
          <BaseDivider size="xxsmall" />
          <BaseLayoutGap alignment="stretch">
            <BaseProse variant="success">{{ doneMessage }}</BaseProse>
            <BaseAnchor :href="generatedDocUrl" class="prs-modal__go-to-button"> Go to doc </BaseAnchor>
          </BaseLayoutGap>
        </template>
        <template v-if="error">
          <BaseDivider size="xxsmall" />
          <BaseProse variant="danger">
            {{ error }}
          </BaseProse>
        </template>
      </div>
    </SwModal>
  </div>
</template>

<style scoped>
.prs-modal {
  padding: var(--space-medium);
  width: 540px;
  display: flex;
  flex-direction: column;
  gap: var(--space-small);
  overflow-y: auto;

  &__pre-wrap {
    white-space: pre-wrap;
  }

  &__go-to-button {
    min-width: 5em;
    text-align: end;
  }
}

.tree-container {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
