import { type Ref, computed } from 'vue';

import { type Repo, type SwimmDocument, config, getLoggerNew } from '@swimm/shared';
import { LegacySwmdError, parseSwmd } from '@swimm/swmd';

import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { SwimmFileType } from '../docs';
import { useDocsStore } from '../stores/docs';

export class DocNotFoundError extends Error {}

const logger = getLoggerNew(__modulename);

export function useDoc(workspaceId: Ref<string>, repoId: Ref<string>) {
  const docsStore = useDocsStore();
  const reposStore = useReposStore();
  const workspaceStore = useWorkspaceStore();

  // The app loads the entire thing upfront now and uses a cache to speed it up
  const repos = computed<Repo[]>(() =>
    reposStore.repos.filter((repo) => workspaceStore.repositories.includes(repo.id))
  );

  const repo = computed<Repo | undefined>(() => repos.value.find((repo) => repo.id === repoId.value));
  const loadingDocs = computed<boolean>(() => !!docsStore.loadingDocs);
  const loadingDocsOfRepoId = computed(() => docsStore.repoId);
  const loadDoc = async (id: string): Promise<string> => {
    const docInfo = docsStore.docs?.[id];
    if (docInfo == null || docInfo.type !== SwimmFileType.SWMD) {
      throw new DocNotFoundError('Doc not found');
    }
    return await docsStore.loadDoc(id);
  };

  function getDocPathInRepo(id: string): string | null {
    const docInfo = docsStore.docs?.[id];
    return docInfo?.path ?? null;
  }

  const loadCrossRepoDoc = async ({
    crossRepoId,
    id,
    defaultBranch,
  }: {
    crossRepoId: string;
    id: string;
    defaultBranch: string;
  }): Promise<string> => {
    try {
      return await docsStore.loadCrossRepoDoc({ crossRepoId, id, defaultBranch });
    } catch (err) {
      logger.error(`failed to load cross repo doc ${crossRepoId} ${id} ${defaultBranch}: ${err}`);
      throw new DocNotFoundError('Doc not found');
    }
  };

  const parseDoc = async (text: string): Promise<SwimmDocument> => {
    try {
      return parseSwmd(text);
    } catch (err) {
      if (!(err instanceof LegacySwmdError)) {
        throw err;
      }
      if (repo.value == null) {
        throw Error('Repo not found');
      }
      return parseSwmd(text, {
        legacy: {
          baseUrl: config.BASE_URL,
          workspaceId: workspaceId.value,
          repoId: repoId.value,
          repoName: repo.value.name,
          repos: repos.value,
        },
      });
    }
  };

  async function loadSwmd(docId: string): Promise<SwimmDocument> {
    return parseSwmd(await loadDoc(docId));
  }

  return { loadDoc, loadCrossRepoDoc, parseDoc, loadingDocs, loadingDocsOfRepoId, getDocPathInRepo, loadSwmd };
}
