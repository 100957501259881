<script setup>
import { Icon, SwText } from '@swimm/ui';

defineProps({
  title: { type: String, required: true },
  subtitle: { type: String, required: false, default: '' },
  showBack: { type: Boolean, default: false },
  disableBack: { type: Boolean, default: false },
});

const emit = defineEmits(['back']);
</script>

<template>
  <div class="settings">
    <div class="title-line">
      <Icon
        v-if="showBack"
        name="arrow-left"
        :class="['back', { disable: disableBack }]"
        no-padding
        @click="!disableBack && emit('back')"
      />
      <SwText variant="headline3" class="title" component="span">{{ title }}</SwText>
      <SwText v-if="subtitle" variant="body-S" class="subtitle" component="span">{{ subtitle }}</SwText>
    </div>
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.title-line {
  display: flex;
  align-items: baseline;

  .back {
    cursor: pointer;
    margin-right: var(--space-base);

    &.disable {
      cursor: not-allowed;
    }
  }

  .title {
    margin-bottom: var(--space-sm);
  }

  .subtitle {
    margin-left: var(--space-xs);
  }
}

.settings {
  display: block;
}
</style>
