<script setup lang="ts">
import ReviewAutosyncHeadline from './ReviewAutosyncHeadline.vue';
import ReviewAutosyncNodeWrapper3 from './ReviewAutosyncNodeWrapper3.vue';
import { type NeedReviewItem } from '@/modules/doc-sidebar/types';

interface ReviewAutosyncSectionProps {
  isAutosyncable: boolean;
  sectionLength: number;
  needsReviewItems: NeedReviewItem[];
}
defineProps<ReviewAutosyncSectionProps>();
const emit = defineEmits(['accept-all', 'delete-outdated', 'accept-autosync', 'node-clicked']);
</script>

<template>
  <div class="section-wrapper">
    <ReviewAutosyncHeadline
      :is-autosyncable="isAutosyncable"
      :nodes-count="sectionLength"
      @accept-all="() => emit('accept-all')"
      class="headline"
    />
    <TransitionGroup name="fade-and-shrink">
      <div class="node-wrapper" :key="node.id" v-for="node in Object.values(needsReviewItems)">
        <ReviewAutosyncNodeWrapper3
          :is-autosyncable="isAutosyncable"
          :node-item="node"
          @accept-autosync="(node) => emit('accept-autosync', node)"
          @delete-outdated="emit('delete-outdated', node)"
          @node-clicked="(pos) => emit('node-clicked', pos)"
        />
      </div>
    </TransitionGroup>
  </div>
</template>

<style scoped lang="postcss">
.node-wrapper {
  padding-bottom: var(--space-xs);
}

.section-wrapper {
  padding-bottom: var(--space-sm);
}
</style>
