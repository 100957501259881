import { config } from '@swimm/shared';

export const MERGE_BASE_FOLDER_PATH = '.merge-base';
export const COMMIT_SUFFIX_PROCESS_TO_DOC = '(BY PTD)';

export function composeHideCommitMessage(): string {
  return 'Revert merge base files';
}

export function composeCommitMessage(userMessage: string): string {
  return `${userMessage}${COMMIT_SUFFIX_PROCESS_TO_DOC}`;
}

export function getHideCommitPath(docId: string): string {
  return `${config.SWM_FOLDER_IN_REPO}/${MERGE_BASE_FOLDER_PATH}/${docId}${config.SWMD_FILE_EXTENSION}`;
}
