<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch, watchEffect } from 'vue';

import { type TokenSuggestion } from '@swimm/shared';

import BaseProse from '../../components/BaseProse/BaseProse.vue';
import BaseLoading from '../../components/BaseLoading/BaseLoading.vue';

import MenuTokens from '../MenuTokens/MenuTokens.vue';
import SwmSelectionContent from '../SwmSelectionContent/SwmSelectionContent.vue';
import type { CachedHighlighters } from '../../types';
import type { SelectedIndex } from '../../types/transitional';

const props = withDefaults(
  defineProps<{
    modelValue: string;
    selectedIndex?: SelectedIndex;
    tokenSuggestions?: TokenSuggestion[];
    loading?: boolean;
    error?: string;
    noResults?: boolean;
    disableAutoFocus?: boolean;
  }>(),
  {
    selectedIndex: undefined,
    tokenSuggestions: undefined,
    error: undefined,
    disableAutoFocus: undefined,
  }
);

const emit = defineEmits<{
  'update:modelValue': [value: string];
  selectToken: [token: TokenSuggestion];
  focusedToken: [token: TokenSuggestion];
  hoveredToken: [token: TokenSuggestion];
  close: [];
}>();

const menuTokens = ref<InstanceType<typeof MenuTokens>>();
const q = ref(props.modelValue);
const selectionContent = ref<InstanceType<typeof SwmSelectionContent> | null>(null);
const paginatedTokenSuggestions = ref<TokenSuggestion[] | undefined>();
const page = ref(1);
const limit = ref(30);
const delayFocus = ref(false);
const isLoading = ref(props.loading || false);
const cachedHighlighters = ref<CachedHighlighters>({});
const showMoreFocusIndex = ref();

const showPrompt = computed(() => {
  return (
    (!props.tokenSuggestions || (props.tokenSuggestions && props.tokenSuggestions.length === 0)) &&
    !props.noResults &&
    !isLoading.value
  );
});

const showMoreLimitReached = computed(() => {
  return page.value === 4;
});

const canShowMore = computed(() => {
  if (!props.tokenSuggestions || showMoreLimitReached.value) {
    return false;
  }

  return page.value * limit.value < props.tokenSuggestions.length;
});

const loadingSize = computed(() => {
  if (isLoading.value && props.tokenSuggestions && props.tokenSuggestions.length) {
    return 'small';
  }

  return 'medium';
});

async function setPaginatedTokenSuggestions() {
  isLoading.value = true;

  paginatedTokenSuggestions.value = props.tokenSuggestions?.slice(0, page.value * limit.value);

  await nextTick();

  await forceUpdateFocusItems();

  isLoading.value = false;
}

function onUpdateModelValue(value: string) {
  emit('update:modelValue', value);
  q.value = value;
}

function onSelectToken(token: TokenSuggestion) {
  emit('selectToken', token);
}

function onFocusToken(token: TokenSuggestion) {
  emit('focusedToken', token);
}

function onHoverToken(token: TokenSuggestion) {
  emit('hoveredToken', token);
}

function setCachedHighlighters(cache: CachedHighlighters) {
  cachedHighlighters.value = cache;
}

async function resetItemFocus() {
  if (selectionContent.value) {
    await nextTick();
    selectionContent.value.resetItemFocus();
  }
}

async function setSearchFocus() {
  if (selectionContent.value) {
    await nextTick();
    selectionContent.value.setSearchFocus();
  }
}

async function setItemFocus(index: number) {
  if (selectionContent.value) {
    await nextTick();
    selectionContent.value.setItemFocus(index);
  }
}

async function forceUpdateFocusItems() {
  if (selectionContent.value) {
    await nextTick();
    selectionContent.value.forceUpdateFocusItems();
  }
}

async function showMore() {
  // Get the curent focus index i.e. the show more action
  showMoreFocusIndex.value = paginatedTokenSuggestions.value ? paginatedTokenSuggestions.value.length : 0;

  // Increase the page count and reset paginated token suggestions
  page.value++;
  await setPaginatedTokenSuggestions();

  // // Force focusable items to update
  // await forceUpdateFocusItems();

  // // Set the item focus to the current focus index so keyboard
  // // navigation can continue from that point
  // setItemFocus(currentFocusIndex);
}

onMounted(async () => {
  if (!props.disableAutoFocus) {
    // We want to focus in the search input on mount but if loading
    // is true we can't focus because the input is disabled, so we
    // set delayFocus to true.
    if (props.loading) {
      delayFocus.value = true;
    } else {
      await setSearchFocus();
    }
  }
});

watch(
  () => props.modelValue,
  async () => {
    q.value = props.modelValue;
    page.value = 1;
    await setPaginatedTokenSuggestions();
  }
);

watchEffect(async () => {
  // We watch for loading to become false and if delayFocus was set
  // to true we focus on the search input.
  if (!props.disableAutoFocus && !props.loading && delayFocus.value) {
    await setSearchFocus();

    // We set delayFocus back to false to ensure this watcher
    // has no further effect.
    delayFocus.value = false;
  }
});

watch(
  () => props.tokenSuggestions,
  async () => {
    page.value = 1;
    await setPaginatedTokenSuggestions();
  },
  {
    immediate: true,
  }
);

watch(
  () => menuTokens.value?.cachingMore,
  async (newValue, oldValue) => {
    if (oldValue && !newValue) {
      // Force focusable items to update
      await forceUpdateFocusItems();

      // Set the item focus to the current focus index so keyboard
      // navigation can continue from that point
      if (showMoreFocusIndex.value != null) {
        setItemFocus(showMoreFocusIndex.value);
      }
    }
  }
);

defineExpose({
  setSearchFocus,
  setItemFocus,
  resetItemFocus,
  forceUpdateFocusItems,
});
</script>

<template>
  <div class="swm-selection-content-token-list">
    <SwmSelectionContent
      ref="selectionContent"
      v-model="q"
      placeholder="Search tokens…"
      :error="error"
      :no-results="noResults"
      :disable-auto-focus="disableAutoFocus"
      class="swm-selection-content-token-list__container"
      @update:model-value="onUpdateModelValue"
      @close="() => emit('close')"
    >
      <template #header-prefix>
        <slot name="header-prefix" />
      </template>

      <template #header>
        <slot name="header" />
      </template>

      <template #header-additional>
        <slot name="header-additional" />
      </template>

      <template #no-results>
        <slot name="no-results" />
      </template>

      <template v-if="showPrompt">
        <div class="swm-selection-content-token-list__start-prompt">
          <slot name="prompt">
            <BaseProse size="small">Start typing your search term</BaseProse>
          </slot>
        </div>
      </template>
      <template v-else-if="paginatedTokenSuggestions">
        <MenuTokens
          ref="menuTokens"
          :query="q"
          :token-suggestions="paginatedTokenSuggestions"
          :can-show-more="canShowMore"
          :show-more-limit-reached="showMoreLimitReached"
          @select-token="onSelectToken"
          @focused-token="onFocusToken"
          @hovered-token="onHoverToken"
          @updated="forceUpdateFocusItems"
          @highlighters-cached="setCachedHighlighters"
          @show-more="showMore"
        />
      </template>

      <BaseLoading
        v-if="isLoading"
        :size="loadingSize"
        variant="secondary"
        class="swm-selection-content-token-list__loading"
      />
    </SwmSelectionContent>
    <slot name="preview" :cached-highlighters="cachedHighlighters" />
  </div>
</template>

<style lang="scss" scoped>
@use '../../assets/styles/utils' as *;

.swm-selection-content-token-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__container {
    overflow: auto;
    max-height: 100%;
  }

  &__start-prompt {
    margin: var(--space-small) var(--space-xlarge) var(--space-small);
    text-align: center;
  }
}
</style>
