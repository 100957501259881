import diff3Merge from 'diff3';

const LINEBREAKS = /(\r?\n)/;
// Explaining the ^(?:[>*]|\d\.)? prefix
// Table rows can either start at start of line or be a child of a containing block (quotes or lists)
// See specifications: https://github.github.com/gfm/#tables-extension-
const TABLE_DELIMITER_ROW_REGEX = /^(?:[>*]|\d\.)?[^\S\r\n]*[-:|][-:|\s]*$/ms; // based on https://github.com/markdown-it/markdown-it/blob/0fe7ccb4b7f30236fb05f623be6924961d296d3d/lib/rules_block/table.mjs#L68
const TABLE_ROW_REGEX = /^(?:[>*]|\d\.)?[^\S\r\n]*(?:[^\n]+(?<!\\)\|)+[^\n]+(?<!\\)\|?$/ims;
const NON_ESCAPED_PIPE_REGEX = /(?<!\\)\|/;

interface MergeOk {
  ok: string[];
}

interface MergeConflict {
  conflict: {
    a: string[];
    b: string[];
    o: string[];
  };
}

function mergeCandidate(row: string): boolean {
  return new RegExp(TABLE_DELIMITER_ROW_REGEX).test(row) || new RegExp(TABLE_ROW_REGEX).test(row);
}

// We are currently only trying to purposefully custom resolve a scenario where within a single table row:
// 1. Date of base and ours (the user's version) are the same
// 2. The SWM tag (SwmToken ATM) of base and theirs (the regenerated version) are the same
// Assuming that the regenerated version happened on a different day than the user's version
// And that a broken token in base and in the regenerated was resolved by the user in order to commit their version
function customMerge(base: string, ours: string, theirs: string): string | null {
  const ourCells = ours.split(NON_ESCAPED_PIPE_REGEX);
  const baseCells = base.split(NON_ESCAPED_PIPE_REGEX);
  const theirCells = theirs.split(NON_ESCAPED_PIPE_REGEX);

  // Try using diff3 to merge by cells
  const mergeResult: Array<MergeOk | MergeConflict> = diff3Merge(ourCells, baseCells, theirCells);

  if (mergeResult.every((result) => 'ok' in result)) {
    return mergeResult
      .reduce((acc, cur) => {
        acc.push(...(cur as MergeOk).ok);
        return acc;
      }, [])
      .join('|');
  }

  // If we are still un-successful - try to merge cell by cell separately
  // Succeed only if all the columns can be merged
  const result = ourCells.reduce((acc, cur, index) => {
    const mergeResult: Array<MergeOk | MergeConflict> = diff3Merge(
      [ourCells[index]],
      [baseCells[index]],
      [theirCells[index]]
    );
    if (mergeResult.every((result) => 'ok' in result)) {
      acc.push(...mergeResult.flatMap((result) => (result as MergeOk).ok));
      return acc;
    }
    return acc;
  }, []);

  return result.length === ourCells.length ? result.join('|') : null;
}

export default function merge(
  current: string,
  regenerated: string,
  mergeBase: string
): { document: string; status: 'ok' | 'conflict' } {
  const ours = current.split(LINEBREAKS);
  const base = mergeBase.split(LINEBREAKS);
  const theirs = regenerated.split(LINEBREAKS);

  const mergeResult: Array<MergeOk | MergeConflict> = diff3Merge(ours, base, theirs);

  const success = mergeResult.every((result) => 'ok' in result);
  if (success) {
    const updatedDoc = mergeResult.reduce((acc, cur) => {
      acc.push(...(cur as MergeOk).ok);
      return acc;
    }, []);
    return { document: updatedDoc.join(''), status: 'ok' };
  }

  let countRealConflicts = 0;
  const updatedDoc = mergeResult.reduce((acc, cur) => {
    if ('ok' in cur) {
      acc.push(...cur.ok);
      return acc;
    }
    const conflicts = cur.conflict;
    const solution = [];
    for (let i = 0; i < Math.max(conflicts.a.length, conflicts.b.length, conflicts.o.length); i++) {
      // Check if this is a type of conflict we this we can reasonably custom resolve
      const tryCustomMerge = mergeCandidate(conflicts.o[i]);

      if (tryCustomMerge) {
        const resolution = customMerge(conflicts.o[i], conflicts.a[i], conflicts.b[i]);
        if (resolution != null) {
          solution.push(resolution);
          continue;
        }
      }

      countRealConflicts++;
    }

    acc.push(...solution);
    return acc;
  }, []);

  if (countRealConflicts === 0) {
    return { document: updatedDoc.join(''), status: 'ok' };
  }

  return { document: regenerated, status: 'conflict' };
}
